/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import { CiFilter } from "react-icons/ci";
import "../Jobs/jobs.css";
import companyLogo from "../../images/company.png";
import interviewLogo from "../../images/xakallogo.png";
import "./interview.css";
import iClock from "../../images/icons/alarm-clock.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import CustomHeaderInterview from "./customHeaderInterview";
import styled from "styled-components";
import Slider from "react-slick";
import ExploreTimeZone from "../MyProfile/timeExploreInterview";
import noData from "../../images/NoData.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "react-bootstrap/Spinner";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import InterviewPopup from "../MyProfile/interviewPopup";
import iPlay from "../../images/icons/play-transparent.svg";
import SlotInterview from "../MyProfile/slotexplore";
import iBookmark from "../../images/icons/bookmark.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { CiLinkedin } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";
import { BsShareFill, BsWhatsapp } from "react-icons/bs";
import CloseIcon from "../../images/CloseIcon.svg";
import Readmore from "./readMore";
import { FaPlayCircle } from "react-icons/fa";
import ExploreInterviewPopup from "./InterviewExplorePopup";
import ReadMores from "./readMores";
import parse from "html-react-parser";
import moment from "moment";
import Clock from "../../images/clock-circle.svg";
import Money from "../../images/Chat Round Money.svg";
import Suit from "../../images/suitcase.svg";
import FooterInterviewPopup from "./footerInterviewPopup";
import ShimmerLoading from "./shimmerloading";
import EditTimePicker from "../MyProfile/editTimePicker";
import BannerInterview from "./bannerInterview";
import Common from "../../utils/common";
import banner1 from "../../images/banner1.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import star from "../../images/icons/star.svg";
import DownArrow from "../../images/Alt Arrow Down.svg";
import UpArrow from "../../images/Alt Arrow Up.svg";
import iLocation from "../../images/icons/location.svg";

const Time = styled.div`
  margin-left: auto;
  font-size: 14px;
  margin-top: 5px;
`;
const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  // position: relative;
  // bottom: 5px;
`;
const Designation = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #8653B6;
  margin-top: 5px;
`;
const DesignationList = styled.div`
  font-family:General Sans,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #8653B6;
  background: #f4f4f6;
  width:fit-content;
  box-shadow: 0px 2px 4px 0px #00000040;
  padding: 10px 10px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
`;
const Description = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin-top: 5px;
`;
const PreferenceWrapper = styled.div`
  margin: 10px 0;
  background:#F4F4F6;
  display:flex;
  gap:10px;
  padding:10px;
  border-radius:8px;
  span {
    position: relative;
    top: 0px;
    cursor: pointer;
  }
`;
const Content = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  color: #362A41;
`;

const MainFlex = styled.div`
  
`;

const ExploreInterview = ({ jobId, onClose }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [jobApply, setJobApply] = useState(false);
  const { state } = useLocation();
  const openTime = state?.activeTime;
  const openFormVaue = state?.openTrue;
  const openValue = state?.oneTrue;
  const datas = state?.selectedData;
  const [oneTrue, setOneTrue] = useState(openValue);

  const [inputId, setInputId] = useState();
  const inputFormInput = (id) => {
    setInputId(id);
  };
  const openDateForm = (id, jobApply = false) => {
    setJobApply(jobApply);
    setOpenDate(true);
    setIds(id);
    setInputId(id);
  };
  const [selectedData, setSelectedData] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  const availableSkills = [
    "Sql",
    "UI Design",
    "UX Design",
    "Web Development",
    "Wireless Communications and Networks",
    "React JS",
    "Recruiter",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Spring Boot",
    "Spring Framework",
    "NLP",
    "Node js",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Hardware Development",
    "Hardware Product Development",
    "HTML",
    "Internet of Things",
    "Java Script",
    "Java",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "net",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Azure DevOps",
    "Big Data",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Core Java",
    "CSS",
    "Data Analytics",
    "Data Engineer",
    "Data Modeling",
    "Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "Angular",
    "AWS",
  ];
  const [social, setOpenSocial] = useState(false);
  const [onPopupData, setPopupData] = useState();
  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    state: "",
    language: "",
    proficiency: "",
    dob: "",
    title: "",
    gender: "",
    pincode: "",
    phoneNumber: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
  });
  const prefillValue = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${inputId}`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setInput({
            ...input,
            userId: response.data.aboutOne.userId,
            id: response.data.aboutOne._id,
            firstName: response.data.aboutOne.firstName,
            lastName: response.data.aboutOne.lastName,
            middleName: response.data.aboutOne.middleName,
            email: response.data.aboutOne.email,
            bio: response.data.aboutOne.bio,
            city: response.data.aboutOne.city,
            state: response.data.aboutOne.state,
            avatar: response.data.aboutOne.avatar,
            country: response.data.aboutOne.country,
            language: response.data.aboutOne.language,
            proficiency: response.data.aboutOne.proficiency,
            about: response.data.aboutOne.about,
            gender: response.data.aboutOne.gender,
            workProfile: response.data.aboutOne.workProfile,
            urlLink: response.data.aboutOne.urlLink,
            phoneNumber: response.data.aboutOne.phoneNumber,
            inputList: response.data.aboutOne.inputList,
            inputList2: response.data.aboutOne.inputList2,
            inputList3: response.data.aboutOne.inputList3,
            createdDate: response.data.aboutOne.createdDate,
          });
        }
      })
      .catch((err) => err.error);
  };
  const settings = {
    autoplay: true,
    arrows: true, // Set arrows to true to make them visible
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };
  const [ids, setIds] = useState("");
  const [loading, setLoading] = useState(true);
  const [openDate, setOpenDate] = useState(false);
  const [exhausted, setExhausted] = useState(true);
  const [editPicker, setEditPicker] = useState();
  const [openConfirm, setConfirmPopup] = useState(false);
  const [slotId, setSlotId] = useState("");
  const openConfirmPopup = (id) => {
    setConfirmPopup(true);
    setOpenDate(false);
    setSlotId(id);
  };
  const [interviewId, setInterviewId] = useState();
  const [serviceId, setServiceId] = useState();
  const [inputs, setInputs] = useState();

  const openFirst = (id, interviewId, serviceId, input) => {
    setEditPicker(true);
    setConfirmPopup(false);
    setSlotId(id);
    setInterviewId(interviewId);
    setServiceId(serviceId);
    setInputs(input);
  };
  const close = () => {
    setConfirmPopup(false);
  };
  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    document.title = "Interviews | Xakal";
  }, []);

  useEffect(() => {
    prefillValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [pagination, setPagination] = useState(1);

  const getAllActiveInterview = async () => {
    const url = `${process.env.PUBLIC_URL}/xakal/get-all-active-interview?p=${pagination}`;
    setLoading(true); // Ensure loading is true while fetching data
  
    try {
      const response = await axios.get(url);
  
      if (response.data) {
        if (response.data.interview.length === 0) {
          setLoading(false);
          setExhausted(false);
        } else {
          setPagination(response.data.pageCount);
  
          // Filter out unique interviews
          const uniquebook = response.data.interview.filter(
            (job) => !data.some((existingbook) => existingbook._id === job._id)
          );
  
          // Function to fetch experience details for a given userId
          const fetchExperienceDetails = async (userId) => {
            try {
              const expResponse = await axios.get(
                `${process.env.PUBLIC_URL}/xakal/get-experience-detail-all/${userId}`
              );
              return expResponse.data.experience; // Adjust based on your API response structure
            } catch (err) {
              console.error(`Error fetching experience for userId ${userId}:`, err);
              return []; // Return an empty array if there's an error
            }
          };
          const fetchAboutDetail = async (expId) => {
            try {
              const aboutResponse = await axios.get(
                `${process.env.PUBLIC_URL}/xakal/get-about-detail/${expId}`
              );
              return aboutResponse.data.aboutOne; // Adjust based on your API response structure
            } catch (err) {
              console.error(`Error fetching about detail for expId ${expId}:`, err);
              return {}; // Return an empty object if there's an error
            }
          };
  
          // Fetch experience details for each unique interview
          const interviewsWithExperience = await Promise.all(
            uniquebook.map(async (job) => {
              const experience = await fetchExperienceDetails(job.createdBy.userId);
              const aboutDetail = await fetchAboutDetail(job.createdBy.userId);
              return {
                ...job,
                experience, // Add the experience data to the interview object
                aboutDetail,
              };
            })
          );
  
          // Update the data state with the interviews including experience details
          setData((current) => {
            if (current) {
              return [...current, ...interviewsWithExperience];
            } else {
              return interviewsWithExperience; // Return the unique interviews with experience if current is undefined
            }
          });
        }
      }
    } catch (err) {
      console.error("Error fetching active interviews:", err);
      // Optionally, you can set an error state here to display an error message in the UI
    } finally {
      setLoading(false); // Ensure loading is set to false after the try-catch
    }
  };
  
  const dataSave = () => {
    getAllActiveInterview();
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
      const intervalId = setInterval(calculateTimeAgo, 60000);

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
      };
    }, [timestamp]);

    return <Time>{timeAgo}</Time>;
  };

  const handleOpen = (data) => {
    if (!openForm) {
      setOpenForm(true);
    }
    setOpenPending(false);
    setSelectedData(data);
    setShowMore(false);
    setShowMores(false);
    setActiveCard(data._id);
    const saved = data.savedBy.find((fav) => fav.userId === userId.userId);
    const favouriteApplied = saved && saved.status === "saved";
    setSavedApplieds(favouriteApplied);
  };

  const closeForm = () => {
    setOpenForm(false);
    setActiveCard(null);
  };
  const [suggestion, setSuggestion] = useState([]);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [savedInterviews, setSavedInterviews] = useState([]);

 
  const getAllSuggestion = async (jobId) => {
    setSuggestionLoading(true); // Set loading to true at the beginning

    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-suggestion/${jobId}`
      );

      if (response.data) {
        setSuggestion(response.data.interviewSkill);
      } else {
        // Handle case where response.data is undefined or empty
        console.warn("No data received for suggestions.");
      }
    } catch (err) {
      console.error("Error fetching suggestions:", err);
      // Optionally, you can set an error state here to display an error message in the UI
    } finally {
      setSuggestionLoading(false); // Ensure loading is set to false after the try-catch
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [isFavorite, setIsFavorite] = useState(false);

  const updateToSavedInterview = async (savedId) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/update-saved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          savedId,
        ]);
        // setOpenForm(false);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleWhatsAppShare = () => {
    const jobName = onPopupData.interviewTitle;
    const jobId = onPopupData._id;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    const message = `${jobName} (Job ID: ${jobId}) - Click the link to view the job details:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobId = onPopupData._id;
    const jobTitle = onPopupData.interviewTitle;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const subject = `Job Opportunity: ${jobTitle} (Job ID: ${jobId})`;
    const body = `Check out this job opportunity: ${jobUrl}\n\nJob Title: ${jobTitle}\nJob ID: ${jobId}`;

    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = onPopupData.interviewTitle;
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const message = `${jobTitle} (Job ID: ${jobId}) - Click the link to view the job details: ${jobUrl}`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const jobId = selectedData?._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);

    navigator.clipboard.writeText(jobUrl).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };
  const contentRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [showMores, setShowMores] = useState(false);
  const [savedApplieds, setSavedApplieds] = useState(false);
  const openPopup = (data) => {
    setPopupData(data);
    setSelectedData(data);
    setOpenSocial(true);
  };

  const [userId, setUser] = useState({});
  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getuser`
      );

      // Assuming the response contains the user data directly
      if (response.data) {
        setUser(response.data);
      } else {
        // Handle case where response data might be empty or undefined
        console.warn("No user data received.");
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
      // Optionally, handle specific error scenarios or update UI state
    }
  };
  useEffect(() => {
    if (jobId) {
      getAllSuggestion(jobId);
    }
    getUser();
  }, []);
  useEffect(() => {
    getAllActiveInterview();

    // Clear the timeout to prevent memory leaks
  }, []);
  const updateToUnsavedInterview = async (savedId) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/unsaved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(savedId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== savedId
            );
          } else {
            return [...prevSavedInterviews, savedId];
          }
        });
        // setOpenForm(false);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const [pendingFeedbackData, setPendingFeedback] = useState([]);
  const [showBanner, setShowBanner] = useState(false);
  const showPendingFeedbackBanner = () => {
    setShowBanner(true);
  };

  const getAllPending = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-pending-feedback`
      );

      // Assuming the response contains the pending feedback data directly
      if (response.data && response.data.book) {
        setPendingFeedback(response.data.book);
      } else {
        console.warn("No pending feedback data received.");
      }
    } catch (err) {
      console.error("Error fetching pending feedback:", err);
      // Optionally handle error state or notify the user
    }
  };
  const provideFeedback = () => {
    history.push("/portal/pending-feedback-interview");
  };
  const [openPending, setOpenPending] = useState(false);
  useEffect(() => {
    getAllPending();
  }, []);
  useEffect(() => {
    if (pendingFeedbackData.length > 0) {
      setOpenPending(true);
    }
  }, [pendingFeedbackData]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (pendingFeedbackData.length > 0) {
          showPendingFeedbackBanner();
        }
      },
      6 * 60 * 60 * 1000
    ); // 6 hours in milliseconds

    return () => clearInterval(interval);
  }, []);
  const [isThisWeekFilterActive, setIsThisWeekFilterActive] = useState(false);
  const [interviewFilter, setInterviewFilter] = useState(data);

  const handleInterviewThisWeek = () => {
    setIsThisWeekFilterActive(!isThisWeekFilterActive);
  };
  useEffect(() => {
    setInterviewFilter(data);
    setLoading(false);
  }, [data]);
  const openClose = () => {
    setOpenForm(false);
  };

  const [interviews, setInterviews] = useState([]);
  const [availabilitySlots, setAvailabilitySlots] = useState({});

  useEffect(() => {
    getAllInterviewStructure();
  }, []);

  const getAllInterviewStructure = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-interview-structure`
      );

      // Check if the response data contains availabilities
      if (response.data && response.data.availabilities) {
        const availabilities = response.data.availabilities;

        // Extract interview details
        const interviewDetails = availabilities
          .map((item) => item.interviewDetail)
          .flat();

        // Extract availability slots
        const slots = availabilities
          .map((item) => item.availabilitySlots)
          .reduce((acc, item) => {
            for (const [day, times] of Object.entries(item)) {
              if (!acc[day]) acc[day] = [];
              acc[day] = acc[day].concat(times);
            }
            return acc;
          }, {});

        // Update state with the processed data
        setInterviews(interviewDetails);
        setAvailabilitySlots(slots);
      } else {
        console.warn("No availabilities data received.");
      }
    } catch (err) {
      console.error("Error fetching interview structure:", err);
      // Optionally handle error state or notify the user
    }
  };
  const filterInterviews = () => {
    const currentDateTime = new Date();
    const currentDay = currentDateTime.toLocaleString("en-US", {
      weekday: "long",
    });
    const currentTime = currentDateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDayIndex = currentDateTime.getDay();

    let futureSlots = [];

    // Collect future slots for the current day and the rest of the week
    for (let i = currentDayIndex; i < daysOfWeek.length; i++) {
      const day = daysOfWeek[i];
      const daySlots = availabilitySlots[day] || [];

      if (daySlots.length > 0) {
        if (day === currentDay) {
          // Only consider times for today that are equal to or after the current time
          futureSlots.push(...daySlots.filter((time) => time >= currentTime));
        } else {
          // For future days, include all slots
          futureSlots.push(...daySlots);
        }
      }
    }

    // Filter interviews based on the future slots
    return interviews.filter((interview) => {
      return (
        interview.status === "active" &&
        interview.createdBy.userId !== userId.userId &&
        futureSlots.some((slot) => slot >= currentTime) // Check if interview time is in future slots
      );
    });
  };

  // Example data

  const filteredInterviews = filterInterviews();

  useEffect(() => {
    const fetchScore = async () => {
      try {
        const response = await axios.get(
          `${process.env.PUBLIC_URL}/xakal/get-score-one`
        );
        // Assuming you want to set the response data to state, modify as needed
        // setData(response.data);
      } catch (err) {
        console.error("Error fetching score:", err);
        // setError(err); // Set error state to display to the user if needed
      } finally {
        setLoading(false); // Ensure loading state is turned off regardless of outcome
      }
    };

    fetchScore();
  }, []);
  const [isScrolled, setIsScrolled] = useState(false);
  const leftCardRef = useRef(null);
  const rightCardRef = useRef(null);

  const handleScrolls = (e) => {
    if (e.currentTarget === leftCardRef.current) {
      leftCardRef.current.scrollTop = 0;
    }
  };






  const BookSlot = () => {
    openDateForm(selectedData?._id, false);
    setInputId(selectedData?.createdBy?.userId);
  };
  const [activeCard, setActiveCard] = useState(null);

  const [countBanner, setCountBanner] = useState(0);
  const [openBanner, setOpenBanner] = useState(false);
  const [oneId, setOneId] = useState();
  const getOneUserDetail = async () => {
    setLoading(true); // Set loading state
    // setError(null); // Reset error state before new request

    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      );
      setCountBanner(response.data.aboutOne?.readBanner);
      setOneId(response.data.aboutOne?._id);
    } catch (err) {
      console.error("Error fetching user details:", err);
      // setError(err); // Update error state with the received error
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  useEffect(() => {
    getOneUserDetail();
  }, []);
  const getOneUpdate = () => {
    const payload = {
      readBanner: 0,
    };
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/update-about/${Common.loggedIn()}`,
        payload
      )
      .then(() => {})
      .catch((err) => {});
  };
  useEffect(() => {
    // Check if the banner has already been opened before
    if (countBanner === 1) {
      setOpenBanner(true);
    } else {
      setOpenBanner(false);
    }
    // Set a flag in local storage indicating the banner has been opened
  }, [countBanner]);
  const handleClose = () => {
    getOneUpdate();
    setOpenBanner(false);
  };
  const openEditBook = (id) => {
    setConfirmPopup(true);
    setEditPicker(false);
    setSlotId(id);
  };
  const searchedKey = () => {};
  const isMobile = window.innerWidth < 768;
  const isTab = window.innerWidth < 1180;
  useEffect(() => {
    if (oneTrue === true) {
      setOpenForm(openFormVaue);
      setActiveCard(openTime);
      setSelectedData(datas);
    }
  }, []);
  useEffect(() => {
    let s = document.cookie.split(";");
    if (s.length > 0) {
      for (let i = 0; i < s.length; i++) {
        if (!(s[i].indexOf("alreadyLoggedIn=") !== -1)) {
          document.cookie = `alreadyLoggedIn=true;`;
        }
      }
    }
    getAllActiveInterview();
  }, []);
  return (
    <div>
      <Dialog
        open={showBanner}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "20px",
            maxWidth: "383px",
            right: 0,
            position: "absolute",
            bottom: 0,
          },
        }}
      >
        <div className="modalfooter">
          <div className="closeBanner">
            <div>
              <img src={banner1} alt="banner1" className="classone" />
            </div>
            <div className="main-banner">
              <div className="font-image-size">Pending Feedback</div>
              <img
                src={CloseIcon}
                alt="img"
                onClick={() => setShowBanner(false)}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
              <div className="content-message">
                You have {pendingFeedbackData.length} pending feedback. Please
                provide your feedback to receive the interview fees.
              </div>
            </div>
          </div>
          <div className="button-div-pending-banner">
            <span onClick={() => setShowBanner(false)}>I'll do it later</span>
            <button className="buttonOne" onClick={provideFeedback}>
              Provide Feedback
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={social}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "540px" } }}
      >
        <div className="modalHeader mheader">
          <PopUpTitle>Share</PopUpTitle>
          <img src={CloseIcon} alt="img" onClick={() => setOpenSocial(false)} />
        </div>
        <DialogContent>
          <Wrapper className="modalBody">
            <div>
              <BsWhatsapp
                size={24}
                onClick={handleWhatsAppShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Whats App</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <HiOutlineMail
                size={24}
                onClick={handleEmailShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> E-Mail</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <CiLinkedin
                size={24}
                onClick={handleLinkedInShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Linkedin</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              {isCopied ? (
                <MdCheck size={30} style={{ color: "green" }} />
              ) : (
                <MdContentCopy
                  size={24}
                  onClick={handleCopyToClipboard}
                  style={{ cursor: "pointer" }}
                />
              )}
              <h4 className="sb-text">{isCopied ? "Copied" : "Copy Link"}</h4>
            </div>
          </Wrapper>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openBanner}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "auto" } }}
      >
        <DialogContent>
          <BannerInterview onClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDate}
        PaperProps={{
          style: {
            height: isTab ? "550px" : "610px",
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ExploreTimeZone
            id={inputId}
            interviewId={ids}
            open={openConfirmPopup}
            onClose={() => setOpenDate(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "800px",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SlotInterview
            inputId={inputId}
            openFirst={() => openFirst(slotId, inputId, ids, input)}
            slotId={slotId}
            close={close}
            onClose={onClose}
            jobId={jobId}
            jobApply={jobApply}
            activeTime={activeCard}
            openTrue={openForm}
            selectedData={selectedData}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={editPicker}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <EditTimePicker
            input={input}
            serviceId={ids}
            interviewId={inputId}
            slotId={slotId}
            open={openEditBook}
            onClose={() => setEditPicker(false)}
          />
        </DialogContent>
      </Dialog>
      {jobId ? (
        ""
      ) : (
        <div className="header-layout header-layouts">
          {/* Start of Mobile Header  */}
          <MobileHeader />
          {/* End of Mobile Header  */}
          {/* Start of desktop Header  */}
          <DesktopHeader title={"Interviews"} searchedKey={searchedKey} />
          {/* End of desktop Header  */}
        </div>
      )}

      {jobId ? (
        <>
          {suggestionLoading ? (
            <div className="custom-sugg">
              {Array.from({ length: 3 }).map((_, index) => (
                <ShimmerLoading key={index} />
              ))}
            </div>
          ) : suggestion && suggestion?.length >= 0 ? (
            <>
              {!openForm ? (
                <div className="boost-your-availability">
                  <div className="mTitle">
                    Boost Your Visibility with Recruiters
                  </div>
                  <div onClick={onClose} className="close-pointer mTitle">
                    {" "}
                    Skip for now
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="job-card-container"
                style={{ paddingBottom: "30px" }}
              >
                {suggestion &&
                  suggestion.map((i, index) => {
                    return (
                      <div
                        className={
                          openForm
                            ? "job-card-wrap job-card-wrappers int-rows"
                            : "int-row int-rows job-card-wrapper job-card-wrappers"
                        }
                        onClick={() => handleOpen(i)}
                      >
                        <div className="int-container">
                          <div className="int-content-container">
                            <div className="int-title-row int-titles">
                              <div>
                                {" "}
                                {i.createdBy && i.createdBy?.logo ? (
                                  <img
                                    src={i.createdBy.logo}
                                    alt="img"
                                    className="int-creator-img int-creat-img img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={companyLogo}
                                    alt="img"
                                    className="int-creator-img int-creat-img img-fluid"
                                  />
                                )}
                              </div>
                              <div className="">
                                <h5
                                  className="title titles"
                                  onClick={() => handleOpen(i)}
                                >
                                  {i.interviewTitle}
                                </h5>
                                <div className="profile-name">
                                  <div
                                    className="int-cre-title"
                                    onClick={() =>
                                      history.push(
                                        `/portal/other-profile/${i?.createdBy?.userId}`
                                      )
                                    }
                                  >
                                    {i?.createdBy?.firstName}
                                  </div>
                                </div>
                                <TimeAgo timestamp={i.timestamps.createdAt} />
                              </div>
                            </div>

                            {i?.designation.length ? (
                              <div className="prefer-wraps">
                                <div className="job-preference">
                                  Designations :
                                </div>
                                {i?.designation
                                  ?.slice(0, 3)
                                  .map((item, index) => (
                                    <div className="job-preference-pill">
                                      {item}
                                    </div>
                                  ))}
                                {i.designation.length > 3 ? (
                                  <div
                                    className="job-preference-pill"
                                    onClick={() => handleOpen(i)}
                                  >
                                    +{i.designation.length - 3}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : (
            <div>
              <img src={noData} className="nodata-center o-desk" alt="icon" />
              <p>
                Enter your skills and complete your educational and experience
                details in your profile to receive personalized job
                recommendations tailored just for you. Start finding the perfect
                job matches today!
              </p>
            </div>
          )}
        </>
      ) : (
        //explore interview
        <>
        <CustomHeaderInterview onSave={dataSave} openForm={openForm} />
        {openPending && (
                <div className="pending-flex pending-int">
                  <p>
                    You have {pendingFeedbackData.length} pending feedback.
                    Please provide your feedback to receive the interview fees.
                  </p>
                  <button onClick={provideFeedback} className="closeFeedback">
                    Provide Feedback
                  </button>
                  <button onClick={() => setOpenPending(false)}>X</button>
                </div>
              )}
        <div className="job-card-app-wrap">
          
          {loading ? (
            <div style={{ paddingTop: "160px" }}>
              {Array.from({ length: 4 }).map((_, index) => (
                <ShimmerLoading key={index} />
              ))}
            </div>
          ) : data && data?.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={getAllActiveInterview}
              hasMore={exhausted}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              
              <div
                className="job-card-container"
                style={{ paddingBottom: "30px" }}
              >
                {data &&
                  data.map((i, index) => {
                    const saved = i.savedBy.find(
                      (fav) => fav.userId === userId.userId
                    );

                    const savedApplied =
                      saved && saved.status === "saved" ? true : false;
                    return (
                      <div
                        className={
                          openForm
                            ? "job-card-wrap int-rows"
                            : "int-row int-rows job-card-wrapper"
                        }
                        onClick={() => handleOpen(i)}
                        ref={leftCardRef}
                        onScroll={handleScrolls}
                        key={index}
                      >
                        <div
                          className={activeCard === i._id ? "under-line" : ""}
                        ></div>
                        <div className="int-container">
                          <div className="int-content-container">
                            {isMobile ? (
                              <div className="int-creator">
                                <h5
                                  className="title titles"
                                  onClick={() => handleOpen(i)}
                                >
                                  <div className="profile-name">
                                    <span className="pro-name">
                                      {" "}
                                      {i.interviewTitle?.substring(0, 20)}
                                      {i.interviewTitle?.length > 20
                                        ? "..."
                                        : ""}
                                    </span>
                                    {i.interviewTitle?.length > 20 ? (
                                      <p className="prof-name">
                                        {i.interviewTitle}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </h5>
                                <TimeAgo timestamp={i.timestamps.createdAt} />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="int-title-row int-titles">
                              <div className="int-creator">
                                <div>
                                  {" "}
                                  {i.createdBy && i.createdBy?.logo ? (
                                    <img
                                      src={i.createdBy.logo}
                                      alt="img"
                                      className="int-creator-img img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={companyLogo}
                                      alt="img"
                                      className="int-creator-img img-fluid"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="int-tit-save">
                                <div className="int-details">
                                  {isMobile ? (
                                    ""
                                  ) : (
                                    <h5
                                      className="title"
                                      onClick={() => handleOpen(i)}
                                    >
                                      <div className="profile-name">
                                        <span className="pro-name">
                                          {" "}
                                          {i.interviewTitle?.substring(0, 7)}
                                          {i.interviewTitle?.length > 7
                                            ? "..."
                                            : ""}
                                        </span>
                                        {i.interviewTitle?.length > 7 ? (
                                          <p className="prof-name">
                                            {i.interviewTitle}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </h5>
                                  )}
                                  <div>
                                    <div
                                      className="company-title"
                                      onClick={(e) => {
                                        history.push(
                                          `/portal/other-profile/${i?.createdBy?.userId}`
                                        );
                                        e.stopPropagation();
                                      }}
                                    >
                                      <span>
                                        {i?.createdBy?.firstName.substring(
                                          0,
                                          7
                                        )}
                                        {i?.createdBy?.firstName?.length > 7
                                          ? "..."
                                          : ""}
                                      </span>
                                      {i?.createdBy?.firstName?.length > 7 ? (
                                        <span className="prof-name">
                                          {i?.createdBy?.firstName}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {isMobile ? (
                                      <p className="int-rating">
                                        <img src={star} alt="img" />
                                        4.5 (40reviews)
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                {isMobile ? (
                                  ""
                                ) : (
                                  <>
                                    <div
                                      className={
                                        openForm
                                          ? "int-tab ver-line"
                                          : "ver-line"
                                      }
                                    ></div>
                                    <div
                                      className={
                                        openForm ? "int-tab b-slot" : "b-slot"
                                      }
                                    >
                                      <div>
                                        {i?.inr ? (
                                          <div className="b-price">
                                            <i className="fa fa-rupee"></i>{" "}
                                            {new Intl.NumberFormat(
                                              "en-IN",
                                              {}
                                            ).format(i?.inr)}
                                          </div>
                                        ) : (
                                          <div className="b-price">Free</div>
                                        )}
                                      </div>
                                      <div className="text-center">
                                        <span className="timer">
                                          <span>{i?.duration}</span>
                                          <span>
                                            {" "}
                                            <img
                                              src={iClock}
                                              className="clock-icon"
                                              alt="icon"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>

                            <>
                              {" "}
                              {i?.designation.length ? (
                                <div className="prefer-wraps">
                                  <div className="job-preferences">
                                    Designations :
                                  </div>
                                  {i?.designation
                                    ?.slice(0, 1)
                                    .map((item, index) => (
                                      <div
                                        className={
                                          openForm
                                            ? "job-pref-fill"
                                            : "job-preference-pill"
                                        }
                                      >
                                        {item}
                                      </div>
                                    ))}
                                  {i.designation.length > 1 ? (
                                    <div
                                      className="job-preference-pill"
                                      onClick={() => handleOpen(i)}
                                    >
                                      +{i.designation.length - 1}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                            {isMobile ? (
                              <div className="b-slot int-slot">
                                <div>
                                  <span className="timer">
                                    <span>
                                      {i?.inr ? (
                                        <div className="">
                                          <i className="fa fa-rupee"></i>{" "}
                                          {new Intl.NumberFormat(
                                            "en-IN",
                                            {}
                                          ).format(i?.inr)}{" "}
                                          /
                                        </div>
                                      ) : (
                                        <div className="">Free /</div>
                                      )}
                                    </span>
                                    <span>{i?.duration}</span>
                                    <span>
                                      {" "}
                                      <img
                                        src={iClock}
                                        className="clock-icon"
                                        alt="icon"
                                      />
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  {savedApplied ||
                                  savedInterviews.includes(i?._id) ? (
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        updateToUnsavedInterview(i?._id);
                                      }}
                                    >
                                      <img
                                        src={iBookmarkActive}
                                        className="bookmark-icon"
                                        alt="icon"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        updateToSavedInterview(i?._id);
                                      }}
                                    >
                                      <img
                                        src={iBookmark}
                                        className="bookmark-icon"
                                        alt="icon"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "50px",
                  }}
                >
                  <Spinner />
                </div>
              }
              </div>
              
            </InfiniteScroll>
          ) : (
            <div className="nodata-center">
              <img src={noData} alt="icon" />
              <p className="nodata-info">
                Enter your skills and complete your educational and experience
                details in your profile to receive personalized job
                recommendations tailored just for you. Start finding the perfect
                job matches today!
              </p>
            </div>
          )}
          {openForm && selectedData ? (
          <div className={openPending ? "job-app-wrap job-app-wraps" : "job-app-wrap job-app-wraps"}>
          <ExploreInterviewPopup
            onPopupData={selectedData}
            onClose={closeForm}
            openDateForm={openDateForm}
            inputId={inputFormInput}
            onClosse={openClose}
            callAll={getAllActiveInterview}
            BookSlot={BookSlot}
          />
          <MainFlex>
            <div className="modalBody mBody modalbody int-prev-cont">
            <div className="int-dur-cost">
              <img src={Clock} alt="img" />
              <div>{selectedData?.duration}</div>
            </div>
            <div>
              {selectedData?.inrService ? (
                <div className="int-dur-cost">
                <img src={Money} alt="img" />
                <div>Free</div>
                </div>
              ) : (
                <div className="int-dur-cost">
                  <img src={Money} alt="img" className="int-dur-img" />
                <div className="int-dur-cost-mon">
                  INR
                  <div>{selectedData && new Intl.NumberFormat("en-IN", {}).format(selectedData?.inr)}</div> 
                </div>
                </div>
              )}
            </div>
              <div>
              <div className="int-heads int-head-start">Designations </div>
                  <PreferenceWrapper>
                  {selectedData?.designation.map((item, index) => (
                    <DesignationList key={index}>{item}</DesignationList>
                  ))}
                  </PreferenceWrapper>
              </div>

              <div>
                
                  <div className="int-heads">
                    Skills that'll be evaluated{" "}
                  </div>
                  <PreferenceWrapper>
                  {selectedData?.skill.map((item, index) => (
                    <DesignationList key={index}>{item.label}</DesignationList>
                  ))}
                </PreferenceWrapper>
              </div>
              
              
                <div className="int-heads">Interview Description</div>
                <div className={`job-des-height ${showMore ? "expanded" : "collapsed"}`}>
                  <Content ref={contentRef}>
                    {selectedData?.description &&
                      (showMore
                        ? parse(selectedData?.description)
                        : parse(selectedData?.description.substring(0, 200)))}
                  </Content>
                </div>
                <span onClick={() => setShowMore(!showMore)}>
                  {showMore ? (
                    <div className="job-readmore">
                      Read Less
                      <span>
                        <img src={UpArrow} alt="img" />
                      </span>
                    </div>
                  ) : selectedData.description && selectedData?.description.length > 90 ? (
                    <div className="job-readmore">
                      Read more
                      <span>
                        <img src={DownArrow} alt="img" />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </span>
              
              <div className="top-exp">
                <div className="int-heads">Meet the Interviewer</div>
                <div className="int-det">
                  <img
                    src={
                      selectedData?.createdBy.logo
                        ? selectedData?.createdBy.logo
                        : companyLogo
                    }
                    className="image-shrink"
                    alt="imgss"
                  />
                  <div className="int-heads">
                    <p className="int-comp-name">
                      {selectedData?.createdBy.firstName}{" "}
                      {selectedData?.createdBy.lastName}
                    </p>
                    <p className="int-desig">Product Development | Strategic Planning</p>
                  </div>
                </div>
              </div>
              <div className="top-exp top-exps">
                <div className="int-heads">About The interviewer</div>
                <div className={`job-des-height ${showMores ? "expanded" : "collapsed"}`}>
                  <Content ref={contentRef}>
                    {selectedData?.aboutDetail.about &&
                      (showMores
                        ? parse(selectedData?.aboutDetail.about)
                        : parse(selectedData?.aboutDetail.about.substring(0, 200)))}
                  </Content>
                </div>
                <span onClick={() => setShowMores(!showMores)}>
                  {showMores ? (
                    <div className="job-readmore">
                      Read Less
                      <span>
                        <img src={UpArrow} alt="img" />
                      </span>
                    </div>
                  ) : selectedData.aboutDetail.about && selectedData?.aboutDetail.about.length > 90 ? (
                    <div className="job-readmore">
                      Read more
                      <span>
                        <img src={DownArrow} alt="img" />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              <div className="top-exp top-exps">
                <div className="int-heads">Experience</div>
                {selectedData.experience?.map((expDetail) => (
                  <div className="card-border" key={expDetail._id}>
                    <div className="interview-exp">
                      <img
                        src={
                          expDetail.company?.companyLogo
                            ? expDetail.company?.companyLogo
                            : companyLogo
                        }
                        alt="Company Logo"
                        className="card-image-company"
                      />
                      <div>
                        <p className="job-title">
                          {expDetail?.designation
                            ? expDetail?.designation
                            : "-"}
                        </p>
                        <p className="int-comp-name">
                          {expDetail?.company?.label
                            ? expDetail?.company?.label
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div className="interview-expss">
                      <div class="icon-title">
                        <img src={Clock} className="jt-icon" alt="icon" />
                        <span>
                          {expDetail?.employeeType
                            ? expDetail?.employeeType
                            : "-"}
                        </span>
                      </div>
                      <div class="icon-title">
                        <img src={Suit} className="jt-icon" alt="icon" />
                        <span>
                          {" "}
                          {moment(expDetail?.startDate).format("MMM YYYY")}
                          {expDetail.endDate === ""
                            ? " - Present"
                            : ` - ${moment(expDetail?.endDate).format(
                                "MMM YYYY"
                              )}`}
                        </span>
                      </div>
                      <div class="icon-title">
                        <img src={iLocation} className="jt-icon" alt="icon" />
                        <span>
                        {expDetail?.location
                            ? expDetail?.location
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
            </div>
            <FooterInterviewPopup data={selectedData} BookSlot={BookSlot}/>
          </MainFlex>
          </div>
          ) : (
          ""
          )}
        </div>
        </>
      )}
    </div>
  );
};

export default ExploreInterview;
