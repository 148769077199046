import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { BsShareFill, BsWhatsapp } from "react-icons/bs";
import parse from "html-react-parser";
import Image from "../../images/company.png";
import CloseIcon from "../../images/CloseIcon.svg";
import { FaRupeeSign } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import "./buttonStyle.css";
import { HiOutlineMail } from "react-icons/hi";
import { Dialog, DialogContent } from "@mui/material";
import { CiLinkedin } from "react-icons/ci";
import Suit from "../../images/suitcase.svg";
import Clock from "../../images/clock-circle.svg";
import Location from "../../images/AboutLanding/solar_map-point-bold-duotone.svg";
import Home from "../../images/solar_home-bold-duotone.png";
import Salary from "../../images/solar_hand-money-bold-duotone.png";
import { MdCheck, MdContentCopy } from "react-icons/md";
import "./job.css";
import Back from "../../images/solar_alt-arrow-left-linear.svg";
import DownArrow from "../../images/Alt Arrow Down.svg";
import UpArrow from "../../images/Alt Arrow Up.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import WebsiteImg from "../../images/Users Group Two Rounded.png";
import Building from "../../images/Buildings 2.svg";
import ColName from "../../images/Buildings.png";
const MainLayout = styled.div`
  overflow-x: hidden;
  margin: 10px;
  // overflow-y: auto;
  // max-height: 450px;
  @media (min-width: 600px) and (max-width: 768px) {
    max-height: 720px;
  }
  @media (min-width: 769px) and (max-width: 820px) {
    height: fit-content;
  }
  @media (min-width: 821px) and (max-width: 1024px) {
    max-height: 490px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    max-height: 560px;
  }
  @media (min-width: 1950px) {
    max-height: fit-content;
  }
`;

const PageWrapper = styled.div`
  background: #fff;
  border-radius: 24px;
  padding: 15px;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 1rem 0;
  }
`;

const ContentWrapper = styled.div`
  img {
    cursor: pointer;
  }
  @media (max-width: 576px) {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div``;
const JobDetailIcon = styled.div`
  left: 10%;
`;
const JobTitle = styled.div``;
const JobTitle2 = styled.div`
margin:13px 0;
font-weight:400;
`;
const JobTitleIcon = styled.div``;

const Dates = styled.div`
  padding: 5px;
  color: #764f90;
  font-size: 16px;
  font-weight: normal;
  width: 120px;
  margin: 20px;
`;

const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 10px 0;
  background-color: #f4f4f6;
  gap: 10px;
  padding: 12px 6px;
  border-radius: 8px;
`;
const Preference = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  color: #362a41;
`;
const JobPreference = styled.div`
  color: #4f4953;
  background: #f4f4f6;
  box-shadow: 0px 2px 4px 0px #00000040;
  padding: 10px;
  border-radius: 5px;
  height: 33px;
  font-size: 14px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  @media (max-width: 576px) {
    height: auto;
  }
`;

const Content = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #4f4953;
`;
const Website = styled.div`
  color: #4f4953;
  font-family: var(--pf-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: lowercase;
  display: flex;
  gap: 10px;
`;
const WebsiteContent = styled.div`
  margin-top: 5px;
  font-size: 12px;
  font-weight: normal;
  text-transform: capitalize;
`;

const ViewJobApplicant = ({ jobData, setIsOpen, showMore, setShowMore }) => {
  const history = useHistory();
  const [social, setOpenSocial] = useState(false);
  var scheduleDate = jobData.scheduleDate;
  var dateString = new Date(scheduleDate);
  var forDate = dateString.toLocaleDateString();
  const [isCopied, setIsCopied] = useState(false);
  const [showMoreCompany,setShowMoreCompany] = useState(false)
  const contentRef = useRef(null);
  
  
  const handleWhatsAppShare = () => {
    const jobName = jobData.jobTitle;
    const jobId = jobData._id;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");

    const jobUrl = `${domain}/share-job?jobId=${jobId}`;

    const message = `${jobName} (Job ID: ${jobId}) - Click the link to view the job details:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobTitle = jobData.jobTitle; // Get the job title from your data
    const jobId = jobData._id; // Get the job ID from your data
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");

    const jobUrl = `${domain}/share-job?jobId=${jobId}`;
    const subject = `Job Opportunity: ${jobTitle} (Job ID: ${jobId})`;
    const body = `Check out this job opportunity: ${jobUrl}\n\nJob Title: ${jobTitle}\nJob ID: ${jobId}`;

    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = jobData.jobTitle; // Get the job title from your data
    const jobId = jobData._id; // Get the job ID from your data
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");

    const jobUrl = `${domain}/share-job?jobId=${jobId}`;

    const message = `${jobTitle} (Job ID: ${jobId}) - Click the link to view the job details: ${jobUrl}`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  // useEffect(() => {
  //   if (showMore) {
  //     setContentHeight(`${contentRef.current.scrollHeight}px`);
  //   } else {
  //     setContentHeight('');
  //   }
  // }, [showMore]);

  const handleCopyToClipboard = () => {
    const jobId = jobData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");

    const jobUrl = `${domain}/share-job?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);

    navigator.clipboard.writeText(jobUrl).then(() => {
      setIsCopied(true);

      // Reset the copy status after a certain duration (e.g., 3 seconds)
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  
  return (
    <div>
      <MainLayout>
        <ContentWrapper className="job-content-wrapper">
          <img
            src={Back}
            alt="img"
            onClick={setIsOpen}
            className="job-prev-back"
          />
          <div
            className="company-logo-container"
            style={{ padding: "0", border: "0" }}
          >
            {jobData.companyName && jobData.companyName.companyLogo ? (
              <>
                <img
                  src={jobData.companyName && jobData.companyName.companyLogo}
                  alt="img"
                  className="company-logo"
                />
              </>
            ) : (
              <img src={Image} alt="img" className="company-logo" />
            )}
          </div>
          <div
            style={{
              display: "flex",
              width: "90%",
              gap: "30px",
              justifyContent: "space-between", // Aligns items at the start and end of the flex container
            }}
            // className="share-btn-container"
          >
            <Wrapper className="job-title-wrapper">
              <JobTitle className="job-titles">{jobData.jobTitle}</JobTitle>
              <JobTitle2
                className="company-title"
                onClick={() =>
                  history.push({
                    pathname: `/portal/other-company-view/${jobData.companyName?.comID}`,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                {jobData.companyName && jobData.companyName.value}
              </JobTitle2>
            </Wrapper>
            <div className="share-btn">
              <BsShareFill size={20} onClick={() => setOpenSocial(true)} />
            </div>
          </div>
        </ContentWrapper>
        <PageWrapper className="modalBody">
          <JobDetailIcon className="job-details">
            <JobTitleIcon className="icon-title">
              <img
                src={Salary}
                alt="img"
                style={{ width: "20px", height: "20px" }}
              />
              <FaRupeeSign size={24} color="grey" />
              <span className="job-sal">
                {`${jobData.salaryFrom}L - ${jobData.salaryTo}L`}{" "}
              </span>
              <span>/ yr</span>
            </JobTitleIcon>
          </JobDetailIcon>
          <JobDetailIcon className="job-details">
            <JobTitleIcon className="icon-title">
              {" "}
              {/* <BiTime size={20} className="jt-icon" /> */}
              <img
                src={Clock}
                alt="img"
                style={{ width: "20px", height: "20px" }}
              />
              <span> {jobData.employeeType} </span>
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              {/* <PiSuitcaseSimpleLight size={20} className="jt-icon" /> */}
              <img
                src={Suit}
                alt="img"
                style={{ marginTop: "-5px", width: "20px", height: "20px" }}
              />
              <span>
                Exp{" "}
                {jobData.experienceRequired2
                  ? `${jobData.experienceRequired} - ${jobData.experienceRequired2} yrs`
                  : jobData.experienceRequired
                    ? `${jobData.experienceRequired} yrs`
                    : ""}
              </span>
            </JobTitleIcon>
          </JobDetailIcon>
          <JobDetailIcon className="job-details">
            <JobTitleIcon className="icon-title-lo">
              {" "}
              {/* <HiLocationMarker size={20} className="jt-icon" /> */}
              <p className="location-hover">{jobData.location}</p>
              <img
                src={Location}
                alt="img"
                style={{ width: "20px", height: "20px" }}
              />
              <span>
                {jobData.location?.substring(0, 8)}
                {jobData.location?.length > 8 ? "..." : ""}
              </span>
            </JobTitleIcon>
            {/* <JobTitle>{i.preference} |</JobTitle> */}
            <JobTitleIcon className="icon-title">
              {" "}
              {/* <TbHome2 size={20} className="jt-icon" /> */}
              <img
                src={Home}
                alt="img"
                style={{ marginTop: "-5px", width: "20px", height: "20px" }}
              />
              <span> {jobData.workMode}</span>
            </JobTitleIcon>
          </JobDetailIcon>
          {jobData.preference?.length > 0 ? (
            <>
              <Preference>Preferred :</Preference>
              <PreferenceWrapper>
                {jobData.preference
                  ? jobData.preference.map((i) => {
                      return <JobPreference>{i}</JobPreference>;
                    })
                  : []}
              </PreferenceWrapper>
            </>
          ) : (
            ""
          )}

          <Preference>Skills :</Preference>

          <PreferenceWrapper>
            {jobData.skill
              ? jobData.skill.map((i) => {
                  return <JobPreference>{i}</JobPreference>;
                })
              : []}
          </PreferenceWrapper>

          <Preference>Job Description and Benefits :</Preference>
          <div
            className={`job-des-height ${showMore ? "expanded" : "collapsed"}`}
          >
            <Content ref={contentRef}>
              {jobData &&
                (showMore
                  ? parse(jobData.jobDesc)
                  : parse(jobData.jobDesc.substring(0, 200)))}
            </Content>
          </div>
          <span onClick={setShowMore}>
            {showMore ? (
              <div className="job-readmore">
                Read Less
                <span>
                  <img src={UpArrow} alt="img" />
                </span>
              </div>
            ) : jobData && jobData.jobDesc.length > 200 ? (
              <div className="job-readmore">
                Read more
                <span>
                  <img src={DownArrow} alt="img" />
                </span>
              </div>
            ) : (
              ""
            )}
          </span>
          <Preference>About the organisation</Preference>
          <Wrapper className="job-title-wrapper">
            <JobTitle2
              className="company-title"
              onClick={() =>
                history.push({
                  pathname: `/portal/other-company-view/${jobData.companyName?.comID}`,
                })
              }
              style={{ cursor: "pointer", color: "#8653B6", fontWeight: "700" }}
            >
              {jobData.companyName && jobData.companyName.value}
            </JobTitle2>
            <JobTitle2 className="company-title" style={{ cursor: "pointer" }}>
              {jobData.companyDetails?.website && jobData.companyDetails?.website}
            </JobTitle2>
            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "10px",
              }}
            >
              <Website>
                <img src={ColName} alt="img" />
                <WebsiteContent>{jobData?.companyDetails && jobData?.companyDetails?.industry}</WebsiteContent>
              </Website>
              <Website>
                <img src={WebsiteImg} alt="img" />
                <WebsiteContent>{jobData?.companyDetails && jobData?.companyDetails?.organisationSize}</WebsiteContent>
              </Website>
              <Website>
                <img src={Building} alt="img" />
                <WebsiteContent>{jobData?.companyDetails && jobData?.companyDetails?.organisationType}</WebsiteContent>
              </Website>
            </div>
            <div
              className={`job-des-height ${
                showMoreCompany ? "expanded" : "collapsed"
              }`}
            >
              <Content ref={contentRef}>
                {jobData?.companyDetails && jobData?.companyDetails.companyDescription &&
                  (showMoreCompany
                    ? parse(jobData?.companyDetails?.companyDescription)
                    : parse(jobData?.companyDetails?.companyDescription?.substring(0, 200)))}
              </Content>
            </div>
            <span onClick={() => setShowMoreCompany(!showMoreCompany)}>
              {showMoreCompany ? (
                <div className="job-readmore">
                  Read Less
                  <span>
                    <img src={UpArrow} alt="img" />
                  </span>
                </div>
              ) : jobData?.companyDetails?.companyDescription && jobData?.companyDetails?.companyDescription.length > 90 ? (
                <div className="job-readmore">
                  Read more
                  <span>
                    <img src={DownArrow} alt="img" />
                  </span>
                </div>
              ) : (
                ""
              )}
            </span>
          </Wrapper>

          {jobData.scheduleDate ? <Dates>{forDate}</Dates> : ""}
        </PageWrapper>
        <Dialog
          open={social}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              position: "absolute",
              top: "150px",
              borderRadius: "20px",
              maxWidth: "450px",
            },
          }}
        >
          <div className="modalHeader mheader">
            <div className="mTitle">Share</div>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setOpenSocial(false)}
            />
          </div>
          <DialogContent>
            <div
              className="modalBody"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div className="social-btn-holder">
                <div className="s-icon-holder">
                  <BsWhatsapp
                    size={24}
                    onClick={handleWhatsAppShare}
                    className="s-icon"
                  />
                </div>
                <h4 className="sb-text"> Whats App</h4>
              </div>
              <div className="social-btn-holder">
                <div className="s-icon-holder">
                  <HiOutlineMail
                    size={24}
                    onClick={handleEmailShare}
                    className="s-icon"
                  />
                </div>

                <h4 className="sb-text"> E-Mail</h4>
              </div>
              <div className="social-btn-holder">
                <div className="s-icon-holder">
                  <CiLinkedin
                    size={24}
                    onClick={handleLinkedInShare}
                    className="s-icon"
                  />
                </div>
                <h4 className="sb-text"> Linkedin</h4>
              </div>
              <div className="link-icon ">
                {isCopied ? (
                  <div className="social-btn-holder">
                    <div className="s-icon-holder">
                      <MdCheck size={24} style={{ color: "green" }} />
                    </div>
                  </div>
                ) : (
                  <div className="social-btn-holder">
                    <div className="s-icon-holder">
                      <MdContentCopy
                        size={24}
                        onClick={handleCopyToClipboard}
                        className="s-icon"
                      />
                    </div>
                  </div>
                )}
                <h6 className="sb-text">
                  {isCopied ? "Link Copied" : "Copy Link"}
                </h6>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </MainLayout>
    </div>
  );
};

export default ViewJobApplicant;
