/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select, { components } from "react-select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import Addcircle from "../../images/Add Circle.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import Back from "../../images/Back.png";
import Spinner from "react-bootstrap/Spinner";
const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    minHeight: "48px",
    height: "fit-content",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontweight: "normal",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const JobTitle = styled.div`
  color: #40444f;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%; /* 19.6px */

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #36a47c;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
`;
const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  p {
    font-size: 22px;
    cursor: pointer;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 0px;
  margin: 0rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #4f4953;
  line-height: 17px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 25px 0 5px 0;

  span {
    color: #ff4873;
    margin-left: 2px;
  }
`;
const Time = styled.div`
  margin-top: 0px;
  margin-left: 40px;
  span {
    font-size: 12px;
    font-weight: normal;
    color: 4F4953;
  }
`;
const Resend = styled.div`
  text-align: center;
  margin-top: 20px;
  span {
    font-size: 14px;
    font-weight: normal;
    color: #a8a1ac;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const options2 = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
];
const options7 = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
];

const options5 = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Internship", label: "Internship" },
];
const options6 = [
  { value: "Hybrid", label: "Hybrid" },
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
];

const JobPreference = styled.div`
  // color: black;
  background: #f4e8ff;
  padding: 10px;
  border-radius: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  display: flex;
  gap: 10px;
`;
const ButtonLink = styled.div`
  width: 146px;
  height: 48px;
  border-radius: 8px;
  // padding: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid rgba(221, 223, 228, 0.5);
  // gap: 8px;
  color: white;
  cursor: pointer;
  // margin-right: 4px;
  display: flex;
  gap: 5px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  text-transform: capitalize;
  p {
    width: 24px;
    height: 24px;
    // flex-shrink: 0;
    background: #1c274c;
    border-radius: 50%;
    // font-size:24px;
    padding: 0px 0px 5px 10px;
    color: white;
    margin-top: 15px;
  }
  img {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;
const HrLines = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  padding: 0 !important;
`;

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <></>
    </components.DropdownIndicator>
  );
};

const NewJob = ({
  closeJobOne,
  closeAll,
  jobId,
  shouldUpdate,
  newOne,
  isCardActive,
  openNewJob,
}) => {
  const history = useHistory();
  const [closemodal, setCloseModal] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [otps, setOtps] = useState(["", "", "", ""]);
  const [input, setInput] = useState({
    jobTitle: "",
    companyName: "",
    experienceRequired: "",
    location: "",
    preference: "",
    salaryFrom: "",
    salaryTo: "",
    employeeType: "",
    workMode: "",
  });

  const [skill, setSkill] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [experienceRequired, setExperienceRequired] = useState("");
  const [experienceRequired2, setExperienceRequired2] = useState("");

  const [location, setLocation] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [workMode, setWorkMode] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [preference, setPreference] = useState([]);
  const [dirty, setIsDirty] = useState(false);
  const [error, setError] = useState({
    jobTitle: "",
    companyName: "",
    experienceRequired: "",
    experienceRequired2: "",
    location: "",
    preference: "",
    salaryFrom: "",
    salaryTo: "",
  });
  const handleSkillChnage = (selected) => {
    const selectedValues = Array.isArray(selected)
      ? selected.map((x) => x.value)
      : [];
    setSkill(selectedValues);
    setSkillCreate("");
    setOptionsOne([]);
    setIsDirty(true);
  };

  const handleExperienceChange = (event) => {
    const selectedValue = event.value;

    setExperienceRequired(selectedValue);
    setIsDirty(true);

    // Reset the error for experience field
    setError({
      ...error,
      experienceRequired: "",
    });
  };

  const handleExperience2Change = (event) => {
    const selectedValue = event.value;

    setExperienceRequired2(selectedValue);
    setIsDirty(true);
    setError({
      ...error,
      experienceRequired2: "",
    });
  };

  const handleEmployementChange = (event) => {
    const selectedValue = event.value;

    setEmployeeType(selectedValue);
    setIsDirty(true);

    // Set the 'workMode' state with the selected value
    // setWorkMode(selectedValue);
  };
  const handleWorkChange = (event) => {
    // setWorkMode(event);
    const selectedValue = event.value;
    setIsDirty(true);

    // Set the 'workMode' state with the selected value
    setWorkMode(selectedValue);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsDirty(true);
  };

  const handleAddInput = () => {
    if (inputValue?.trim() !== "") {
      // Use the functional form of setPreference to ensure you’re working with the latest state
      setPreference((prevPreferences) => {
        if (Array.isArray(prevPreferences)) {
          return [...prevPreferences, inputValue];
        }
        // Handle the case where prevPreferences is not an array
        return [inputValue];
      });
      setInputValue(""); // Clear inputValue after adding a new preference
      setPreferenceRequiredTouched("");
    }
  };

  const handleRemoveInput = (index) => {
    const updatedValues = [...preference];
    updatedValues.splice(index, 1);
    setPreference(updatedValues);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    setIsDirty(true);
  };
  const handleChangesOne = (event) => {
    const { name, value } = event.target;

    const regex = /^[0-9]*$/;
    const isValidInput = regex.test(value) || value === "";

    const isNegative = value.includes("-");

    if (isValidInput && !isNegative) {
      setInput((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }

    setIsDirty(true);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "jobTitle":
          if (value === "") {
            stateObj[name] = "Please enter job title";
          }
          break;

        case "companyName":
          if (value === "") {
            stateObj[name] = "Please enter company name";
          } else {
            stateObj[name] = "";
          }

          break;

        case "experienceRequired":
          if (value === "") {
            stateObj[name] = "Please enter experience required";
          }
          break;
        case "location":
          if (value === "") {
            stateObj[name] = "Please enter location";
          }
          break;

        case "salaryFrom":
          const regex3 = /^[0-9,.]+$/.test(value);

          if (input.salaryFrom === "") {
            stateObj[name] = "Please enter salary from";
          } else if (!regex3) {
            stateObj[name] = "Please enter valid salary from";
          } else if (parseFloat(value) < 0) {
            stateObj[name] = "Salary from cannot be negative";
          }
          break;
        case "salaryTo":
          const regex4 = /^[0-9,.]+$/.test(value);

          if (input.salaryTo === "") {
            stateObj[name] = "Please enter salary to";
          } else if (!regex4) {
            stateObj[name] = "Please enter valid salary to";
          } else if (parseFloat(value) < 0) {
            stateObj[name] = "Salary to cannot be negative";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const [prefrenceRequiredTouched, setPreferenceRequiredTouched] =
    useState(false);
  const [locationRequiredTouched, setLocationRequiredTouched] = useState(false);
  const [jobIds, setJobId] = useState("");
  const [verifyId, setVerifyId] = useState("");
  const [value, setValue] = useState();

  const [loading, setLoading] = useState(false);
  const newJobs = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = {};

    if (!input.jobTitle) {
      errors.jobTitle = "Please enter job title";
    }

    if (!input.companyName) {
      errors.companyName = "Please enter company name";
    }

    if (!experienceRequired) {
      errors.experienceRequired = "Please select experience";
    }
    if (experienceRequired >= experienceRequired2) {
      errors.experienceRequired2 =
        "Experience from should be less than experience to";
    }
    if (!location) {
      errors.location = "Please select location";
    }

    const regex3 = /^[0-9,.]+$/.test(input.salaryFrom);
    if (!input.salaryFrom || !regex3) {
      errors.salaryFrom = "Please enter valid salary from";
    }

    const Difference = input.salaryTo - input.salaryFrom;
    if (Difference < 0 && input.salaryTo !== "") {
      errors.salaryTo = "Salary to should be greater than salary from";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          jobTitle: input.jobTitle,
          companyName: input.companyName,
          experienceRequired: parseInt(experienceRequired),
          experienceRequired2: parseInt(experienceRequired2),
          companyId: input.companyName.comId,
          location: location,
          salaryFrom: input.salaryFrom,
          salaryTo: input.salaryTo,
          employeeType: employeeType,
          workMode: workMode,
          skill: skill,
          preference: preference,
        };

        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/job-manage-create`,
          data
        );

        if (response.data.success) {
          console.log(response, "add form");
          setInput({
            jobTitle: "",
            companyName: "",
            experienceRequired: "",
            location: "",
            salaryFrom: "",
            salaryTo: "",
            employeeType: "",
            workMode: "",
          });
          setExperienceRequired("");
          setExperienceRequired2("");
          setSkill([]);
          setPreference([]);
          setLocation("");
          setWorkMode("");
          setEmployeeType("");
        }

        setCompanyName(response.data.job.companyName);
        setValue(response.data.job.companyName);
        setJobId(response.data.job._id);
        setVerifyId(response.data.job.verifyId);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
      setOpen(true);
    } else {
      setError(errors);

      setLocationRequiredTouched({
        location: location === "" ? "Please enter location" : "",
      });
      setLoading(false);
    }
  };
  const newJobs2 = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = {};

    if (!input.jobTitle) {
      errors.jobTitle = "Please Enter Job Title";
    }

    if (!input.companyName) {
      errors.companyName = "Please Enter Company Name";
    }

    if (!experienceRequired) {
      errors.experienceRequired = "Please Select Experience";
    }

    if (!location) {
      errors.location = "Please Select Location";
    }

    const regex3 = /^[0-9,.]+$/.test(input.salaryFrom);
    if (!input.salaryFrom || !regex3) {
      errors.salaryFrom = "Please Enter Valid Salary From";
    }

    const Difference = input.salaryTo - input.salaryFrom;
    if (Difference < 0 && input.salaryTo !== "") {
      errors.salaryTo = "Salary To should be greater than Salary From";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          jobTitle: input.jobTitle,
          companyName: input.companyName,
          experienceRequired: parseInt(experienceRequired),
          experienceRequired2: parseInt(experienceRequired2),
          companyId: input.companyName.comId,
          location: location,
          salaryFrom: input.salaryFrom,
          salaryTo: input.salaryTo,
          employeeType: employeeType,
          workMode: workMode,
          skill: skill,
          preference: preference,
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-manage-job/${jobIds}`,
          data
        );

        if (response.data.success) {
          console.log(response, "add form,hjghdsfsgfhafdkgdftyqfyft");
          setInput({
            jobTitle: "",
            companyName: "",
            experienceRequired: "",
            location: "",
            salaryFrom: "",
            salaryTo: "",
            employeeType: "",
            workMode: "",
          });
          setExperienceRequired("");
          setExperienceRequired2("");
          setSkill([]);
          setPreference([]);
          setLocation("");
          setWorkMode("");
          setEmployeeType("");
        }

        setCompanyName(response.data.updateJob);
        setValue(response.data.updateJob.companyName);
        setJobId(response.data.updateJob?._id);
        setVerifyId(response.data.updateJob?.verifyId);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
      setOpen(true);
    } else {
      setError(errors);

      setLocationRequiredTouched({
        location: location === "" ? "Please Enter Location" : "",
      });
      setLoading(false);
    }
  };
  const newJobs3 = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!input.jobTitle) {
      errors.jobTitle = "Please Enter Job Title";
    }

    if (!input.companyName) {
      errors.companyName = "Please Enter Company Name";
    }

    if (!experienceRequired) {
      errors.experienceRequired = "Please Select Experience";
    }

    if (!location) {
      errors.location = "Please Select Location";
    }

    const regex3 = /^[0-9,.]+$/.test(input.salaryFrom);
    if (!input.salaryFrom || !regex3) {
      errors.salaryFrom = "Please Enter Valid Salary From";
    }

    const Difference = input.salaryTo - input.salaryFrom;
    if (Difference < 0 && input.salaryTo !== "") {
      errors.salaryTo = "Salary To should be greater than Salary From";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          jobTitle: input.jobTitle,
          companyName: input.companyName,
          experienceRequired: parseInt(experienceRequired),
          experienceRequired2: parseInt(experienceRequired2),
          companyId: input.companyName.comId,
          location: location,
          salaryFrom: input.salaryFrom,
          salaryTo: input.salaryTo,
          employeeType: employeeType,
          workMode: workMode,
          skill: skill,
          preference: preference,
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-post-draft/${jobIds}`,
          data
        );

        if (response.data.success) {
          console.log(response, "add form,hjghdsfsgfhafdkgdftyqfyft");
          setInput({
            jobTitle: "",
            companyName: "",
            experienceRequired: "",
            location: "",
            salaryFrom: "",
            salaryTo: "",
            employeeType: "",
            workMode: "",
          });
          setExperienceRequired("");
          setExperienceRequired2("");
          setSkill([]);
          setPreference([]);
          setLocation("");
          setWorkMode("");
          setEmployeeType("");
        }

        setCompanyName(response.data.updateJob);
        setValue(response.data.updateJob);
        setJobId(response.data.updateJob?._id);
        setVerifyId(response.data.updateJob.verifyId);
      } catch (err) {
        console.error(err);
      }
      closeAll();
    } else {
      setError(errors);

      setLocationRequiredTouched({
        location: location === "" ? "Please Enter Location" : "",
      });
    }
  };
  const handleRouteChange = async () => {
    let data = JSON.parse(localStorage.getItem("draft"));
    if (
      data.jobTitle?.trim().length > 0 ||
      data.companyName?.trim().length > 0 ||
      data.location?.trim().length > 0 ||
      data.salaryFrom?.trim().length > 0 ||
      // data.preference.trim().length > 0 ||
      data.experienceRequired?.trim().length > 0
    ) {
      data["status"] = "draft";
      try {
        await axios.post(
          `${process.env.PUBLIC_URL}/xakal/get-draft-create`,
          data
        );
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    }
    localStorage.removeItem("draft");
  };

  useEffect(() => {
    const data = {
      jobTitle: input?.jobTitle,
      companyName: input?.companyName,
      experienceRequired: experienceRequired,
      experienceRequired2: experienceRequired2,
      location: location,
      salaryFrom: input?.salaryFrom,
      salaryTo: input?.salaryTo,
      employeeType: employeeType,
      workMode: workMode,
      skill: skill,
      preference: preference,
    };
    localStorage.setItem("draft", JSON.stringify(data));
  });

  const handleCompany = (selectedOption) => {
    const value = selectedOption ? selectedOption : "";
    setInput({ ...input, companyName: value });
    setError({
      ...error,
      companyName: value ? "" : "Please enter company name",
    });
  };

  const saveAsDraft = () => {
    handleRouteChange();
    closeAll();
    history.push("/portal/drafts")
  };

  const DiscardChanges = () => {
    setInput({
      jobTitle: "",
      companyName: "",
      experienceRequired: "",
      experienceRequired2: "",
      location: "",
      salaryFrom: "",
      salaryTo: "",
      employeeType: "",
      workMode: "",
    });
    setSkill("");
    setPreference([""]);
    setInputValue("");
    setCloseModal(false);
    history.push("/portal/jobs");
  };

  const [companyData, setCompanyData] = useState([]);
  const [companyDomain, setDomain] = useState([]);

  const GetAllCompany = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-company`
      );
      const companyTwo = response.data?.companyValue.map((e) => ({
        domain: e.domainName,
        comId: e.companyId,
      }));
      const companyValue = response.data.company?.map((e) => {
        // Find all domains that match the comId in the companyTwo array
        const matchingDomains = companyTwo
          .filter((domainObj) => domainObj.comId === e.companyId)
          .map((domainObj) => domainObj.domain);

        return {
          label: e.companyName,
          value: e.companyName,
          website: e.website,
          comID: e.companyId,
          companyLogo: e.companyLogo,
          xakalAddress: e.xakalAddress,
          domains: matchingDomains,
        };
      });

      setDomain(companyTwo);
      setCompanyData(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  const [insitituteData, setInstitueData] = useState([]);
  const GetAllInstitute = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-institute`
      );
      const companyValue = response.data.data?.map((e) => ({
        label: e.collegeName,
        value: e.collegeName,
        websites: e.website,
        comID: e.instituteId,
        companyLogo: e.companyLogo,
        xakalAddress: e.xakalAddress,
      }));
      setInstitueData(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    GetAllCompany();
    GetAllInstitute();
  }, []);
  const [organisation, setOrganisation] = useState([]);
  const mergeCompanyAndInstituteData = () => {
    const mergedData = [...companyData, ...insitituteData];
    // Use mergedData as needed (e.g., set state for dropdown options)
    // Example: setDropdownOptions(mergedData);
    setOrganisation(mergedData);
  };
  useEffect(() => {
    mergeCompanyAndInstituteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, insitituteData, companyDomain]);

  // eslint-disable-next-line no-unused-vars
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [enableEmailBtn, setEnableEmailBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorOtpOne, setErrorOtpOne] = useState("");

  const [shouldSave, setShouldSave] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);

  const sendEmailOtp = async () => {
    setResendTimer(60);
    setLoading(true);
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: companyName.website,
        jobId: jobIds,
        verifyId: verifyId,
      };
      try {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/send/domain`,
          payload
        );

        console.log(response, "otpsend");
        toast.success("OTP sent to mail", {
          autoClose: 5000,
          position: "top-right",
        });

        // Start the timer and enable resend only after OTP is successfully sent
        startResendTimer();
        setIsSendOtpClicked(true);
        setOpenOTP(true);

        setEnableEmailBtn(false);
      } catch (error) {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const resendOtp = async () => {
    setResendTimer(60);
    setIsSendOtpClicked(true);
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: companyName.website,
        jobId: jobIds,
        verifyId: verifyId,
      };
      try {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/resend/domain`,
          payload
        );
        console.log(response);
        toast.success("OTP sent to mail", {
          autoClose: 5000,
          position: "top-right",
        });
        startResendTimer();
      } catch (err) {
        toast.error("Failed to send OTP", {
          autoClose: 5000,
          position: "top-right",
        });
        console.log(err.error);
      }
    }
  };
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const verifyOtp = async () => {
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        otp: otps.join(""),
        website: companyName.website,
        jobId: jobIds,
        verifyId: verifyId,
      };
      console.log("otp verify", payload);
      try {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/verify/domain`,
          payload
        );
        console.log(response);
        if (response && response.data && response.data.success === true) {
          setTimeout(() => {
            setOpen(false);
            closeJobOne(jobIds);
          }, 1000);
          setErrorOtp(response.data.message);
        } else if (
          response &&
          response.data &&
          response.data.success === false
        ) {
          // Set the error message for an invalid OTP
          setErrorOtpOne("Invalid OTP");
        }
      } catch (err) {
        console.log("Error fetching details:", err);
      }
    }
  };

  const [error4, setError4] = useState("");
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const extractDomain = (url) => {
    const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/);
    return match ? match[1].toLowerCase() : "";
  };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;

    // Extracting the domain from the company's website
    const companyWebsite = value?.website || "";
    const companyDomain = extractDomain(companyWebsite);
    // List of allowed domains
    const allowedDomains = value?.domains || [];

    if (!validateEmail(enteredEmail)) {
      setError4("Please enter a valid email address.");
      setEnableEmailBtn(false);
    } else {
      const enteredDomain = enteredEmail.split("@")[1].toLowerCase();

      if (
        enteredDomain === companyDomain ||
        allowedDomains.some((domain) =>
          enteredDomain.includes(domain.toLowerCase())
        )
      ) {
        setError4("");
        setEnableEmailBtn(true);
      } else {
        setError4(
          "Invalid email domain. Please use your company email address."
        );
        setEnableEmailBtn(false);
      }
    }

    setEmail(enteredEmail);

    // setEnableEmails(true)
  };

  console.log(email, "email");
  const cancelJob = () => {
    setOpen(false);
    setShouldSave(true);
  };
  const CloseModalClear = () => {
    if (!dirty) {
      closeAll();
    } else {
      cancelJob();
    }
  };
  const [skillData, setSkillData] = useState([]);

  const [optionsOne, setOptionsOne] = useState([]);
  const [skillsDropDown, setSkillsDropDown] = useState([]);

  const getSkill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/skills`
      );
      console.log(JSON.stringify(response), "skilldata");
      const namesArray = response.data.map((item) => item.value);
      console.log(namesArray);
      setSkillData(namesArray);
      setSkillsDropDown(
        namesArray.map((skill) => ({
          value: skill,
          label: skill,
        }))
      );
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    getSkill();
  }, []);

  const [skillcreate, setSkillCreate] = useState("");

  const createSkill = async () => {
    const payload = {
      name: skillcreate,
    };
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/skill`,
        payload
      );
      console.log(JSON.stringify(response.data), "skilldata");
      setSkillCreate("");
      setOptionsOne([]);
      getSkill();
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOne(
        skillData.map((skill) => ({
          value: skill,
          label: skill,
        }))
      );
      setSkillsDropDown(
        skillData.map((skill) => ({
          value: skill,
          label: skill,
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [skillData, skillcreate]);

  const handleCreatable = async (e) => {
    console.log("creaable", e);
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleSkillChnage(e);
    console.log("creatable options", optionsOne);
    // getSkill();
    // setSkillCreate(e.value);
  };
  const getAll = async () => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/place`
      );
      console.log(response, "google places");
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const inputRefs = useRef(otps.map(() => React.createRef()));
  const [valid, setValid] = useState(false);

  const handleChanges = (event, index) => {
    const updatedOtp = [...otps];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtps(updatedOtp);

      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtps(updatedOtp);

    // Clear the error message when any input changes
    // setErrors(null);
    setValid(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      setValid(true);
    } else {
      setValid(false);
    }

    // Focus on the next input field if available
    if (index < otps.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otps.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtps(updatedOtp);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Focus on the next input field if available
    if (index + pastedData.length < otps.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };

  useEffect(() => {
    getAll();
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    try {
      // Get geocode results based on the selected address
      const results = await geocodeByAddress(value);
      console.log(
        results,
        "addressComponentsaddressComponentsaddressComponents"
      );

      // Extract necessary information from the first result
      const addressComponents = results[0].address_components;

      let state = "";
      let city = "";
      let country = "";

      // Iterate through address components and extract state, city, and country
      const includedTypes = [
        "administrative_area_level_1", // state
        "administrative_area_level_2", // city
        "country",
        "locality", // locality
        "political", // political
      ];
      addressComponents.forEach((component) => {
        if (includedTypes.includes(component.types[0])) {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (component.types.includes("administrative_area_level_2")) {
            city = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          }
        }
      });

      // Update your state with the extracted information
      if (
        isCardActive === "manage-job" ||
        isCardActive === "archived" ||
        isCardActive === "draft"
      ) {
        setLocation(value);
      } else {
        setLocation(value.description || value.formatted_address || "");
      }
      setLocation(value);
      setCoordinates(results[0].geometry.location); // Assuming you still want coordinates
      setLocationRequiredTouched({
        ...locationRequiredTouched,
        location: "",
      });
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  const getOneService = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${jobIds}`
      );
      console.log(response, "multiple frequently value");
      const jobData = response.data.updateJob;
      setJobId(response.data.updateJob._id);
      // Set the initial state with the fetched job data
      setVerifyId(jobData.verifyId);
      setInput({
        jobTitle: jobData.jobTitle,
        companyName: jobData.companyName,
        experienceRequired: jobData.experienceRequired,
        location: jobData.location,
        salaryFrom: jobData.salaryFrom,
        salaryTo: jobData.salaryTo,
        // ... other fields
      });

      setExperienceRequired(jobData.experienceRequired);
      setExperienceRequired2(jobData.experienceRequired2);
      setSkill(jobData.skill);
      setWorkMode(jobData.workMode);
      setEmployeeType(jobData.employeeType);
      setLocation(jobData.location);
      setValue(jobData.companyName);
      // Set skill and preference using the fetched data
      setPreference(jobData.preference);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const getOpen = () => {
    setOpen(false);
    getOneService();
    setEmail("");
  };
  useEffect(() => {
    if (jobIds) {
      getOneService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNavigation = () => {
    const path = "/portal/company";
    history.push({
      pathname: path,
      state: {
        isCardActive,
        openNewJob,
      },
    });
  };
  useEffect(() => {
    if (openNewJob) {
      const storedInput = localStorage.getItem("input");
      const storedExperienceRequired =
        localStorage.getItem("experienceRequired");
      const storedExperienceRequired2 = localStorage.getItem(
        "experienceRequired2"
      );
      const storedLocation = localStorage.getItem("location");
      const storedPreference =
        JSON.parse(localStorage.getItem("preference")) || [];
      const storedSkill = JSON.parse(localStorage.getItem("skill")) || [];
      const storedEmployeeType = localStorage.getItem("employeeType");
      const storedWorkMode = localStorage.getItem("workMode");

      setInput(
        storedInput
          ? JSON.parse(storedInput)
          : { jobTitle: "", salaryFrom: "", salaryTo: "" }
      );
      setExperienceRequired(storedExperienceRequired || "");
      setExperienceRequired2(storedExperienceRequired2 || "");
      setLocation(storedLocation || "");
      setPreference(storedPreference);
      setSkill(storedSkill);
      setEmployeeType(storedEmployeeType || "");
      setWorkMode(storedWorkMode || "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save state to local storage whenever state changes
  useEffect(() => {
    localStorage.setItem("input", JSON.stringify(input));
    localStorage.setItem("experienceRequired", experienceRequired);
    localStorage.setItem("experienceRequired2", experienceRequired2);
    localStorage.setItem("location", location);
    localStorage.setItem("preference", JSON.stringify(preference));
    localStorage.setItem("skill", JSON.stringify(skill));
    localStorage.setItem("employeeType", employeeType);
    localStorage.setItem("workMode", workMode);
  }, [
    input,
    experienceRequired,
    experienceRequired2,
    location,
    preference,
    skill,
    employeeType,
    workMode,
  ]);

  const handleClear = () => {
    setInput({ jobTitle: "", salaryFrom: "", salaryTo: "" });
    setExperienceRequired("");
    setExperienceRequired2("");
    setLocation("");
    setPreference([]);
    setSkill([]);
    setEmployeeType("");
    setWorkMode("");
    // Optionally, also clear from local storage
    localStorage.removeItem("input");
    localStorage.removeItem("experienceRequired");
    localStorage.removeItem("experienceRequired2");
    localStorage.removeItem("location");
    localStorage.removeItem("preference");
    localStorage.removeItem("skill");
    localStorage.removeItem("employeeType");
    localStorage.removeItem("workMode");
  };
  return (
    <div>
      <div>
        <ToastContainer />
      </div>
      <div>
        {closemodal ? (
          <div
            className="wrappers2"
            style={{ marginTop: "-80px", height: "170vh" }}
          >
            <div
              className="containerUpload"
              style={{ padding: "20px", marginTop: "700px" }}
            >
              <h4 className="text-name" style={{ color: "black" }}>
                Discard Changes
              </h4>
              <p
                onClick={() => setCloseModal(false)}
                className="close-icon2"
                style={{ top: "700px" }}
              >
                X
              </p>
              <div className="upload-camera">
                <p>Are you sure you want to discard the changes you made?</p>
              </div>
              <div className="uploadButon">
                <h4
                  onClick={() => setCloseModal(false)}
                  style={{ margin: "5px 20px 0 0", color: "#764F90" }}
                >
                  Go Back
                </h4>
                <button className="button2" onClick={DiscardChanges}>
                  Discard
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/*<MainLayout>*/}

        {open && value ? (
          <div>
            <div style={{ display: "flex", gap: "10px", paddingLeft: "30px" }}>
              <img
                src={Back}
                alt="img"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                  marginTop: "25px",
                }}
                onClick={getOpen}
              />
              <Label style={{ fontSize: "18px", marginTop: "30px" }}>
                OTP Verification
              </Label>
            </div>
            <HrLines />
            <div className="modalBody">
              <Label style={{ margin: "0px 0 5px 15px" }}>
                Enter your company email
              </Label>
              <div style={{ margin: "0 15px" }}>
                <input
                  type="text"
                  name="email"
                  className="font"
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #F4F4F6",
                    background: "rgba(54, 42, 65, 0.04)",
                    width: "100%",
                    height: "48px",
                    padding: "20px",
                    marginBottom: "0px",
                  }}
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter email"
                />
                {error4 && (
                  <p className="f12" style={{ marginTop: "0px" }}>
                    {error4}
                  </p>
                )}
              </div>
            </div>
            <div className="modalFooter">
              <button
                className="button3"
                style={{
                  width: "100%",
                  height: "43px",
                  color: "#FFFFFF",
                  backgroundColor: "#362A41",
                  opacity: enableEmailBtn && email ? 1 : 0.5,
                }}
                disabled={!enableEmailBtn || !email}
                onClick={sendEmailOtp}
              >
                {loading ? <Spinner /> : "Get OTP"}
              </button>
            </div>
            <Dialog
              open={openOTP}
              PaperProps={{ style: { width: "430px", height: "340px" } }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "0.5px solid #F4F4F6",
                  padding: "10px 20px",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={Back}
                    alt="img"
                    style={{
                      marginTop: "4px",
                      marginleft: "5px",
                      padding: "3.89px, 2.67px, 3.89px, 2.67px",

                      width: "24px",
                      height: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenOTP(false)}
                  />

                  <Label
                    style={{
                      fontFamily: "Inter-Medium,sans-serif",
                      fontSize: "18px",
                      fontweight: "normal",
                      lineHeight: "23px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      marginTop: "4px",
                    }}
                  >
                    OTP Verification
                  </Label>
                </div>
              </div>

              <DialogContent>
                <div className="modalBody" style={{ padding: "0px 20px" }}>
                  <Label style={{ textAlign: "center", marginTop: "15px" }}>
                    Enter the OTP sent to {`${email}`}{" "}
                  </Label>
                  <div
                    className="otp-group"
                    style={{ margin: "10px auto 20px 40px" }}
                  >
                    {otps.map((digit, idx) => (
                      <input
                        key={idx}
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern="\d"
                        className="otp-inputs"
                        value={digit}
                        id={`otp-input-${idx}`}
                        ref={inputRefs.current[idx]}
                        onChange={(e) => handleChanges(e, idx)}
                        onPaste={(e) => handlePaste(e, idx)}
                      />
                    ))}
                  </div>
                  <div style={{ margin: "-15px 0 0 40px" }}>
                    <span className="f12">{errorOtpOne}</span>
                  </div>

                  <Time>
                    {isSendOtpClicked && (
                      <span>
                        {resendTimer > 0
                          ? `Time Remaining: ${formatTimer(resendTimer)}`
                          : ""}
                      </span>
                    )}
                  </Time>

                  {isSendOtpClicked && resendTimer === 0 ? (
                    <Resend>
                      Didn't receive the code yet?{" "}
                      <span
                        style={{
                          color: "#8652B6",
                          fontweight: "normal",
                          cursor: "pointer",
                        }}
                        onClick={resendOtp}
                      >
                        Resend
                      </span>
                    </Resend>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      color: "green",
                      textAlign: "center",
                      // marginTop: "20px",
                    }}
                  >
                    {errorOtp}
                  </div>
                </div>
              </DialogContent>
              <div className="modalFooter" style={{}}>
                <div>
                  <button
                    className="button3"
                    style={{ width: "400px" }}
                    disabled={!valid}
                    onClick={() => verifyOtp()}
                  >
                    Verify & Get Started
                  </button>
                </div>
              </div>

              {/* </div> */}
            </Dialog>
          </div>
        ) : (
          <PageWrapper>
            <div className="mheader">
              <div className="mTitle">Add New Job</div>
            </div>
            <HrLines />
            <div
              className="modalBody"
              style={{ padding: "0px 20px 80px 20px" }}
            >
              <Label>
                Job Title<span>*</span>
              </Label>
              <InputGroup className="mb-1">
                <Form.Control
                  placeholder="Enter your job title here"
                  aria-label="Job Title"
                  name="jobTitle"
                  value={input?.jobTitle}
                  onBlur={validateInput}
                  aria-describedby="basic-addon1"
                  onChange={handleChange}
                  className="footer-header font"
                  style={{
                    borderRadius: "8px",
                    background: "#F4F4F6",
                    height: "48px",
                    color: "#362A41",
                  }}
                />
              </InputGroup>
              {error.jobTitle && (
                <span className="text-danger f12">{error.jobTitle}</span>
              )}
              <Label>
                Company Name<span>*</span>
              </Label>

              <FieldWrapper>
                <div style={{ width: "100%" }}>
                  <Select
                    // className="footer-header mb-3"
                    isSearchable={true}
                    name="companyName"
                    isClearable={false}
                    value={input?.companyName}
                    placeholder="Enter your company name "
                    components={{ Placeholder, DropdownIndicator }}
                    onBlur={() =>
                      validateInput({
                        target: {
                          name: "companyName",
                          value: input.companyName,
                        },
                      })
                    }
                    onChange={handleCompany}
                    options={organisation}
                    className="font"
                    styles={customStyle}
                  />

                  {error.companyName && (
                    <span className="text-danger f12">{error.companyName}</span>
                  )}
                </div>

                <div>
                  <ButtonLink onClick={handleNavigation}>
                    <img src={Addcircle} alt="img" />
                    New Company
                  </ButtonLink>
                </div>
              </FieldWrapper>

              <Label>
                Experience Required<span>*</span>
              </Label>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  marginTop: "0px",
                  width: "100%",
                }}
              >
                <div style={{ width: "50%" }}>
                  {/* <Label>Min</Label> */}
                  <Select
                    className="font"
                    styles={customStyle}
                    components={{ Placeholder, DropdownIndicator }}
                    name="experienceRequired"
                    placeholder="Min exp"
                    value={options2.find(
                      (option) => option.value === experienceRequired
                    )}
                    onBlur={validateInput}
                    onChange={handleExperienceChange}
                    options={options2}
                    isSearchable={true}
                  />

                  <span className="text-danger f12">
                    {error.experienceRequired}
                  </span>
                </div>
                <Label style={{ marginTop: "15px" }}>to</Label>
                <div style={{ width: "50%" }}>
                  {/* <Label>Max</Label> */}
                  <Select
                    className="font"
                    styles={customStyle}
                    components={{ Placeholder, DropdownIndicator }}
                    name="experienceRequired2"
                    placeholder="Max exp"
                    value={options7.find(
                      (option) => option.value === experienceRequired2
                    )}
                    onBlur={validateInput}
                    onChange={handleExperience2Change}
                    options={options7}
                    isSearchable={true}
                  />
                  <span className="text-danger f12">
                    {error.experienceRequired2}
                  </span>
                </div>
              </div>
              <Label>
                Location<span>*</span>
              </Label>
              <div>
                <PlacesAutocomplete
                  value={location}
                  onChange={setLocation}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Enter your work location",
                          className: "location-search-input",
                          onBlur: () => setLocationRequiredTouched(true),
                        })}
                        style={{ width: "100%", paddingLeft: "10px" }}
                        className="footer-header font"
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            width: "100%", // Set the width to 100%
                            height: "48px",
                            backgroundColor: "#F4F4F6",
                            border: "1px solid #F4F4F6",
                            borderRadius: "8px",
                            marginBottom: "5px",
                          }),
                        }}
                      />
                      <div
                        className="autocomplete-dropdown-container"
                        // style={{ width: "100%" }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions?.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fff",
                                cursor: "pointer",
                                marginTop: "10px",
                                marginBottom: "10px",
                                fontSize: "12px",
                                color: "#FFF",
                                borderRadius: "0px",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                height: "fit-content",
                                marginTop: "10px",
                                cursor: "pointer",
                                fontSize: "12px",
                                color: "#362A41",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span
                                style={{
                                  color: "#362A41", // Add hover text color
                                  padding: "5px",
                                  background: "#C6C6C6",
                                  borderRadius: "8px",
                                  width: "calc(100% - 6px)",
                                  height: "fit-content",
                                  margin: "0px 0px 0px 0px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {locationRequiredTouched.location && (
                  <span className="text-danger f12">
                    {locationRequiredTouched.location}
                  </span>
                )}
              </div>

              <Label>
                Salary Range<span>*</span>
              </Label>
              {/* <Wrapper>
                <Label style={{ width: "50%", marginTop: "5px" }}>
                  From<span>*</span>
                </Label>
                <Label style={{ width: "50%", marginTop: "5px" }}>To</Label>
              </Wrapper> */}
              <Wrapper style={{ width: "100%", marginTop: "5px" }}>
                <InputGroup
                  // className="mb-3"
                  style={{ width: "50%", borderRadius: "5px" }}
                >
                  <Form.Control
                    placeholder="₹ ------ L"
                    type="number"
                    aria-label="Job Title"
                    name="salaryFrom"
                    value={input?.salaryFrom}
                    onBlur={validateInput}
                    onChange={handleChangesOne}
                    className="font"
                    style={{
                      background: "rgba(54, 42, 65, 0.04)",
                      height: "48px",
                    }}
                  />
                </InputGroup>
                <Label style={{ marginTop: "15px" }}>to</Label>
                <InputGroup
                  className="mb-1"
                  style={{ width: "50%", borderRadius: "5px" }}
                >
                  <Form.Control
                    placeholder="₹ ------ L"
                    type="number"
                    aria-label="Job Title"
                    name="salaryTo"
                    value={input?.salaryTo}
                    aria-describedby="basic-addon1"
                    className="font"
                    style={{
                      background: "rgba(54, 42, 65, 0.04)",
                      height: "48px",
                    }}
                    onBlur={validateInput}
                    onChange={handleChangesOne}
                  />
                </InputGroup>
              </Wrapper>
              {error.salaryFrom && (
                <span className="text-danger f12">{error.salaryFrom}</span>
              )}
              {error.salaryTo && (
                <span className="text-danger f12" style={{}}>
                  {error.salaryTo}
                </span>
              )}
              <Label>Employment Type</Label>
              <div>
                <Select
                  className="footer-header  mb-3 font "
                  styles={customStyle}
                  components={{ Placeholder, DropdownIndicator }}
                  name="employeeType"
                  placeholder="Select the type of employment"
                  value={options5.find(
                    (option) => option.value === employeeType
                  )}
                  onBlur={validateInput}
                  onChange={handleEmployementChange}
                  isSearchable={true}
                  options={options5}
                />
              </div>

              <Label>Work Mode</Label>
              <div>
                <Select
                  className="footer-header  mb-3 font"
                  styles={customStyle}
                  components={{ Placeholder, DropdownIndicator }}
                  name="workMode"
                  value={options6.find((option) => option.value === workMode)}
                  onBlur={validateInput}
                  onChange={handleWorkChange}
                  isSearchable={true}
                  options={options6}
                  placeholder="Type / Select"
                />
              </div>

              <Label>Preference</Label>

              <FieldWrapper>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="font"
                  name="preference"
                  placeholder="Add your preference "
                  onBlur={() => setPreferenceRequiredTouched(true)}
                  maxLength={16}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #F4F4F6",
                    background: "rgba(54, 42, 65, 0.04)",
                    width: "100%",
                    height: "48px",
                    padding: "20px",
                    marginBottom: "5px",
                  }}
                />
                <ButtonLink onClick={handleAddInput}>
                  <img src={Addcircle} alt="img" />
                  Add Preference
                </ButtonLink>
              </FieldWrapper>
              {preference &&
                preference.map((input, index) => (
                  <div
                    key={index}
                    style={{
                      display: "inline-flex",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <JobPreference>
                      {input}
                      {input !== "" ? (
                        <button onClick={() => handleRemoveInput(index)}>
                          X
                        </button>
                      ) : (
                        ""
                      )}
                    </JobPreference>
                  </div>
                ))}

              {prefrenceRequiredTouched &&
                prefrenceRequiredTouched.preference && (
                  <span className="text-danger f12">
                    {prefrenceRequiredTouched.preference}
                  </span>
                )}
              <Label>Skills</Label>
              <div>
                <CreatableSelect
                  isMulti
                  noOptionsMessage={() => null}
                  isValidNewOption={() => optionsOne.length}
                  menuPlacement="top"
                  styles={customStyle}
                  components={{ Placeholder, DropdownIndicator }}
                  className="font"
                  name="skill"
                  placeholder="Select your skills"
                  onChange={(e) => handleCreatable(e)}
                  onInputChange={(e) => setSkillCreate(e)}
                  options={optionsOne}
                  maxLength={16}
                  value={skillsDropDown.filter((e) => skill?.includes(e.value))}
                />
              </div>
            </div>
            <div className="modalFooter modalfooter">
              <button
                className="button5"
                onClick={() => {
                  CloseModalClear();
                  // handleClear();
                }}
              >
                Cancel
              </button>
              {jobIds ? (
                <button
                  className="button3"
                  onClick={newJobs2}
                  style={{ width: "83px" }}
                >
                  {loading ? <Spinner /> : "Next"}
                </button>
              ) : (
                <button
                  className="button3"
                  onClick={newJobs}
                  style={{ width: "83px" }}
                >
                  {loading ? <Spinner /> : "Next"}
                </button>
              )}

              {/* </Button3> */}
            </div>
          </PageWrapper>
        )}
        <Dialog
          open={shouldSave}
          PaperProps={{
            style: {
              borderRadius: "8px",
              top: "10px",
              transform: "translateY(0%)",
              position: "absolute",
              backgroundColor: "#F4F4F6",
              padding: "5px 8px",
              // height: "56px",
              overflow: "hidden",
            },
          }}
          halfWidth
          maxWidth="md"
          className="def"
        >
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
            }}
          >
            <JobTitle
              style={{
                fontFamily: "Inter-Medium,sans-serif",
                fontSize: "14px",
                fontweight: "normal",
                lineHeight: "37px",
                gap: "10px",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "8px",
                  gap: " 8px",
                  background: "white",
                }}
              >
                <img
                  src={QuestionImg}
                  style={{ marginTop: "2px", marginLeft: "9px" }}
                  alt="img"
                />
              </div>
              Save changes as draft ?
            </JobTitle>
            <DialogActions>
              <div
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button4
                  onClick={() => closeAll()}
                  style={{ cursor: "pointer" }}
                >
                  No
                </Button4>
                {jobIds ? (
                  <Button6 style={{ cursor: "pointer" }} onClick={newJobs3}>
                    Yes, Save
                  </Button6>
                ) : (
                  <Button6
                    style={{ cursor: "pointer" }}
                    onClick={() => saveAsDraft()}
                  >
                    Yes, Save
                  </Button6>
                )}
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default NewJob;
