/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./buttonStyle.css";
import image from "../../images/company.png";
import "./jobs.css";
import "./search.css";
import "../interview/interview.css";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import iExp from "../../images/icons/exp.svg";
import gradBg from "../../images/gradient-bg.png";
import noData from "../../images/NoData.svg";
import iSalary from "../../images/icons/salary.svg";
import iLocation from "../../images/icons/location.svg";
import iHome from "../../images/icons/home.svg";
import iClock from "../../images/icons/clock.svg";
import iBookmark from "../../images/icons/bookmark.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import RecentSearch from "./recentSerchDetails";
import SearchDetails from "./searchdetails";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import Select from "react-select";
import { Country, State } from "country-state-city";
import AllFilters from "./allFilters";
import AllJobFilters from "./AllJobFilters";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Image from "../../images/company.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import companyLogo from "../../images/company.png";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Fitme from "./fitme";
import ViewJobApplicant from "./jobApplication";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Amplify, Auth } from "aws-amplify";
import Common from "../../utils/common";
import Settings from "../settings";
import Favourite from "../../images/FavouriteSaved.png";
import Favourites from "../../images/Bookmark.png";
import Upload from "../../images/Upload Minimalistic.svg";
import Spinner from "react-bootstrap/Spinner";
import CloseIcon from "../../images/CloseIcon.svg";
import InterviewPopup from "../MyProfile/interviewPopup";
import ExploreTimeZone from "../MyProfile/timeExploreInterview";
import SlotInterview from "../MyProfile/slotexplore";
import { HiOutlineMail } from "react-icons/hi";
import { CiLinkedin } from "react-icons/ci";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import star from "../../images/icons/star.svg";
import EditTimePicker from "../MyProfile/editTimePicker";
import languages from "languages-list";
import uni from "../MyProfile/universities.json";
const NativeLink = styled.div``;

const Button2 = styled.div``;
const Button3 = styled.div``;
const Button5 = styled.div``;
const Button4 = styled.div`
  color: rgba(45, 49, 57, 1);
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  cursor: pointer;
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgba(253, 33, 69, 1);
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 5px;
`;

const Wrapper = styled.div``;
const JobDetail = styled.div``;

const JobTitle = styled.div`
  font-weight: normal;
`;

const IconTitle = styled.div``;
const DateWrapper = styled.div``;
const DateWrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;
const QuestionLabel = styled.label`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const Time = styled.div`
  margin-left: auto;
  font-size: 14px;
  margin-top: 5px;
`;

const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4f4953;
  margin: 10px 0 5px 20px;
`;

const PopUpTitles = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const FileTypeInfo = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #4f4953;
  position: relative;
  right: 15px;
  bottom: 20px;
  margin: 10px 0 0 10px;
  span {
    color: #ff596a;
  }
`;
const FileTypeInfos = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #4f4953;
  // margin-left: 5px;
  span {
    color: #ff596a;
    margin-top: 20px;
  }
`;
const DateWrapper1 = styled.div``;
const HrLine = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 0;
`;

const HrLines = styled.div``;

const PreferenceWrapper = styled.div``;
const Preference = styled.div``;
const JobPreference = styled.div``;

const HeaderLayout = styled.div``;

const CardWrapper = styled.div``;

const Text = styled.div`
  text-align: center;
  margin: 20px;
`;

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Search = (props) => {
  const { state } = useLocation();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLangugae] = useState([]);

  useEffect(() => {
    const options = languages.map((language) => ({
      value: language, // or use a unique code if available
      label: language,
    }));

    setLanguageOptions(options);
  }, []);
  const handleLanguageChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedLangugae(selectedOptions);
  };
  const openFormVaue = state?.openTrue;
  const openValue = state?.oneTrues;
  const datas = state?.selectedData;
  const selectedViews = state?.selectedView;

  const [oneTrue, setOneTrue] = useState(openValue);
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const [error2, setError2] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [data, setData] = useState([]);
  const [interviewData, setInterviewData] = useState([]);
  const [interviewDataDup, setInterviewDataDup] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [dataDup, setDataDup] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [favourites, setFavourites] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isFavorite, setIsFavorite] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [question, setQuestion] = useState();
  const [open4, setOpen4] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [exhausted, setExhausted] = useState(true);

  const [userId, setUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [associates, setAssociates] = useState([]);
  const [associatesDuplicates, setAssociatesDuplicates] = useState([]);

  const [users, setUsersList] = useState([]);
  const [selectedView, setSelectedview] = useState("profile");
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedQuestionList, setSelectedQuestionList] = useState([]);

  const [formData, setFormData] = useState({
    answers: [], // Store answers as an array of objects
  });
  const [file, setFile] = useState(null);
  const [detailOne, setDetail] = useState();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [recentsearchOpen, setRecentSearchOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [userApplied, setUserApplied] = useState(false);
  const [favouriteApplied, setFavouriteApplied] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    // skill: "",
    state: "",
    language: "",
    proficiency: "",
    dob: "",
    title: "",
    gender: "",
    pincode: "",
    phoneNumber: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
  });
  const [ids, setIds] = useState("");
  const [openDate, setOpenDate] = useState(false);

  const [inputId, setInputId] = useState();
  const [openConfirm, setConfirmPopup] = useState(false);
  const [slotId, setSlotId] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openTrue, setOpenTrue] = useState(true);
  const [savedInterviews, setSavedInterviews] = useState([]);
  const [userApppliedJob, setUserAppliedJob] = useState([]);
  const [social, setOpenSocial] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [editPicker, setEditPicker] = useState(false);
  const [dataOne, setDataOne] = useState();
  const [dataOneResume, setDataOneResume] = useState();
  const [company, setCompany] = useState([]);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    if (oneTrue === true) {
      setOpenForm(openFormVaue);
      setSelectedData(datas);
      setSelectedview(selectedViews);
    }
  }, []);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);

  const getAssociatesList = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get/all/associates/${Common.loggedIn()}`
      );

      getblockedList(res?.data);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  useEffect(() => {
    getAssociatesList();
    getAllActiveInterview();
  }, []);
  const getblockedList = async (overallData) => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/blocked/meByUsers/${Common.loggedIn()}`
      );

      // Extract blocked user IDs
      let blockedIds = [];
      for (const record of response?.data) {
        blockedIds.push(record?.appUserId);
      }
      const removeBlocked = overallData?.filter(
        (item) => !blockedIds.includes(item?.userId)
      );
      setAssociates(removeBlocked);
      setUsersList(removeBlocked);
      setAssociatesDuplicates(removeBlocked);

      // Update states with users having experience details
    } catch (error) {
      console.log("Error fetching blocked users:", error);
    }
  };

  const getAllJob = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getall-active`
      );

      if (response.data) {
        if (response.data.job.length === 0) {
          setExhausted(false);
        } else {
          setPagination(response.data.pageCount);
          const uniqueJobs = response.data.job;

         
          setData((current) => {
            if (current) {
              return [...current, ...uniqueJobs];
            } else {
              return [];
            }
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const getAllJobReset = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-job-detail-skill?p=${pagination}`
      );

      if (response.data) {
        if (response.data.activeJobsOne.length === 0) {
          setExhausted(false);
        } else {
          setPagination(response.data.pageCount);
          setData(response.data.activeJobsOne);
          setLoading(false);
          setDataDup(response.data.activeJobsOne);
          setQuestion(response.data.job);
        }
      }
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const toggleFavorite = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setIsFavorite((prevIsFavorite) => !prevIsFavorite);
        setPagination(1);
        getAllJobReset();
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleFavoriteTwo = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setIsFavorite((prevIsFavorite) => !prevIsFavorite);
        setQuestion(() => {
          return [];
        });
        getAllJobReset();
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleUpdateFavoriteOne = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setIsFavorite((prevIsFavorite) => !prevIsFavorite);
        setPagination(1);

        getAllJobReset();
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleUpdateFavoriteTwo = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setIsFavorite((prevIsFavorite) => !prevIsFavorite);
        setPagination(1);
        setScheduleData(() => {
          return [];
        });
        setFavourites(() => {
          return [];
        });
        setQuestion(() => {
          return [];
        });
        getAllJobReset();
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  useEffect(() => {
    getAllJob();
  }, []);

  const handleFunctionClose = async () => {
    try {
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/apply-submit/${selectedId}/${userId.userId}`
      );

      if (response.status === 200) {
        const appliedJobs =
          JSON.parse(localStorage.getItem("appliedJobs")) || [];
        localStorage.setItem(
          "appliedJobs",
          JSON.stringify([...appliedJobs, selectedId])
        );
        setUserAppliedJob((prevSavedInterviews) => [
          ...prevSavedInterviews,
          selectedId,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
      setOpen3(false);
      setActiveCard(selectedId);
      setFile("");
      toast.success("Job Applied Successfully...");
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  const handleApplyClick = (id, questionList, applyThrough, urls) => {
    if (applyThrough === "companySite") {
      window.open(urls, "_blank");
    } else {
      setSelectedId(id); // Set the selected id in the state

      if (
        Array.isArray(questionList) &&
        questionList.length > 0 &&
        questionList.some((q) => q.questionName.trim() !== "")
      ) {
        setOpen(true);
        setSelectedQuestionList(questionList);
      } else {
        setOpen2(true);
      }
    }
  };

  const handleInputChange = (e, questionId) => {
    const newAnswers = { ...formData.answers };
    newAnswers[questionId] = { questionId, answer: e.target.value };
    setFormData((prevData) => ({ ...prevData, answers: newAnswers }));
  };
  const closeAnswer = () => {
    setOpen(false);
    setFormData({
      answers: [], // Resetting the answers array to an empty array
    });
  };
  const checkUnansweredMandatoryQuestions = () => {
    const unansweredMandatoryQuestions = selectedQuestionList.filter(
      (question) => {
        const answer = formData.answers[question._id];
        const isMandatory = question.isMandatory === true;
        const isStarred = question.isStarred === true;
        const condition1 = isMandatory && isStarred;
        const condition2 = isMandatory && !isStarred;
        const isUnanswered = !answer || answer.answer.trim() === "";
        return (condition1 || condition2) && isUnanswered;
      }
    );

    return unansweredMandatoryQuestions;
  };
  const handleSubmitOne = async () => {
    try {
      // Create an array of answers based on the questions
      const answers = sortedQuestions.map((question) => ({
        questionId: question._id,
        answer: formData.answers[question._id]?.answer || "",
      }));

      const unansweredMandatoryQuestions = checkUnansweredMandatoryQuestions();

      if (unansweredMandatoryQuestions.length > 0) {
        toast.error("Please answer all mandatory questions.");
      } else {
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/add-answer/${selectedId}`, {
            answers: answers, // Send the answers array
          })
          .then(() => {
            setFormData({
              ...formData,
              answers: Array(formData.answers.length).fill(""),
            });
          })
          .catch((err) => {
            console.error(err);
          });

        // Handle the response here as needed
        setOpen(false);
        setOpen2(true);
      }
    } catch (error) {}
  };

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();

  const formattedYear = currentYear.toString();
  const handleFileChnage = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 2 * 1024 * 1024; // 2MB
    const allowedFileTypes = ["pdf", "doc", "docx"];

    if (
      selectedFile &&
      selectedFile.size <= fileSizeLimit &&
      allowedFileTypes.some((type) =>
        selectedFile.name.toLowerCase().endsWith(type)
      )
    ) {
      setFile(selectedFile);
      setError2("");
    } else {
      // Display an error message or take any other action
      setFile(null);
      setError2(
        "File Size should not exceed 2mb and PDF, Word doc only allowed."
      );
    }
  };

  const handlePreview = () => {
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["pdf", "doc", "docx"].includes(fileExtension)) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="80px"
              height="50px"
            />
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
  };
  const handlePreviews = () => {
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["pdf", "doc", "docx"].includes(fileExtension)) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="80px"
              height="50px"
            />
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
  };

  const handleSubmitTwo = async () => {
    if (error2 === "") {
      setLoading(true); // Set loading state to true

      const formData1 = new FormData();
      formData1.append("image", file);

      try {
        const res = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/`,
          formData1
        );

        if (res.data) {
          const params = {
            url: res.data.url ? res.data.url : "",
          };
          try {
            await axios.post(
              `${process.env.PUBLIC_URL}/xakal/apply/${selectedId}`,
              params
            );

            setOpen2(false);
            setOpen3(true);
            fetchData();
          } catch (error) {
            console.log("Error fetching details:", error);
          } finally {
            setLoading(false); // Set loading state to false on completion
          }
        }
      } catch (error) {
        console.log("Error fetching details:", error);
      }
    } else {
      setError2(
        "File Size should not exceed 2mb and PDF, Word doc only allowed."
      );
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/jobs/${selectedId}/applicants/questions`
      );

      const questionList = response.data.job.questionList;
      const sortedQuestionList = questionList.sort((a, b) => {
        return b.starred - a.starred;
      });
      setDataOne(sortedQuestionList);
      setDataOneResume(response.data);
      // Continue with the rest of your logic, for example, call setOpen2 and setOpen3.
      setOpen2(false);
      setOpen3(true);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const handleWithdrawClick = async (jobId) => {
    try {
      // Send a PUT request to withdraw the job
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`
      );

      if (response.status === 200) {
        setUserAppliedJob((prevUserAppliedJob) => {
          return prevUserAppliedJob.filter((id) => id !== jobId);
        });

        const appliedJobs =
          JSON.parse(localStorage.getItem("appliedJobs")) || [];
        const updatedAppliedJobs = appliedJobs.filter((id) => id !== jobId);
        localStorage.setItem("appliedJobs", JSON.stringify(updatedAppliedJobs));

        setUserApplied(false);
        setFavouriteApplied(false);
        setOpen4(false);
        setActiveCard(jobId);
        toast.success("Job withdrawn successfully");
      } else {
        console.error("Failed to withdraw job");
      }
    } catch (error) {
      console.error("Error withdrawing job:", error);
    }
  };
  const getUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getuser`
      );

      setUser(response.data);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const getUserChat = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      );

      setDetail(res.data.aboutOne);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  useEffect(() => {
    getUserChat();
  }, []);
  useEffect(() => {
    getUser();
  }, []);
  const [searchKeyword, setSearchKeyword] = useState("");

  const captureSearchedData = (value) => {
    handleInputOneChange(value);
  };

  // const handleInputOneChange = (event) => {
  //   setSearchKeyword(event);
  //   const defaultJobs = dataDup;
  //   const defaultCompany = company;
  //   const defaultAssociated = associatesDuplicates;
  //   if (event?.length > 0) {
  //     if (associatesDuplicates?.length > 0) {
  //       const filterAssociates = associatesDuplicates?.filter(
  //         (assc) =>
  //           (assc?.firstName &&
  //             assc?.firstName?.toLowerCase().includes(event?.toLowerCase())) ||
  //           (assc?.lastName &&
  //             assc?.lastName?.toLowerCase().includes(event?.toLowerCase())) ||
  //           (assc?.middleName &&
  //             assc?.middleName?.toLowerCase().includes(event?.toLowerCase())) ||
  //           (assc?.firstName &&
  //             assc?.lastName &&
  //             (assc?.firstName + assc?.lastName)
  //               .toLowerCase()
  //               .includes(event?.toLowerCase().replace(" ", ""))) ||
  //           (assc?.firstName &&
  //             assc?.middleName &&
  //             assc?.lastName &&
  //             (assc?.firstName + assc?.middleName + assc?.lastName)
  //               .toLowerCase()
  //               .includes(event?.toLowerCase().replace(" ", ""))) ||
  //           (assc?.coreSkill &&
  //             assc?.coreSkill.length > 0 &&
  //             assc?.coreSkill.findIndex((a) => {
  //               return (
  //                 (typeof a === "object" &&
  //                   a?.value?.toLowerCase() === event?.toLowerCase()) ||
  //                 (typeof a != "object" &&
  //                   a?.toLowerCase() === event?.toLowerCase())
  //               );
  //             }) !== -1) ||
  //           (assc?.softSkill &&
  //             assc?.softSkill.length > 0 &&
  //             assc?.softSkill.findIndex((a) => {
  //               return (
  //                 (typeof a === "object" &&
  //                   a?.value?.toLowerCase() === event?.toLowerCase()) ||
  //                 (typeof a != "object" &&
  //                   a?.toLowerCase() === event?.toLowerCase())
  //               );
  //             }) !== -1)
  //       );
  //       setUsersList(filterAssociates);
  //     }

  //     if (interviewDataDup?.length > 0) {
  //       const filterInterview = interviewDataDup?.filter(
  //         (assc) =>
  //           (assc?.interviewTitle &&
  //             assc?.interviewTitle
  //               ?.toLowerCase()
  //               .includes(event?.toLowerCase())) ||
  //           (assc?.skill &&
  //             assc?.skill.length > 0 &&
  //             assc?.skill.findIndex((a) => {
  //               return (
  //                 (typeof a === "object" &&
  //                   a?.value?.toLowerCase() === event?.toLowerCase()) ||
  //                 (typeof a != "object" &&
  //                   a?.toLowerCase() === event?.toLowerCase())
  //               );
  //             }) !== -1)
  //       );
  //       setInterviewData(filterInterview);
  //     }

  //     if (defaultJobs?.length > 0) {
  //       const filterJobs = defaultJobs?.filter(
  //         (e) =>
  //           (e?.jobTitle &&
  //             e?.jobTitle
  //               ?.toLowerCase()
  //               .replace(" ", "")
  //               .includes(event.toLowerCase().replace(" ", ""))) ||
  //           (e?.skill &&
  //             e?.skill.length > 0 &&
  //             e?.skill.findIndex((a) => {
  //               return (
  //                 (typeof a === "object" &&
  //                   a?.value?.toLowerCase() === event?.toLowerCase()) ||
  //                 (typeof a != "object" &&
  //                   a?.toLowerCase() === event?.toLowerCase())
  //               );
  //             }) !== -1)
  //       );
  //       setData(filterJobs);
  //     }
  //     if (defaultCompany?.length > 0) {
  //       const filterCompany = defaultCompany?.filter(
  //         (assc) =>
  //           assc?.companyName &&
  //           assc?.companyName?.toLowerCase()
  //           .replace(" ", "")
  //           .includes(event.toLowerCase().replace(" ", ""))
  //       );
  //       setCompany(filterCompany);
  //     }
  //   } else {
  //     setUsersList(defaultAssociated);
  //     setData(defaultJobs);
  //     setCompany(defaultCompany)
  //   }
  // };
  const [filteredApplicants, setFilteredApplicants] = useState([]);
  const [filteredJobs, setFilteredJob] = useState([]);

  const handleInputOneChange = async (event) => {
    const searchTerm = event?.toLowerCase().trim() || ""; // Ensure search term is a clean, lowercase string

    setSearchKeyword(event); // Update state with the current search term

    const defaultJobs = data;
    const defaultCompany = company;
    const defaultAssociated = associatesDuplicates;

    if (searchTerm.length > 0) {
      // Filter Associates
      if (associatesDuplicates?.length > 0) {
        const filterAssociates = associatesDuplicates.filter(
          (assc) =>
            (assc?.firstName &&
              assc?.firstName.toLowerCase().includes(searchTerm)) ||
            (assc?.lastName &&
              assc?.lastName.toLowerCase().includes(searchTerm)) ||
            (assc?.middleName &&
              assc?.middleName.toLowerCase().includes(searchTerm)) ||
            (assc?.firstName &&
              assc?.lastName &&
              (assc?.firstName + assc?.lastName)
                .toLowerCase()
                .includes(searchTerm)) ||
            (assc?.firstName &&
              assc?.middleName &&
              assc?.lastName &&
              (assc?.firstName + assc?.middleName + assc?.lastName)
                .toLowerCase()
                .includes(searchTerm)) ||
            assc?.coreSkill?.some((a) =>
              typeof a === "object"
                ? a?.value?.toLowerCase() === searchTerm
                : a.toLowerCase() === searchTerm
            ) ||
            assc?.softSkill?.some((a) =>
              typeof a === "object"
                ? a?.value?.toLowerCase() === searchTerm
                : a.toLowerCase() === searchTerm
            )
        );
        setFilteredApplicants(filterAssociates);
      }

      // Filter Interview Data
      if (interviewDataDup?.length > 0) {
        const filterInterview = interviewDataDup.filter(
          (interview) =>
            (interview?.interviewTitle &&
              interview?.interviewTitle.toLowerCase().includes(searchTerm)) ||
            interview?.skill?.some((a) =>
              typeof a === "object"
                ? a?.value?.toLowerCase() === searchTerm
                : a.toLowerCase() === searchTerm
            )
        );
        setInterviewData(filterInterview);
      }

      // Filter Jobs
      if (defaultJobs?.length > 0) {
        const filterJobs = defaultJobs.filter(
          (job) =>
            (job?.jobTitle &&
              job?.jobTitle
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(searchTerm.replace(/\s+/g, ""))) ||
            job?.skill?.some((a) =>
              typeof a === "object"
                ? a?.value?.toLowerCase() === searchTerm
                : a.toLowerCase() === searchTerm
            )
        );
        setFilteredJob(filterJobs);
      }

      // Filter Companies
      if (defaultCompany?.length > 0) {
        const filterCompany = defaultCompany.filter(
          (comp) =>
            (comp?.companyName &&
              comp?.companyName
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(searchTerm.replace(/\s+/g, ""))) ||
            (comp?.website &&
              comp?.website
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(searchTerm.replace(/\s+/g, "")))
        );
        setCompany(filterCompany);
      }
    } else {
      setFilteredApplicants(defaultAssociated);
      setFilteredJob(defaultJobs);
      await getAllCompany();
    }
  };

  const [jobWithdrawId, setWithdrawIdToDelete] = useState(null);
  const sortedQuestions = selectedQuestionList.slice().sort((a, b) => {
    if (a.isMandatory && a.isStarred && (!b.isMandatory || !b.isStarred)) {
      return -1; // A comes before B if A is both mandatory and starred, and B is not
    } else if (
      b.isMandatory &&
      b.isStarred &&
      (!a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is both mandatory and starred, and A is not
    } else if (
      !a.isMandatory &&
      a.isStarred &&
      (b.isMandatory || !b.isStarred)
    ) {
      return -1; // A comes before B if A is not mandatory but starred, and B is mandatory or not starred
    } else if (
      !b.isMandatory &&
      b.isStarred &&
      (a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is not mandatory but starred, and A is mandatory or not starred
    } else {
      return 0; // No change in order for other cases
    }
  });

  const handleSave = (data) => {
    setPopupOpen(false); // Close the dialog if needed
  };
  const [openSetting, setOpenSetting] = useState(false);
  const handleWithdrawPopUpClick = async (jobId) => {
    // Make an API request to update the statusOne field to "withdrawn"
    try {
      await axios.put(`${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`);

      setPagination(1);
      setData(() => {
        return [];
      });

      setDataDup(() => {
        return [];
      });
      setScheduleData(() => {
        return [];
      });
      setFavourites(() => {
        return [];
      });
      setQuestion(() => {
        return [];
      });
      getAllJob();

      setOpen5(false);
      setIsOpen(false);
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const togglePopUpFavorite = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setFavouriteApplied((prevIsFavorite) => !prevIsFavorite);
        setPagination(1);
        setActiveCard(null);
        // setData(() => {
        //   return [];
        // });
        //
        // setDataDup(() => {
        //   return [];
        // });
        setScheduleData(() => {
          return [];
        });
        setFavourites(() => {
          return [];
        });
        setQuestion(() => {
          return [];
        });
        getAllJobReset();
      } else {
        console.error("Failed to toggle favorite status");
      }

      setIsOpen(false);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleUpdatePopFavoriteOne = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setIsFavorite((prevIsFavorite) => !prevIsFavorite);
        setPagination(1);
        setActiveCard(null);
        // setData(() => {
        //   return [];
        // });
        //
        // setDataDup(() => {
        //   return [];
        // });
        setScheduleData(() => {
          return [];
        });
        setFavourites(() => {
          return [];
        });
        setQuestion(() => {
          return [];
        });
        getAllJobReset();
      } else {
        console.error("Failed to toggle favorite status");
      }

      setIsOpen(false);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const openPopup = (jobData) => {
    setSelectedJobData(jobData);
    setActiveCard(jobData._id);
    const applicant = jobData.applicants.find(
      (applicant) => applicant.userId === userId.userId
    );
    const userApplied =
      (applicant && applicant.statusOne === "Applied") ||
      (applicant && applicant.statusOne === "shortlist") ||
      (applicant && applicant.statusOne === "engage") ||
      (applicant && applicant.statusOne === "interviewed") ||
      (applicant && applicant.statusOne === "offered");

    const favourite = jobData.favoritedBy.find(
      (fav) => fav.userId === userId.userId
    );
    const favouriteApplied =
      favourite && favourite.statusFavourite === "favourites";

    setUserApplied(userApplied);
    setFavouriteApplied(favouriteApplied);

    setIsOpen(true);
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <Time>{timeAgo}</Time>;
  };

  let id = Common.loggedIn();

  const prefillValue = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`
      );

      if (response && response.data && response.data.success) {
        setInput({
          ...input,
          userId: response.data.aboutOne.userId,
          id: response.data.aboutOne._id,
          firstName: response.data.aboutOne.firstName,
          lastName: response.data.aboutOne.lastName,
          middleName: response.data.aboutOne.middleName,
          email: response.data.aboutOne.email,
          bio: response.data.aboutOne.bio,
          city: response.data.aboutOne.city,
          state: response.data.aboutOne.state,
          avatar: response.data.aboutOne.avatar,
          country: response.data.aboutOne.country,
          language: response.data.aboutOne.language,
          proficiency: response.data.aboutOne.proficiency,
          about: response.data.aboutOne.about,
          gender: response.data.aboutOne.gender,
          workProfile: response.data.aboutOne.workProfile,
          urlLink: response.data.aboutOne.urlLink,
          phoneNumber: response.data.aboutOne.phoneNumber,
          inputList: response.data.aboutOne.inputList,
          inputList2: response.data.aboutOne.inputList2,
          inputList3: response.data.aboutOne.inputList3,
          createdDate: response.data.aboutOne.createdDate,
        });
      }
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const settings = {
    autoplay: true,
    arrows: true, // Set arrows to true to make them visible
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };

  const openDateForm = (id) => {
    setOpenDate(true);
    setIds(id);
    setInputId(id);
  };

  const openConfirmPopup = (id) => {
    setConfirmPopup(true);
    setOpenDate(false);
    setSlotId(id);
  };
  const openFirst = (id) => {
    setOpenDate(true);
    setConfirmPopup(false);
    setSlotId(id);
  };
  const close = () => {
    setConfirmPopup(false);
  };
  // eslint-disable-next-line no-unused-vars
  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    document.title = "Interviews | Xakal";
  }, []);

  useEffect(() => {
    prefillValue();
    // getAllActiveInterview();
  }, []);
  const getAllActiveInterview = async () => {
    const url = `${process.env.PUBLIC_URL}/xakal/get-all-active-interview-no-pagination`;
    try {
      const response = await axios.get(url);

      if (response.data) {
        if (response.data.interview.length === 0) {
          setLoading(false);
          setExhausted(false);
        } else {
          setInterviewData(response.data.interview);
          setInterviewDataDup(response.data.interview);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("Error fetching details:", error);
    }
  };

  const handleOpen = (data) => {
    setOpenForm(true);
    setSelectedData(data);
  };

  const closeForm = () => {
    setOpenForm(false);
  };
  const openClose = () => {
    setOpenForm(false);
  };
  // eslint-disable-next-line no-unused-vars
  const availableSkills = [
    "Sql",
    "UI Design",
    "UX Design",
    "Web Development",
    "Wireless Communications and Networks",
    "React JS",
    "Recruiter",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Spring Boot",
    "Spring Framework",
    "NLP",
    "Node js",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Hardware Development",
    "Hardware Product Development",
    "HTML",
    "Internet of Things",
    "Java Script",
    "Java",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "net",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Azure DevOps",
    "Big Data",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Core Java",
    "CSS",
    "Data Analytics",
    "Data Engineer",
    "Data Modeling",
    "Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "Angular",
    "AWS",
  ];
  // eslint-disable-next-line no-unused-vars

  const updateToSavedInterview = async (savedId) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/update-saved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          savedId,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleWhatsAppShare = () => {
    const jobName = selectedData.interviewTitle;
    const jobId = selectedData._id;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    const message = `${jobName} (Job ID: ${jobId}) - Click the link to view the job details:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobId = selectedData._id;
    const jobTitle = selectedData.interviewTitle;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const subject = `Job Opportunity: ${jobTitle} (Job ID: ${jobId})`;
    const body = `Check out this job opportunity: ${jobUrl}\n\nJob Title: ${jobTitle}\nJob ID: ${jobId}`;

    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = selectedData.interviewTitle;
    const jobId = selectedData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const message = `${jobTitle} (Job ID: ${jobId}) - Click the link to view the job details: ${jobUrl}`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  const handleCopyToClipboard = () => {
    const jobId = selectedData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);

    navigator.clipboard.writeText(jobUrl).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };
  const updateToUnsavedInterview = async (savedId) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/unsaved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(savedId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== savedId
            );
          } else {
            return [...prevSavedInterviews, savedId];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 170);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const inputFormInput = (id) => {
    setInputId(id);
  };
  const handleCancel = () => {
    // Clear the selected file and any error messages
    setFile(null);
    setOpen2(false);
    setActiveCard(null);
  };
  const openEditBook = (id) => {
    setConfirmPopup(true);
    setEditPicker(false);
    setSlotId(id);
  };
  ///get-allcompany
  const getAllCompany = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-company`
      );
      setCompany(response.data.company);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getAllCompany();
  }, []);
  //filtering for profile exp
  const [openFilterProfileExp, setOpenFilterProfileExp] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [isMaleChecked, setIsMaleChecked] = useState(false);
  const [isFemaleChecked, setIsFemaleChecked] = useState(false);
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [openAllFilter, setOpenAllFilter] = useState(false);
  const [openDesignationFilter, setOpenDesignationFilter] = useState(false);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [designaSkill, setDesignSkill] = useState([]);
  const [is0To2YearsChecked, setIs0To2YearsChecked] = useState(false);
  const [is2To4YearsChecked, setIs2To4YearsChecked] = useState(false);
  const [is4To6YearsChecked, setIs4To6YearsChecked] = useState(false);
  const [is6To8YearsChecked, setIs6To8YearsChecked] = useState(false);
  const [is8To10YearsChecked, setIs8To10YearsChecked] = useState(false);
  const [is10PlusYearsChecked, setIs10PlusYearsChecked] = useState(false);
  const handleFilterExp = () => {
    setOpenFilterProfileExp((prevState) => !prevState);
    setOpenAllFilter(false);
    setOpenDesignationFilter(false);
    setOpenFilterProfileLocation(false);
  };

  const selectedExperienceCount = [
    is0To2YearsChecked,
    is2To4YearsChecked,
    is4To6YearsChecked,
    is8To10YearsChecked,
    is10PlusYearsChecked,
  ].filter(Boolean).length;

  ///filtering for location
  const [openFilterProfileLocation, setOpenFilterProfileLocation] =
    useState(false);
  const handleFilterLocations = () => {
    setOpenFilterProfileLocation((prevState) => !prevState);
    setOpenFilterProfileExp(false);
    setOpenAllFilter(false);
    setOpenDesignationFilter(false);
  };
  console.log(filteredApplicants, "userList");
  const handleDesignationChange = (selectedOptions) => {
    setSelectedDesignations(selectedOptions);
  };

  const [location, setLocation] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [coordinates, setCoordinates] = useState([]);

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const addressComponents = results[0].address_components;

      let state = "";
      let city = "";
      let country = "";

      const includedTypes = [
        "administrative_area_level_1", // state
        "administrative_area_level_2", // city
        "country",
        "locality",
        "political",
      ];

      addressComponents.forEach((component) => {
        if (includedTypes.includes(component.types[0])) {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("administrative_area_level_2")) {
            city = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.short_name;
          }
        }
      });
      console.log(addressComponents, "addressComponents");
      // Check for duplicates before adding
      const exists = selectedLocations.some(
        (loc) =>
          loc.city === city && loc.state === state && loc.country === country
      );

      if (!exists) {
        setSelectedLocations((prev) => [
          ...prev,
          { value, city, state, country },
        ]);
      }
      setLocation(""); // Clear input
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };
  ///designation filter for profile

  const handleCompanyChange = (selectedOptions) => {
    setSelectedCompany(selectedOptions);
  };
  const handleFilterDesignation = () => {
    setOpenDesignationFilter((prev) => !prev);
    setOpenFilterProfileExp(false);
    setOpenFilterProfileLocation(false)
    setOpenAllFilter(false);
    
  };
  const getDesignationSkill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/getDesignSpec`
      );
      const companyValue = response.data.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
      }));

      setDesignSkill(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  const GetAllCompany = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-company`
      );

      const companyValue = response.data.company.map((e) => ({
        label: e.companyName,
        value: e.companyName,
        website: e.website,
        comID: e.companyId,
        companyLogo: e.companyLogo,
      }));
      setCompanyData(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };
  useEffect(() => {
    getDesignationSkill();
    GetAllCompany();
  }, []);

  //all filter

  const handleAllFilters = () => {
    setOpenAllFilter((filterOpen) => !filterOpen);
    setOpenFilterProfileExp(false);
    setOpenFilterProfileLocation(false);
    setOpenDesignationFilter(false);
  };
  const removeLocation = (index) => {
    setSelectedLocations((prev) => prev.filter((_, i) => i !== index));
  };
  const removeLocations = (index) => {
    setSelectedJobLocations((prev) => prev.filter((_, i) => i !== index));
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };
  const [isDropdownOpens, setDropdownOpens] = useState(false);
  const toggleDropdowns = () => {
    setDropdownOpens((prev) => !prev);
  };
  const [isDropdownOpenSort, setDropdownOpenSort] = useState(false);
  const toggleDropdownSort = () => {
    setDropdownOpenSort((prev) => !prev);
  };
  const [isDropdownOpenDateSort, setDropdownOpenDateSort] = useState(false);
  const toggleDropdownDateSort = () => {
    setDropdownOpenDateSort((prev) => !prev);
  };
  const [isDropdownOpenExpereinceSort, setIsDropdownOpenExpereinceSort] =
    useState(false);
  const toggleDropdownExpereinceSort = () => {
    setIsDropdownOpenExpereinceSort((prev) => !prev);
  };
  const handleSkillChange = (e) => {
    setSelectedSkill(e);
  };

  const getSkill = async () => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/skills`
      );
      const companyValue = response.data.map((e) => ({
        label: e.label,
        value: e.value,
      }));
      setSkillData(companyValue);
    } catch (err) {
      console.log("Error fetching details:", err);
    }
  };

  useEffect(() => {
    getSkill();
  }, []);
  ///university

  const optionsTwo = uni.Universities.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const handleUniversityChange = (e) => {
    setSelectedUniversity(e);
  };
  const filterApplicants = (input) => {
    if (!input || !input) return [];

    const experienceFilters = {
      "0-2Years": (startDate) => {
        const start = new Date(startDate);
        return (
          start >=
            new Date(new Date().setFullYear(new Date().getFullYear() - 2)) &&
          start < new Date(new Date().setFullYear(new Date().getFullYear() - 0))
        );
      },
      "2-4Years": (startDate) => {
        const start = new Date(startDate);
        return (
          start >=
            new Date(new Date().setFullYear(new Date().getFullYear() - 4)) &&
          start < new Date(new Date().setFullYear(new Date().getFullYear() - 2))
        );
      },
      "4-6Years": (startDate) => {
        const start = new Date(startDate);
        return (
          start >=
            new Date(new Date().setFullYear(new Date().getFullYear() - 6)) &&
          start < new Date(new Date().setFullYear(new Date().getFullYear() - 4))
        );
      },
      "6-8Years": (startDate) => {
        const start = new Date(startDate);
        return (
          start >=
            new Date(new Date().setFullYear(new Date().getFullYear() - 8)) &&
          start < new Date(new Date().setFullYear(new Date().getFullYear() - 6))
        );
      },
      "8-10Years": (startDate) => {
        const start = new Date(startDate);
        return (
          start >=
            new Date(new Date().setFullYear(new Date().getFullYear() - 10)) &&
          start < new Date(new Date().setFullYear(new Date().getFullYear() - 8))
        );
      },
      "10PlusYears": (startDate) => {
        const start = new Date(startDate);
        return (
          start <=
          new Date(new Date().setFullYear(new Date().getFullYear() - 10))
        );
      },
    };

    const selectedExperienceRanges = [];
    if (is0To2YearsChecked) selectedExperienceRanges.push("0-2Years");
    if (is2To4YearsChecked) selectedExperienceRanges.push("2-4Years");
    if (is4To6YearsChecked) selectedExperienceRanges.push("4-6Years");
    if (is6To8YearsChecked) selectedExperienceRanges.push("6-8Years");
    if (is8To10YearsChecked) selectedExperienceRanges.push("8-10Years");
    if (is10PlusYearsChecked) selectedExperienceRanges.push("10PlusYears");

    return input.filter((i) => {
      const matchesExperience =
        selectedExperienceRanges.length === 0 ||
        selectedExperienceRanges.some((range) =>
          i.experienceDetails.some((exp) =>
            experienceFilters[range](exp.startDate)
          )
        );

      const matchesLocation =
        selectedLocations.length === 0 ||
        selectedLocations.some((loc) => {
          const primaryAddress = i.primaryAddress || {};
          const cityMatch = primaryAddress.city === loc.city;
          const stateMatch = primaryAddress.state === loc.state; // Convert to full state name
          const countryMatch = primaryAddress.country === loc.country; // Convert to full country name

          return cityMatch || (stateMatch && countryMatch);
        });
      const matchesDesignation =
        selectedDesignations.length === 0 ||
        selectedDesignations.some((designation) =>
          i.experienceDetails.some(
            (exp) => exp?.designation === designation?.value
          )
        );
      const matchesCompany =
        selectedCompany.length === 0 ||
        selectedCompany.some((designation) =>
          i.experienceDetails.some(
            (exp) => exp.company?.value === designation?.value
          )
        );
      const matchesSkill =
        selectedSkill.length === 0 ||
        selectedSkill.some((designation) =>
          i.coreSkill.some((exp) => exp?.value === designation?.value)
        );
      const matchesLanguage =
        selectedLanguage.length === 0 ||
        selectedLanguage.some((designation) =>
          i?.inputList.some((exp) => exp?.language === designation?.value)
        );
      const matchesEducation =
        selectedUniversity.length === 0 ||
        selectedUniversity.some((designation) =>
          i.educationDetails.some(
            (exp) => exp?.university === designation?.value
          )
        );
      const matchesGender =
        (!isMaleChecked && !isFemaleChecked && !isOtherChecked) ||
        (isMaleChecked && i.gender === "Male") ||
        (isFemaleChecked && i.gender === "Female") ||
        (isOtherChecked && i.gender === "Other");
      return (
        matchesExperience &&
        matchesLocation &&
        matchesDesignation &&
        matchesCompany &&
        matchesSkill &&
        matchesLanguage &&
        matchesEducation &&
        matchesGender
      );
    });
  };
  const [selectedCountDesignation,setSelectedCountDesignation] = useState(0)
  const [selectedCountExpereince,setSelectedCountExpereince] = useState(0)
  const [selectedCountLocation,setSelectedCountLocation] = useState(0)
  const [openJobFilter, setOpenJobFilter] = useState(false);
  const [openAllJobFilter, setOpenAllJobFilter] = useState(false);

  const [selectedJobLocations, setSelectedJobLocations] = useState([]);
  const [openDatePosted, setOpenDatePosted] = useState(false);
  const [openDateFilterPosted, setOpenDateFilterPosted] = useState(false);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null); // Initially null
  const handleGetResults = () => {
    const results = filterApplicants(users);
    setFilteredApplicants(results);
 
    setSelectedCountDesignation(selectedDesignations?.length)
    setSelectedCountExpereince(selectedExperienceCount?.length)
    setSelectedCountLocation(selectedLocations?.length)
  
  };
  const handleGetResetResults = () => {
    const results = filterApplicants(users);
    setFilteredApplicants(results);
    setOpenFilterProfileExp(false);
    setOpenAllFilter(false);
    setSelectedDesignations([]);
    setSelectedLocations([]);
    setIs0To2YearsChecked(false);
    setIs2To4YearsChecked(false);
    setIs4To6YearsChecked(false);
    setIs6To8YearsChecked(false);
    setIs8To10YearsChecked(false);
    setIs10PlusYearsChecked(false);
    setSelectedCountDesignation(selectedDesignations?.length)
    setSelectedCountExpereince(selectedExperienceCount?.length)
    setSelectedCountLocation(selectedLocations?.length)
    getAssociatesList();
    setOpenFilterProfileLocation((prevState) => !prevState);
  };
  const handleAllFilter = () => {
    setOpenAllJobFilter((prevState) => !prevState);
    setOpenJobFilter(false);
    setOpenDatePosted(false);
    setOpenDateFilterPosted(false);
  };
  const handleOpenJobFilter = () => {
    setOpenJobFilter((prevState) => !prevState);
    setOpenDatePosted(false);
    setOpenDateFilterPosted(false);
    setOpenAllJobFilter(false);
  };
  const handleOpenDatePosted = () => {
    setOpenDatePosted((prevState) => !prevState);
    setOpenJobFilter(false);
    setOpenDateFilterPosted(false);
    setOpenAllJobFilter(false);
  };
  const handleOpenDateFilterPosted = () => {
    setOpenDateFilterPosted((prevState) => !prevState);
    setOpenDatePosted(false);
    setOpenJobFilter(false);
    setOpenAllJobFilter(false);
  };
  const handleTimeFrameChange = (event) => {
    setSelectedTimeFrame(event.target.value);
  };
  const [selectedFilter, setSelectedFilter] = useState(null); // Default to Most Recent
  const [experienceRange, setExperienceRange] = useState([0, 10]); // Default range from 0 to 10

  const filteredJob = (input) => {
    if (!input || !input.length) return [];

    const now = new Date();
    const filterTimeFrame = (jobDate) => {
      const jobTime = new Date(jobDate).getTime();
      const timeDifference = now.getTime() - jobTime;

      switch (selectedTimeFrame) {
        case "past24Hours":
          return timeDifference <= 24 * 60 * 60 * 1000; // 24 hours
        case "pastWeek":
          return timeDifference <= 7 * 24 * 60 * 60 * 1000; // 7 days
        case "pastMonth":
          return timeDifference <= 30 * 24 * 60 * 60 * 1000; // 30 days
        default:
          return true; // Any Time or if no selection
      }
    };

    // Filter jobs based on location and timeframe
    const filteredJobs = input.filter((job) => {
      const matchesLocation =
        selectedJobLocations.length === 0 ||
        selectedJobLocations.some(
          (location) => location?.value === job.location
        );
      const matchesExperience =
        job.experienceRequired >= experienceRange[0] &&
        job.experienceRequired2 <= experienceRange[1];
      return (
        matchesLocation &&
        filterTimeFrame(job.timestamps.createdAt) &&
        matchesExperience
      );
    });

    // Sort jobs based on the selected filter
    if (selectedFilter === "mostRecent") {
      return filteredJobs.sort(
        (a, b) =>
          new Date(b.timestamps.createdAt) - new Date(a.timestamps.createdAt)
      );
    } else if (selectedFilter === "firstPosted") {
      return filteredJobs.sort(
        (a, b) =>
          new Date(a.timestamps.createdAt) - new Date(b.timestamps.createdAt)
      );
    }

    // If no filter is selected, return the filtered jobs unsorted
    return filteredJobs;
  };
const [selectedCountLocationJob,setSelectedCountLocationJob] = useState(0)
  const handleJobResults = () => {
    const results = filteredJob(data); // Assuming dataDup is defined in your context
    setFilteredJob(results);
    setSelectedCountLocationJob(selectedJobLocations.length)
    getAllJob();
  };
  const handleJobResultResults = () => {
    const results = filteredJob(data); // Assuming dataDup is defined in your context
    setFilteredJob(results);
    setSelectedFilter(null);
    setSelectedTimeFrame(null);
    setSelectedJobLocations([]);
    setExperienceRange([]);
    getAllJob();
    setSelectedCountLocationJob(0)
  };
  const handleSelects = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const addressComponents = results[0].address_components;

      let state = "";
      let city = "";
      let country = "";

      const includedTypes = [
        "administrative_area_level_1", // state
        "administrative_area_level_2", // city
        "country",
        "locality",
        "political",
      ];

      addressComponents.forEach((component) => {
        if (includedTypes.includes(component.types[0])) {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("administrative_area_level_2")) {
            city = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.short_name;
          }
        }
      });
      console.log(addressComponents, "addressComponents");
      // Check for duplicates before adding
      const exists = selectedJobLocations.some(
        (loc) =>
          loc.city === city && loc.state === state && loc.country === country
      );

      if (!exists) {
        setSelectedJobLocations((prev) => [
          ...prev,
          { value, city, state, country },
        ]);
      }
      setLocation(""); // Clear input
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };
  console.log(filteredJobs, "filteredJobs");
  console.log(selectedJobLocations, "selectedJobLocations");

  return (
    <div>
      <ToastContainer />
      <HeaderLayout className="header-layout">
        {/* Start of Mobile Header  */}
        <MobileHeader hTitle="Job" />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader title={"Search"} searchedKey={captureSearchedData} />
        {/* End of desktop Header  */}
      </HeaderLayout>

      <>
        <div>
          <div className="job-nav-container">
            <NativeLink className="job-nav">
              <div
                selected={selectedView === "profile"}
                onClick={() => {
                  setSelectedview("profile");
                  setIsOpen(false);
                  setOpenForm(false);
                  setActiveCard(null);
                }}
                className={
                  selectedView === "profile"
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                People
              </div>
              <div
                selected={selectedView === "jobs"}
                onClick={() => {
                  setSelectedview("jobs");
                  setOpenForm(false);
                  setActiveCard(null);
                }}
                className={
                  selectedView === "jobs"
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                Jobs
              </div>
              <div
                selected={selectedView === "interview"}
                onClick={() => {
                  setSelectedview("interview");
                  setIsOpen(false);
                  setActiveCard(null);
                }}
                className={
                  selectedView === "interview"
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                Interviews
              </div>
              <div
                selected={selectedView === "company"}
                onClick={() => {
                  setSelectedview("company");
                  setIsOpen(false);
                  setActiveCard(null);
                }}
                className={
                  selectedView === "company"
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                Company
              </div>
            </NativeLink>
          </div>
          <div className="job-card-container">
            {selectedView === "jobs" ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "30px",
                    marginTop: "10rem",
                  }}
                >
                  <button
                    className="button2"
                    onClick={handleOpenDateFilterPosted}
                  >
                    Sort By
                  </button>
                  {openDateFilterPosted && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        zIndex: 9999,
                        marginTop: "45px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        left: "500px",
                      }}
                    >
                      <div style={{ margin: "1rem 0" }}>
                        <label>
                          <input
                            type="radio"
                            value="mostRecent"
                            checked={selectedFilter === "mostRecent"}
                            onChange={() => setSelectedFilter("mostRecent")}
                          />{" "}
                          Most Recent
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="firstPosted"
                            checked={selectedFilter === "firstPosted"}
                            onChange={() => setSelectedFilter("firstPosted")}
                          />{" "}
                          First Posted
                        </label>

                        <br />
                      </div>

                      <div
                        className="modelFooter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ marginRight: "10px" }}
                          onClick={() => setSelectedFilter(null)}
                        >
                          Reset
                        </button>
                        <button onClick={handleJobResults}>Get Result</button>
                      </div>
                    </div>
                  )}
                  <button className="button2" onClick={handleOpenDatePosted}>
                    Date Posted
                  </button>
                  {openDatePosted && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        zIndex: 9999,
                        marginTop: "45px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        left: "500px",
                      }}
                    >
                      <div style={{ margin: "1rem 0" }}>
                        <label>
                          <input
                            type="radio"
                            value="anyTime"
                            checked={selectedTimeFrame === "anyTime"}
                            onChange={handleTimeFrameChange}
                          />{" "}
                          Any Time
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="past24Hours"
                            checked={selectedTimeFrame === "past24Hours"}
                            onChange={handleTimeFrameChange}
                          />{" "}
                          Past 24 hours
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="pastWeek"
                            checked={selectedTimeFrame === "pastWeek"}
                            onChange={handleTimeFrameChange}
                          />{" "}
                          Past Week
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="pastMonth"
                            checked={selectedTimeFrame === "pastMonth"}
                            onChange={handleTimeFrameChange}
                          />{" "}
                          Past Month
                        </label>
                        <br />
                      </div>

                      <div
                        className="modelFooter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ marginRight: "10px" }}
                          onClick={() => setSelectedTimeFrame(null)}
                        >
                          Reset
                        </button>
                        <button onClick={handleJobResults}>Get Result</button>
                      </div>
                    </div>
                  )}
                  <button className="button2" onClick={handleOpenJobFilter}>
                    Location {selectedCountLocationJob}
                  </button>

                  {openJobFilter && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        zIndex: 9999,
                        marginTop: "45px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        left: "650px",
                      }}
                    >
                      <div style={{ margin: "1rem 0" }}>
                        <div>
                          <PlacesAutocomplete
                            value={location}
                            onChange={setLocation}
                            onSelect={handleSelects}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter your work location",
                                    className: "location-search-input",
                                  })}
                                  style={{ width: "100%", paddingLeft: "10px" }}
                                  className="footer-header font"
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      width: "100%",
                                      height: "48px",
                                      backgroundColor: "#F4F4F6",
                                      border: "1px solid #F4F4F6",
                                      borderRadius: "8px",
                                      marginBottom: "5px",
                                    }),
                                  }}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions?.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";

                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fff",
                                          cursor: "pointer",
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                          fontSize: "12px",
                                          color: "#FFF",
                                          borderRadius: "0px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          height: "fit-content",
                                          marginTop: "10px",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                          color: "#362A41",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span
                                          style={{
                                            color: "#362A41",
                                            padding: "5px",
                                            background: "#C6C6C6",
                                            borderRadius: "8px",
                                            width: "calc(100% - 6px)",
                                            height: "fit-content",
                                            margin: "0px 0px 0px 0px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {suggestion.description}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div>
                            {selectedJobLocations.map((loc, index) => (
                              <div
                                key={index}
                                style={{
                                  margin: "5px 0",
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                }}
                              >
                                <strong>{loc.value}</strong>
                                <br />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div
                        className="modelFooter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ marginRight: "10px" }}
                          onClick={() => setSelectedJobLocations([])}
                        >
                          Reset
                        </button>
                        <button onClick={handleJobResults}>Get Result</button>
                      </div>
                    </div>
                  )}
                  <button className="button2" onClick={handleAllFilter}>
                    All Filter
                  </button>
                  {openAllJobFilter && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        zIndex: 9999,
                        marginTop: "50px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        right: "3px",
                        transform: "translateX(0%)",
                        width: "50%",
                      }}
                    >
                      <div style={{ margin: "1rem 0" }}>
                        <div>
                          <span onClick={toggleDropdownSort}>
                            {" "}
                            Sort By {isDropdownOpenSort ? "▲" : "▼"}
                          </span>
                          {isDropdownOpenSort && (
                            <fieldset
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                                marginTop: "10px",
                              }}
                            >
                              <legend
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                }}
                              >
                                Sort By:
                              </legend>

                              <label>
                                <input
                                  type="radio"
                                  value="mostRecent"
                                  checked={selectedFilter === "mostRecent"}
                                  onChange={() =>
                                    setSelectedFilter("mostRecent")
                                  }
                                />{" "}
                                Most Recent
                              </label>
                              <br />
                              <label>
                                <input
                                  type="radio"
                                  value="firstPosted"
                                  checked={selectedFilter === "firstPosted"}
                                  onChange={() =>
                                    setSelectedFilter("firstPosted")
                                  }
                                />{" "}
                                First Posted
                              </label>
                            </fieldset>
                          )}
                        </div>
                      </div>
                      <div style={{ margin: "1rem 0" }}>
                        <div>
                          <span onClick={toggleDropdownDateSort}>
                            {" "}
                            Date {isDropdownOpenDateSort ? "▲" : "▼"}
                          </span>
                          {isDropdownOpenDateSort && (
                            <fieldset
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                                marginTop: "10px",
                              }}
                            >
                              <legend
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                }}
                              >
                                Sort By:
                              </legend>

                              <label>
                                <input
                                  type="radio"
                                  value="anyTime"
                                  checked={selectedTimeFrame === "anyTime"}
                                  onChange={handleTimeFrameChange}
                                />{" "}
                                Any Time
                              </label>
                              <br />
                              <label>
                                <input
                                  type="radio"
                                  value="past24Hours"
                                  checked={selectedTimeFrame === "past24Hours"}
                                  onChange={handleTimeFrameChange}
                                />{" "}
                                Past 24 hours
                              </label>
                              <br />
                              <label>
                                <input
                                  type="radio"
                                  value="pastWeek"
                                  checked={selectedTimeFrame === "pastWeek"}
                                  onChange={handleTimeFrameChange}
                                />{" "}
                                Past Week
                              </label>
                              <br />
                              <label>
                                <input
                                  type="radio"
                                  value="pastMonth"
                                  checked={selectedTimeFrame === "pastMonth"}
                                  onChange={handleTimeFrameChange}
                                />{" "}
                                Past Month
                              </label>
                            </fieldset>
                          )}
                        </div>
                      </div>
                      <div style={{ margin: "1rem 0" }}>
                        <div>
                          <PlacesAutocomplete
                            value={location}
                            onChange={setLocation}
                            onSelect={handleSelects}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter your work location",
                                    className: "location-search-input",
                                  })}
                                  style={{ width: "100%", paddingLeft: "10px" }}
                                  className="footer-header font"
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      width: "100%",
                                      height: "48px",
                                      backgroundColor: "#F4F4F6",
                                      border: "1px solid #F4F4F6",
                                      borderRadius: "8px",
                                      marginBottom: "5px",
                                    }),
                                  }}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions?.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";

                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fff",
                                          cursor: "pointer",
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                          fontSize: "12px",
                                          color: "#FFF",
                                          borderRadius: "0px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          height: "fit-content",
                                          marginTop: "10px",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                          color: "#362A41",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span
                                          style={{
                                            color: "#362A41",
                                            padding: "5px",
                                            background: "#C6C6C6",
                                            borderRadius: "8px",
                                            width: "calc(100% - 6px)",
                                            height: "fit-content",
                                            margin: "0px 0px 0px 0px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {suggestion.description}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div>
                            {selectedJobLocations.map((loc, index) => (
                              <div
                                key={index}
                                style={{
                                  margin: "5px 0",
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                }}
                              >
                                <strong>{loc.value}</strong>
                                <button
                                  onClick={() => removeLocations(index)}
                                  style={{
                                    marginLeft: "10px",
                                    background: "none",
                                    border: "none",
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div style={{ margin: "1rem 0" }}>
                        <div>
                          <span onClick={toggleDropdownExpereinceSort}>
                            {" "}
                            Expereince Level{" "}
                            {isDropdownOpenExpereinceSort ? "▲" : "▼"}
                          </span>
                          {isDropdownOpenExpereinceSort && (
                            <fieldset
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                                marginTop: "10px",
                              }}
                            >
                              <legend
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                }}
                              >
                                Expereince Level:
                              </legend>

                              <div>
                                <label>
                                  Minimum Experience: {experienceRange[0]} years
                                  <div>
                                    <button
                                      onClick={() =>
                                        setExperienceRange([
                                          Math.max(0, experienceRange[0] - 1),
                                          experienceRange[1],
                                        ])
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      type="range"
                                      min="0"
                                      max="20" // Set maximum experience value as needed
                                      value={experienceRange[0]}
                                      onChange={(e) =>
                                        setExperienceRange([
                                          Number(e.target.value),
                                          experienceRange[1],
                                        ])
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        setExperienceRange([
                                          experienceRange[0] + 1,
                                          experienceRange[1],
                                        ])
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </label>
                                <br />
                                <label>
                                  Maximum Experience: {experienceRange[1]} years
                                  <div>
                                    <button
                                      onClick={() =>
                                        setExperienceRange([
                                          experienceRange[0],
                                          Math.max(0, experienceRange[1] - 1),
                                        ])
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      type="range"
                                      min="0"
                                      max="20" // Set maximum experience value as needed
                                      value={experienceRange[1]}
                                      onChange={(e) =>
                                        setExperienceRange([
                                          experienceRange[0],
                                          Number(e.target.value),
                                        ])
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        setExperienceRange([
                                          experienceRange[0],
                                          experienceRange[1] + 1,
                                        ])
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </label>
                              </div>
                            </fieldset>
                          )}
                        </div>
                      </div>
                      <div
                        className="modelFooter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setSelectedFilter(null);
                            setSelectedTimeFrame(null);
                            setSelectedJobLocations([]);
                            setExperienceRange([]);
                          }}
                        >
                          Reset
                        </button>
                        <button onClick={handleJobResults}>Get Result</button>
                      </div>
                    </div>
                  )}
                  <button className="button5" onClick={handleJobResultResults}>
                    Reset
                  </button>
                </div>
                <div style={{ paddingBottom: "30px" }}>
                  {filteredJobs &&
                    filteredJobs
                      .filter((i) => {
                        // Add your filtering logic here for 'data'
                        return (
                          i.status === "active" && // Filter by status (e.g., only 'active')
                          i.jobTitle
                            .toLowerCase()
                            .includes(searchKeyword.toLowerCase()) // Filter by job title
                        );
                      })
                      .map((i) => {
                        return (
                          <CardWrapper
                            className={
                              isOpen
                                ? "job-card-wrap int-rows"
                                : "job-card-wrapper int-rows"
                            }
                          >
                            <div
                              className={
                                activeCard === i._id ? "underline" : ""
                              }
                            ></div>
                            <div>
                              <ContentWrapper className="job-content-wrapper">
                                <div className="company-logo-container">
                                  {i.companyName &&
                                  i.companyName.companyLogo ? (
                                    <img
                                      src={
                                        i.companyName &&
                                        i.companyName.companyLogo
                                      }
                                      alt="img"
                                      style={{ width: "60px" }}
                                    />
                                  ) : (
                                    <img
                                      src={Image}
                                      alt="img"
                                      style={{ width: "60px" }}
                                    />
                                  )}
                                </div>
                                <Wrapper className="job-title-wrapper">
                                  <JobTitle
                                    className="job-title"
                                    key={i._id}
                                    onClick={() => openPopup(i)}
                                    // onClick={() =>
                                    //   history.push(`./view-application/${i._id}`)
                                    // }
                                  >
                                    {i.jobTitle}
                                  </JobTitle>

                                  <companyTitle className="company-title">
                                    {i.companyName && i.companyName.value}
                                  </companyTitle>
                                </Wrapper>
                              </ContentWrapper>
                              <HrLines className="job-divider" />
                              <div className="d-flex align-items-center justify-content-between">
                                <JobDetail className="job-details">
                                  <IconTitle className="icon-title">
                                    <img
                                      src={iExp}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>
                                      {" "}
                                      Exp{" "}
                                      {i.experienceRequired2
                                        ? `${i.experienceRequired} - ${i.experienceRequired2} yrs`
                                        : i.experienceRequired
                                          ? `${i.experienceRequired} yrs`
                                          : ""}{" "}
                                    </span>
                                  </IconTitle>

                                  <IconTitle className="icon-title">
                                    <img
                                      src={iSalary}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>{`${i.salaryFrom}L - ${i.salaryTo}L`}</span>
                                  </IconTitle>
                                  <IconTitle className="icon-title">
                                    <img
                                      src={iLocation}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>{i.location}</span>
                                  </IconTitle>
                                  {/* <JobTitle>{i.preference} |</JobTitle> */}
                                  <IconTitle className="icon-title">
                                    <img
                                      src={iHome}
                                      className="jt-icon"
                                      alt="icon"
                                    />
                                    <span>{i.workMode}</span>
                                  </IconTitle>
                                </JobDetail>
                              </div>
                            </div>
                          </CardWrapper>
                        );
                      })}
                </div>
              </div>
            ) : null}

            {selectedView === "profile" ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "30px",
                    marginTop: "10rem",
                  }}
                >
                  <button className="button2" onClick={handleFilterExp}>
                    Years of experience {selectedCountExpereince}
                  </button>
                  {openFilterProfileExp && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        zIndex: 9999,
                        marginTop: "45px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        left: "500px",
                      }}
                    >
                      <div style={{ margin: "1rem 0" }}>
                        <label>
                          <input
                            type="checkbox"
                            checked={is0To2YearsChecked}
                            onChange={() =>
                              setIs0To2YearsChecked(!is0To2YearsChecked)
                            }
                          />{" "}
                          0 - 2 years
                        </label>
                        <br />
                        <label>
                          <input
                            type="checkbox"
                            checked={is2To4YearsChecked}
                            onChange={() =>
                              setIs2To4YearsChecked(!is2To4YearsChecked)
                            }
                          />{" "}
                          2 - 4 years
                        </label>
                        <br />
                        <label>
                          <input
                            type="checkbox"
                            checked={is4To6YearsChecked}
                            onChange={() =>
                              setIs4To6YearsChecked(!is4To6YearsChecked)
                            }
                          />{" "}
                          4 - 6 years
                        </label>
                        <br />
                        <label>
                          <input
                            type="checkbox"
                            checked={is6To8YearsChecked}
                            onChange={() =>
                              setIs6To8YearsChecked(!is6To8YearsChecked)
                            }
                          />{" "}
                          6 - 8 years
                        </label>
                        <br />
                        <label>
                          <input
                            type="checkbox"
                            checked={is8To10YearsChecked}
                            onChange={() =>
                              setIs8To10YearsChecked(!is8To10YearsChecked)
                            }
                          />{" "}
                          8 - 10 years
                        </label>
                        <br />
                        <label>
                          <input
                            type="checkbox"
                            checked={is10PlusYearsChecked}
                            onChange={() =>
                              setIs10PlusYearsChecked(!is10PlusYearsChecked)
                            }
                          />{" "}
                          10 + years
                        </label>
                      </div>

                      <div
                        className="modelFooter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setIs0To2YearsChecked(false);
                            setIs2To4YearsChecked(false);
                            setIs4To6YearsChecked(false);
                            setIs6To8YearsChecked(false);
                            setIs8To10YearsChecked(false);
                            setIs10PlusYearsChecked(false);
                            getAssociatesList();
                            setSelectedCountExpereince(0);
                          }}
                          disabled={
                            !(
                              is0To2YearsChecked ||
                              is2To4YearsChecked ||
                              is4To6YearsChecked ||
                              is8To10YearsChecked ||
                              is10PlusYearsChecked
                            )
                          }
                        >
                          Reset
                        </button>
                        <button onClick={handleGetResults}>Get Result</button>
                      </div>
                    </div>
                  )}
                  <button className="button2" onClick={handleFilterLocations}>
                    Location {selectedCountLocation}
                  </button>
                  {openFilterProfileLocation && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        zIndex: 9999,
                        marginTop: "45px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        left: "650px",
                      }}
                    >
                      <div style={{ margin: "1rem 0" }}>
                        <div>
                          <PlacesAutocomplete
                            value={location}
                            onChange={setLocation}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter your work location",
                                    className: "location-search-input",
                                  })}
                                  style={{ width: "100%", paddingLeft: "10px" }}
                                  className="footer-header font"
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      width: "100%",
                                      height: "48px",
                                      backgroundColor: "#F4F4F6",
                                      border: "1px solid #F4F4F6",
                                      borderRadius: "8px",
                                      marginBottom: "5px",
                                    }),
                                  }}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions?.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";

                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fff",
                                          cursor: "pointer",
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                          fontSize: "12px",
                                          color: "#FFF",
                                          borderRadius: "0px",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          height: "fit-content",
                                          marginTop: "10px",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                          color: "#362A41",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span
                                          style={{
                                            color: "#362A41",
                                            padding: "5px",
                                            background: "#C6C6C6",
                                            borderRadius: "8px",
                                            width: "calc(100% - 6px)",
                                            height: "fit-content",
                                            margin: "0px 0px 0px 0px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {suggestion.description}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div>
                            {selectedLocations.map((loc, index) => (
                              <div
                                key={index}
                                style={{
                                  margin: "5px 0",
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                }}
                              >
                                <strong>{loc.value}</strong>
                                <br />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div
                        className="modelFooter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ marginRight: "10px" }}
                          onClick={() => {setSelectedLocations([]);setSelectedCountLocation(0)}}
                        >
                          Reset
                        </button>
                        <button onClick={handleGetResults}>Get Result</button>
                      </div>
                    </div>
                  )}
                  <button className="button2" onClick={handleFilterDesignation}>
                    Designation {selectedCountLocationJob}
                  </button>
                  {openDesignationFilter && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        zIndex: 9999,
                        marginTop: "45px",
                        padding: "20px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        left: "650px",
                      }}
                    >
                      <div style={{ margin: "1rem 0" }}>
                        <div>
                          <Select
                            isMulti
                            options={designaSkill}
                            onChange={handleDesignationChange}
                          />
                          <div>
                            {selectedDesignations.map((loc, index) => (
                              <div
                                key={index}
                                style={{
                                  margin: "5px 0",
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                }}
                              >
                                <strong>{loc.value}</strong>
                                <br />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div
                        className="modelFooter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ marginRight: "10px" }}
                          onClick={() => {setSelectedDesignations([]);setSelectedCountDesignation(0)}}
                        >
                          Reset
                        </button>
                        <button onClick={handleGetResults}>Get Result</button>
                      </div>
                    </div>
                  )}
                  <button className="button2" onClick={handleAllFilters}>
                    All Filter
                  </button>
                  {openAllFilter && (
                    <AllFilters
                      location={location}
                      setLocation={setLocation}
                      handleSelect={handleSelects}
                      toggleDropdown={toggleDropdown}
                      isDropdownOpen={isDropdownOpen}
                      toggleDropdowns={toggleDropdowns}
                      isDropdownOpens={isDropdownOpens}
                      is0To2YearsChecked={is0To2YearsChecked}
                      setIs0To2YearsChecked={setIs0To2YearsChecked}
                      is2To4YearsChecked={is2To4YearsChecked}
                      setIs2To4YearsChecked={setIs2To4YearsChecked}
                      is4To6YearsChecked={is4To6YearsChecked}
                      setIs4To6YearsChecked={setIs4To6YearsChecked}
                      is6To8YearsChecked={is6To8YearsChecked}
                      setIs6To8YearsChecked={setIs6To8YearsChecked}
                      is8To10YearsChecked={is8To10YearsChecked}
                      setIs8To10YearsChecked={setIs8To10YearsChecked}
                      is10PlusYearsChecked={is10PlusYearsChecked}
                      setIs10PlusYearsChecked={setIs10PlusYearsChecked}
                      designaSkill={designaSkill}
                      handleDesignationChange={handleDesignationChange}
                      companyData={companyData}
                      handleCompanyChange={handleCompanyChange}
                      optionsTwo={optionsTwo}
                      handleUniversityChange={handleUniversityChange}
                      skillData={skillData}
                      handleSkillChange={handleSkillChange}
                      languageOptions={languageOptions}
                      handleLanguageChange={handleLanguageChange}
                      isMaleChecked={isMaleChecked}
                      setIsMaleChecked={setIsMaleChecked}
                      isFemaleChecked={isFemaleChecked}
                      setIsFemaleChecked={setIsFemaleChecked}
                      isOtherChecked={isOtherChecked}
                      setIsOtherChecked={setIsOtherChecked}
                      setSelectedDesignations={setSelectedDesignations}
                      setSelectedLocations={setSelectedLocations}
                      handleGetResults={handleGetResults}
                      selectedLocations={selectedLocations}
                      removeLocation={removeLocation}
                      handleGetResetResults={handleGetResetResults}
                    />
                  )}

                  <button className="button5" onClick={handleGetResetResults}>
                    Reset
                  </button>
                </div>
                <div className="company-card-row">
                  {filteredApplicants?.map((invitation, idx) => (
                    <div key={idx}>
                      <div
                        className="company-card"
                        style={{ marginTop: "30px" }}
                      >
                        <img src={gradBg} className="top-grad-bg" alt="" />
                        <div className="company-cont">
                          <div className="cpp-container">
                            <span>
                              {" "}
                              <img
                                src={
                                  invitation.avatar ? invitation?.avatar : image
                                }
                                className="img-fluid company-profile-pic"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="star-space">
                            <h5
                              className="oc-title"
                              onClick={() =>
                                history.push(
                                  "/portal/otherProfile/" + invitation?.userId
                                )
                              }
                            >
                              {invitation?.firstName +
                                " " +
                                invitation?.lastName}
                            </h5>
                            {invitation?.totalAverageRating !== undefined &&
                            invitation.totalAverageRating !== null &&
                            invitation.totalAverageRating !== 0 &&
                            invitation.totalAverageRating !== 0.5 ? (
                              <div>
                                <img
                                  src={star}
                                  className="star-people"
                                  alt=""
                                />
                                <span className="star-average">
                                  {invitation.totalAverageRating.toFixed(1)}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {selectedView === "interview" ? (
              <>
                <Dialog
                  open={openDate}
                  PaperProps={{
                    style: {
                      // width: "100%",
                      height: "650px",
                      borderRadius: "24px",
                      maxWidth: "560px",
                    },
                  }}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent>
                    <ExploreTimeZone
                      id={inputId}
                      interviewId={ids}
                      open={openConfirmPopup}
                      onClose={() => setOpenDate(false)}
                    />
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={openConfirm}
                  PaperProps={{
                    style: {
                      width: "100%",
                      height: "auto",
                      borderRadius: "24px",
                      maxWidth: "660px",
                    },
                  }}
                  fullWidth
                  maxWidth="lg"
                >
                  <DialogContent>
                    <SlotInterview
                      inputId={inputId}
                      openFirst={() => openFirst(inputId)}
                      slotId={slotId}
                      close={close}
                      selectedView={selectedView}
                      selectedData={selectedData}
                      oneTrues={openTrue}
                      openTrue={openForm}
                    />
                  </DialogContent>
                </Dialog>
                {loading ? (
                  <Spinner className="card-center" />
                ) : interviewData && interviewData?.length > 0 ? (
                  <div
                    className="job-card-container"
                    style={{ paddingBottom: "30px" }}
                  >
                    {interviewData &&
                      interviewData?.map((i) => {
                        const saved = i.savedBy.find(
                          (fav) => fav.userId === userId.userId
                        );

                        const savedApplied =
                          saved && saved.status === "saved" ? true : false;
                        return (
                          // <div className="int-row int-rows" onClick={() => handleOpen(i)}>
                          <div
                            className={
                              openForm
                                ? "job-card-wrap int-rows job-card-wrappers search-int"
                                : "job-card-wrapper int-rows job-card-wrappers"
                            }
                          >
                            <div
                              className={
                                // openForm ? "int-contain" :
                                "int-container"
                              }
                            >
                              <div className="search-int-title-row int-titles">
                                <div className="int-creator">
                                  {" "}
                                  {i.createdBy && i.createdBy?.logo ? (
                                    <img
                                      src={i.createdBy.logo}
                                      alt="img"
                                      className="int-creator-img img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={companyLogo}
                                      alt="img"
                                      className="int-creator-img img-fluid"
                                    />
                                  )}
                                </div>
                                <div className="int-tit-save">
                                  <div className="int-details">
                                    <h5
                                      className="title mb-0"
                                      onClick={() => handleOpen(i)}
                                    >
                                      {i.interviewTitle}
                                    </h5>
                                    <div>
                                      <span className="int-cre-lite">by</span>
                                      <span
                                        className="int-cre-title"
                                        onClick={() =>
                                          history.push(
                                            `/portal/other-profile/${i?.createdBy?.userId}`
                                          )
                                        }
                                      >
                                        {i?.createdBy?.firstName}
                                      </span>
                                    </div>
                                    <TimeAgo
                                      timestamp={i.timestamps.createdAt}
                                    />
                                  </div>
                                  <div className="ver-line o-desk"></div>
                                  <div className="b-slot o-desk">
                                    <div>
                                      {i?.inr ? (
                                        <p className="b-price">
                                          <i className="fa fa-rupee"></i>{" "}
                                          {new Intl.NumberFormat(
                                            "en-IN",
                                            {}
                                          ).format(i?.inr)}
                                        </p>
                                      ) : (
                                        <p className="b-price">Free</p>
                                      )}
                                    </div>
                                    <div className="text-center">
                                      <span className="timer">
                                        <span>{i?.duration}</span>
                                        <span>
                                          {" "}
                                          <img
                                            src={iClock}
                                            className="clock-icon"
                                            alt="icon"
                                          />
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <>
                                {" "}
                                {i?.designation.length ? (
                                  <div className="search-prefer-wrap">
                                    <div className="job-preferences">
                                      Designations :
                                    </div>
                                    {i?.designation
                                      ?.slice(0, 3)
                                      .map((item, index) => (
                                        <div
                                          className={
                                            openForm
                                              ? "job-pref-fill"
                                              : "job-preference-pill"
                                          }
                                        >
                                          {item}
                                        </div>
                                      ))}
                                    {i.designation.length > 3 ? (
                                      <div
                                        className="job-preference-pill"
                                        onClick={() => handleOpen(i)}
                                      >
                                        +{i.designation.length - 3}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <img src={noData} className="nodata-center" alt="icon" />
                )}
              </>
            ) : null}
            {selectedView === "company" ? (
              <div>
                <div className="company-card-row">
                  {company?.map((invitation, idx) => (
                    <div key={idx}>
                      <div
                        className="company-card"
                        onClick={() =>
                          history.push({
                            pathname: `/portal/other-company-view/${invitation?.companyId}`,
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img src={gradBg} className="top-grad-bg" alt="" />
                        <div className="company-cont">
                          <div className="cpp-container">
                            <span>
                              {" "}
                              <img
                                src={
                                  invitation?.companyLogo
                                    ? invitation?.companyLogo
                                    : image
                                }
                                className="img-fluid company-profile-pic"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="star-space">
                            <h5
                              className="oc-title"
                              onClick={() =>
                                history.push({
                                  pathname: `/portal/other-company-view/${invitation?.companyId}`,
                                })
                              }
                            >
                              {invitation?.companyName}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>

      {openForm ? (
        <div className={isScrolled ? "int-previews" : "int-preview"}>
          <DialogContent>
            <InterviewPopup
              onPopupData={selectedData}
              onClose={closeForm}
              openDateForm={openDateForm}
              inputId={inputFormInput}
              onClosse={openClose}
              callAlls={getAllActiveInterview}
            />
          </DialogContent>
        </div>
      ) : (
        ""
      )}
      <Dialog
        open={isPopupOpen}
        halfWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span className="mTitle">Job</span>
          {/* <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <Fitme onSave={handleSave} />
        </DialogContent>
      </Dialog>
      {isOpen && selectedJobData ? (
        <div className={isScrolled ? "job-app-wrap" : "job-app-wraps"}>
          <DialogContent>
            <ViewJobApplicant
              jobData={selectedJobData}
              setIsOpen={() => {
                setIsOpen(false);
                setActiveCard(null);
              }}
            />
          </DialogContent>
          <div
            className="modalFooter"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              {favouriteApplied ? (
                <>
                  <img
                    src={Favourite}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      padding: "4px",
                      margin: "10px",
                      background: "#fff",
                      border: "1px solid rgba(221, 223, 228, 0.50)",
                      borderRadius: "8px",
                    }}
                    onClick={() =>
                      toggleUpdatePopFavoriteOne(selectedJobData._id)
                    }
                  />
                </>
              ) : (
                <img
                  src={Favourites}
                  alt="img"
                  style={{
                    width: "30px",
                    height: "30px",
                    padding: "4px",
                    margin: "10px",
                    background: "#fff",
                    border: "1px solid rgba(221, 223, 228, 0.50)",
                    borderRadius: "8px",
                  }}
                  onClick={() => togglePopUpFavorite(selectedJobData._id)}
                />
              )}
            </div>
            <div>
              {userApplied || userApppliedJob.includes(selectedJobData._id) ? (
                <>
                  <button
                    className="button3"
                    onClick={() => {
                      setWithdrawIdToDelete(selectedJobData._id);
                      setOpen4(true); // Open the modal when Delete is clicked
                    }}
                  >
                    Withdraw
                  </button>
                </>
              ) : (
                <button
                  className="job-btn"
                  onClick={() =>
                    handleApplyClick(
                      selectedJobData._id,
                      selectedJobData.questionList,
                      selectedJobData.applyThrough,
                      selectedJobData.urls
                    )
                  }
                >
                  Apply
                </button>
              )}
            </div>
          </div>
          {/* </DialogActions> */}
        </div>
      ) : (
        ""
      )}
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "430px", borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Assessment Questions
          </PopUpTitle>
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <div className="modalBody">
            {sortedQuestions
              .filter((question) => question.questionName.trim() !== "")
              .map((question, index) => (
                <div key={question.questionId} style={{ marginTop: "20px" }}>
                  <div>
                    <QuestionLabel htmlFor={`answer_${question._id}`}>
                      {index + 1}. {question.questionName}
                      {question.isMandatory && (
                        <span className="mandatory-star">*</span>
                      )}
                    </QuestionLabel>
                    {/* <input

                /> */}
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        id={`answer_${question._id}`}
                        name={`answer_${question._id}`}
                        value={
                          formData.answers[question._id]
                            ? formData.answers[question._id].answer
                            : ""
                        }
                        onChange={(e) => handleInputChange(e, question._id)}
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                          height: "48px",
                        }}
                        maxLength={250}
                      />
                    </InputGroup>
                  </div>
                </div>
              ))}
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={closeAnswer}>
              Cancel
            </button>
            <button
              className="button3"
              type="submit"
              onClick={() => handleSubmitOne(selectedId)}
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open2}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "430px", borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Document Submission
          </PopUpTitle>
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <div className="modalBody" style={{ padding: "0px 20px" }}>
            <PopUpTitles className="modal-title-sub">Resume</PopUpTitles>

            <InputGroup className="mb-5">
              <label
                for="file-upload"
                className="custom-file-upload"
                onChange={handleFileChnage}
              >
                <Form.Control
                  // className="custom-file-upload"
                  id="file-upload"
                  type="file"
                />
                {file === null ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <img src={Upload} alt="img" />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        padding: "0px",
                        marginTop: "10px",
                      }}
                    >
                      <FileTypeInfos>
                        <span>*</span>Upload file size should be less than 2mb
                        (File type: Pdf, Doc)
                      </FileTypeInfos>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "60px",
                      alignItems: "center",
                    }}
                  >
                    <FileTypeInfo
                      style={{
                        // bottom: "77px",
                        borderRadius: "8px",
                        border: "1px solid #FFF",
                        width: "100px",
                        height: "65px",
                        padding: "5px 10px",
                        background:
                          "linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      {handlePreview()}
                    </FileTypeInfo>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={Upload}
                        alt="img"
                        style={{ marginTop: "-30px" }}
                      />
                    </div>
                  </div>
                )}
              </label>
              <span className="text-danger f12" style={{ marginLeft: "10px" }}>
                {error2}
              </span>
            </InputGroup>
          </div>
          <div className="modalFooter" style={{ marginTop: "-20px" }}>
            <button className="button5" onClick={handleCancel}>
              Cancel
            </button>
            <button
              onClick={handleSubmitTwo}
              disabled={!file || loading} // Disable the button when loading is true
              className={`button3 ${loading ? "loading" : ""}`}
              style={{ height: "43px" }}
            >
              {loading ? "Loading..." : "Next"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open3}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "440px", borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <PopUpTitle style={{ fontSize: "18px", color: "#362A41" }}>
            Application Preview
          </PopUpTitle>
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <div className="modalBody">
            <div
              style={
                {
                  // borderBottom: "1px solid #F4F4F6",
                  // paddingBottom: "10px",
                }
              }
            >
              {dataOne ? (
                <>
                  {dataOne &&
                    dataOne.map((question, index) => {
                      const userAnswers = question.answers.filter(
                        (answer) => answer.userId === userId.userId
                      );

                      // Check if there are any user answers
                      if (userAnswers.length > 0) {
                        const lastUserAnswer =
                          userAnswers[userAnswers.length - 1];

                        return (
                          <>
                            <PopUpTitles>{`${index + 1}. ${
                              question.questionName
                            }`}</PopUpTitles>
                            <div>
                              <PopUpTitle
                                style={{
                                  fontweight: "normal",
                                  marginBottom: "10px",
                                }}
                              >{`Answer: ${
                                lastUserAnswer.answer || "No answer provided"
                              }`}</PopUpTitle>
                            </div>
                          </>
                        );
                      }
                    })}
                </>
              ) : (
                ""
              )}
            </div>

            <div style={{ position: "relative", marginTop: "20px" }}>
              <PopUpTitles className="modal-title-sub">
                Documents Submitted
              </PopUpTitles>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  background: "#F4F4F6",
                  height: "88px",
                  borderRadius: "8px",
                }}
              >
                <FileTypeInfo
                  style={{
                    top: "2px",
                    left: "3px",
                    borderRadius: "8px",
                    border: "1px solid #FFF",
                    width: "100px",
                    height: "65px",
                    padding: "5px 10px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {handlePreviews()}
                </FileTypeInfo>
                <a
                  href={
                    dataOneResume ? dataOneResume.resume : "No resume provided"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <PopUpTitle
                    style={{ fontweight: "normal", marginTop: "35px" }}
                  >{`${detailOne && detailOne.firstName} ${
                    detailOne && detailOne.lastName
                  }_resume_${formattedYear}`}</PopUpTitle>
                </a>
              </div>
            </div>
          </div>
          <div className="modalFooter" style={{ marginTop: "20px" }}>
            <button className="button5" onClick={() => setOpen3(false)}>
              Cancel
            </button>
            <button
              className="button3"
              type="submit"
              onClick={handleFunctionClose}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={recentsearchOpen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", position: "absolute", top: "80px" },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          {/* <PopUpTitle>Document Submission</PopUpTitle> */}
          <AiOutlineCloseCircle onClick={() => setRecentSearchOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <RecentSearch />
        </DialogContent>
      </Dialog>

      <Dialog
        open={searchOpen}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", position: "absolute", top: "80px" },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <AiOutlineCloseCircle onClick={() => setSearchOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <SearchDetails />
        </DialogContent>
      </Dialog>

      <Dialog
        open={open4}
        halfWidth
        maxWidth="md"
        className="def"
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "rgba(255, 218, 224, 1)",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            fontFamily: "Inter-Medium",
          }}
        >
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                gap: " 8px",
                background: "white",
              }}
            >
              <img
                src={DeleteImg}
                style={{ marginTop: "2px", marginLeft: "9px" }}
                alt="img"
              />
            </div>
            Are you sure you want to withdraw ?
          </JobTitle>
          <DialogActions className="ml-auto">
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => setOpen4(false)}
                style={{ cursor: "pointer" }}
              >
                No Thanks
              </Button4>
              <Button6 onClick={() => handleWithdrawClick(jobWithdrawId)}>
                Proceed
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={open5}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Application withdrawal
          </PopUpTitle>
          <AiOutlineCloseCircle onClick={() => setOpen5(false)} />
          <img src={CloseIcon} alt="img" onClick={() => setOpen5(false)} />
        </DialogTitle>
        <DialogContent>
          <Text className="modal-b-title">
            Are you sure you want to withdraw your Application?
          </Text>

          <DateWrapperButton>
            <Button5
              className="button5"
              onClick={() => setOpen5(false)}
              style={{ cursor: "pointer" }}
            >
              No
            </Button5>
            <Button3
              className="button3"
              onClick={() => handleWithdrawPopUpClick(jobWithdrawId)}
            >
              Yes
            </Button3>
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Text className="modal-b-title">
            Are You Sure You Want To Log Out?
          </Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={social}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "540px" } }}
      >
        <div className="modalHeader mheader">
          <PopUpTitle>Share</PopUpTitle>
          <img src={CloseIcon} alt="img" onClick={() => setOpenSocial(false)} />
        </div>
        <DialogContent>
          <Wrapper className="modalBody">
            <div>
              <BsWhatsapp
                size={24}
                onClick={handleWhatsAppShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Whats App</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <HiOutlineMail
                size={24}
                onClick={handleEmailShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> E-Mail</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <CiLinkedin
                size={24}
                onClick={handleLinkedInShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Linkedin</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              {isCopied ? (
                <MdCheck size={30} style={{ color: "green" }} />
              ) : (
                <MdContentCopy
                  size={24}
                  onClick={handleCopyToClipboard}
                  style={{ cursor: "pointer" }}
                />
              )}
              <h4 className="sb-text">{isCopied ? "Copied" : "Copy Link"}</h4>
            </div>
          </Wrapper>
        </DialogContent>
      </Dialog>
      <Dialog
        open={editPicker}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <EditTimePicker
            input={input}
            serviceId={ids}
            interviewId={input.userId}
            slotId={slotId}
            open={openEditBook}
            onClose={() => setEditPicker(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Search;
