/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DesktopHeader from "../desktopHeader";
import backArrow from "../../images/arrow-box.svg";
import axios from "axios";
import profile from "../../images/company.png";
import iMedal from "../../images/icons/medal-ribbon.svg";
import "./applicant.css";
import "./jobs.css";
import CloseIcon from "../../images/CloseIcon.svg";
import { IoIosArrowForward } from "react-icons/io";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";
import { BsCreditCard2Front } from "react-icons/bs";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
import Spinner from "react-bootstrap/Spinner";
import star from "../../images/icons/star.svg";
import DownArrow from "../../images/DownArrowSort.svg";

const Button11 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button10 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Wrapper3 = styled.div`
  @media (max-width: 576px) {
    margin: 10px 0;
  }
  margin: 10px 0;
  padding: 5px;
`;
const QuestionWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
const JobTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  color: #4f4953;
  margin: 5px 0px 0 0px;
  padding: 5px 0px;
  // margin-top: -10px;

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const JobTitles = styled.div`
  margin-left: 40px;
  color: #362a41;
  font-size: 14px;
  font-weight: normal;
  font-family: Inter-Medium, sans-serif;
  margin-top: 10px;
  margin-left: 36px;
`;

const Button3 = styled.div``;
const HeaderLayout = styled.div``;

const HeaderDownLayout = styled.div``;
const CenterLayout = styled.div``;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const Name = styled.div`
  margin-top: 13px;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;

const Card = styled.div``;

// Create a styled component for the columns
const Column = styled.div``;
const NameImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img``;
const MainPart = styled.div`
  position: relative;
  display: flex;
  gap: 40px;
  margin-left:225px;
  margin-bottom:17px;
  button{
  width:fit-content;
  }
`;
const DivParts = styled.div`

`;
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://test-staging.thexakal.com/login",
      redirectSignOut: "https://test-staging.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Applicants = () => {
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [open4, setOpen4] = useState(false);
  const [userForStarredQuestions, setUserForStarredQuestions] = useState(null);
  const [openSetting, setOpenSetting] = useState(false);
  const [input, setInput] = useState({});
  const [input2, setInput2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openExp, setOpenExp] = useState(false);
  const [ratingExp, setRatingExp] = useState(false);
  const [openDataExp, setOpenDataExp] = useState(false);
  const [openLocationExp, setOpenLocationExp] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [tempSelectedLocations, setTempSelectedLocations] = useState([]);
  const [sortOption, setSortOption] = useState(null);
  const [sortAscOption, setSortAscOption] = useState(null);
  const [isLessThanOneYearChecked, setIsLessThanOneYearChecked] =
    useState(false);
  const [isOneToTwoYearsChecked, setIsOneToTwoYearsChecked] = useState(false);
  const [isTwoToThreeYearsChecked, setIsTwoToThreeYearsChecked] =
    useState(false);
  const [isThreeToFourYearsChecked, setIsThreeToFourYearsChecked] =
    useState(false);
    const [isFourPlusYearsChecked, setIsFourPlusYearsChecked] =
    useState(false);
    const [tempFourPlusYearsChecked, setTempFourPlusYearsChecked] = useState(false);

  const [tempLessThanOneYearChecked, setTempLessThanOneYearChecked] =
    useState(false);
  const [tempOneToTwoYearsChecked, setTempOneToTwoYearsChecked] =
    useState(false);
  const [tempTwoToThreeYearsChecked, setTempTwoToThreeYearsChecked] =
    useState(false);
  const [tempThreeToFourYearsChecked, setTempThreeToFourYearsChecked] =
    useState(false);

  const [tempSortOption, setTempSortOption] = useState(null); // Temporary sort option
  const [tempSortAscOption, setTempSortAscOption] = useState(null); // Temporary sort option

  const handleSortChange = (option) => {
    setTempSortOption(option); // Set the temporary sort option
  };
  const handleAscSortChange = (option) => {
    setTempSortAscOption(option); // Set the temporary sort option
  };
  const handleGetResult = () => {
    setSortOption(tempSortOption); // Update the main sort option
    setOpenExp(false); // Close the sorting options
  };
  const handleSortAscChange = () => {
    setSortAscOption(tempSortAscOption);
    setRatingExp(false);
  };
  const filteredApplicants = (() => {
    if (!input || !input.applicants) {
      return []; // Return an empty array if input or applicants is undefined
    }

    // Define experience filters based on selected checkboxes
    const experienceFilters = {
      lessThanOneYear: (startDate) => {
        const start = new Date(startDate);
        return (
          start > new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        );
      },
      oneToTwoYears: (startDate) => {
        const start = new Date(startDate);
        return (
          start <=
            new Date(new Date().setFullYear(new Date().getFullYear() - 1)) &&
          start > new Date(new Date().setFullYear(new Date().getFullYear() - 2))
        );
      },
      twoToThreeYears: (startDate) => {
        const start = new Date(startDate);
        return (
          start <=
            new Date(new Date().setFullYear(new Date().getFullYear() - 2)) &&
          start > new Date(new Date().setFullYear(new Date().getFullYear() - 3))
        );
      },
      threeToFourYears: (startDate) => {
        const start = new Date(startDate);
        return (
          start <=
            new Date(new Date().setFullYear(new Date().getFullYear() - 3)) &&
          start > new Date(new Date().setFullYear(new Date().getFullYear() - 4))
        );
      },
      fourPlusYears: (startDate) => {
        const start = new Date(startDate);
        return start <= new Date(new Date().setFullYear(new Date().getFullYear() - 4));
      },
    };

    // Example: Check which checkboxes are selected
    const selectedExperienceRanges = [];
    if (isLessThanOneYearChecked)
      selectedExperienceRanges.push("lessThanOneYear");
    if (isOneToTwoYearsChecked) selectedExperienceRanges.push("oneToTwoYears");
    if (isTwoToThreeYearsChecked)
      selectedExperienceRanges.push("twoToThreeYears");
    if (isThreeToFourYearsChecked)
      selectedExperienceRanges.push("threeToFourYears");
    if (isFourPlusYearsChecked) selectedExperienceRanges.push("fourPlusYears");

    return selectedLocations.length > 0
      ? input.applicants.filter((i) => {
          const city = i.aboutId?.primaryAddress?.city;
          const hasCity = city !== undefined; // Check if city is defined
          const isInSelectedLocations = selectedLocations.includes(city);
          const hasAppliedDate = i.appliedDate !== undefined; // Check if appliedDate is defined

          // Check if experience matches the selected ranges
          const matchesExperience =
            selectedExperienceRanges.length === 0 ||
            selectedExperienceRanges.some((range) =>
              i.experienceIds.some((exp) =>
                experienceFilters[range](exp.startDate)
              )
            );

          return (
            hasCity &&
            isInSelectedLocations &&
            hasAppliedDate &&
            matchesExperience
          );
        })
      : input.applicants.filter((i) => {
          const hasAppliedDate = i.appliedDate !== undefined; // Check if appliedDate is defined

          // Check if experience matches the selected ranges
          const matchesExperience =
            selectedExperienceRanges.length === 0 ||
            selectedExperienceRanges.some((range) =>
              i.experienceIds.some((exp) =>
                experienceFilters[range](exp.startDate)
              )
            );

          return hasAppliedDate && matchesExperience;
        });
  })();

  const sortedApplicants = (() => {
    let applicantsToSort = [...filteredApplicants];

    if (sortOption === "earliest") {
      applicantsToSort.sort(
        (a, b) => new Date(a.appliedDate) - new Date(b.appliedDate)
      );
    } else if (sortOption === "latest") {
      applicantsToSort.sort(
        (a, b) => new Date(b.appliedDate) - new Date(a.appliedDate)
      );
    } else if (sortAscOption === "ascending") {
      applicantsToSort.sort(
        (a, b) =>
          (a.aboutId?.totalAverageRating || 0) -
          (b.aboutId?.totalAverageRating || 0)
      );
    } else if (sortAscOption === "descending") {
      applicantsToSort.sort(
        (a, b) =>
          (b.aboutId?.totalAverageRating || 0) -
          (a.aboutId?.totalAverageRating || 0)
      );
    }

    return applicantsToSort;
  })();

  const handleOpenExp = () => {
    setOpenExp((prevState) => !prevState);
    setRatingExp(false)
    setOpenDataExp(false)
    setOpenLocationExp(false)
  };
  const handleRating = () => {
    setRatingExp((prevState) => !prevState);
    setOpenExp(false);
    setOpenDataExp(false)
    setOpenLocationExp(false)
  };
  const handleDataExpRating = () => {
    setOpenDataExp((prevState) => !prevState);
    setRatingExp(false)
    setOpenLocationExp(false)
    setOpenExp(false)
  };
  const handleLocationExp = () => {
    setOpenLocationExp((prevState) => !prevState);
    setOpenExp(false)
    setRatingExp(false)
    setOpenDataExp(false)
  };
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);
  function onSubmit() {
    setSuccess(true);
  }

  const updateOne = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-view-applicant/${id}`
      );
      if (response.data.success) {
        setInput(response.data.updateJob);
        const questionList = response.data.updateJob.questionList;

        const filteredQuestions = questionList.filter(
          (question) =>
            (question.isStarred && !question.isMandatory) ||
            (question.isStarred && question.isMandatory)
        );

        // Sort the filtered questions with starred questions first
        const sortedQuestionList = filteredQuestions.sort((a, b) => {
          return b.isStarred - a.isStarred;
        });

        setInput2(sortedQuestionList);
      }
    } catch (err) {
      console.log("Error fetching details:", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    updateOne(id);
  }, [id]);

  console.log(input);
  const handleViewStarredQuestions = (user) => {
    setUserForStarredQuestions(user);
    setOpen4(true);
  };
  const handleInputChange = (event) => {};
  const searchedKey = () => {};
  const handleCheckboxChange = (city) => {
    setTempSelectedLocations((prev) =>
      prev.includes(city) ? prev.filter((loc) => loc !== city) : [...prev, city]
    );
  };
  const [selectedLocationCount, setSelectedLocationCount] = useState(0);
  const handleLocationGetResult = () => {
    setSelectedLocations(tempSelectedLocations); // Update the main selected locations
    setSelectedLocationCount(tempSelectedLocations.length)
    setOpenLocationExp(false); // Close the dropdown
  };

  const resetLocations = () => {
    setTempSelectedLocations([]); // Clear temporary selections
    setSelectedLocations([]); // Reset main selections
    setOpenLocationExp(false); // Close the dropdown
    setSelectedLocationCount(0)
  };
  const handleReset = () => {
    setSortOption(null); // Reset sorting option
    setTempSortOption(null);
  };

  const handleAscReset = () => {
    setSortAscOption(null); // Reset sorting option
    setTempSortAscOption(null);
  };
  const clearCheckboxes = () => {
    setTempLessThanOneYearChecked(false);
    setTempOneToTwoYearsChecked(false);
    setIsTwoToThreeYearsChecked(false);
    setTempTwoToThreeYearsChecked(false);
  };

  // Function to reset checkboxes to a specific state (if needed)
const [selectedExperienceCount,setSelectedExperienceCount] = useState(0)
  const mainReset = () => {
    handleReset();
    handleAscReset();
    resetCheckboxes();
    setTempSelectedLocations([]); // Clear temporary selections
    setSelectedLocations([]); // Reset main selections
    setSelectedExperienceCount(0)
    setSelectedLocationCount(0)
  };
  const handleExpereinceGetResult = () => {
    setIsLessThanOneYearChecked(tempLessThanOneYearChecked);
    setIsOneToTwoYearsChecked(tempOneToTwoYearsChecked);
    setIsTwoToThreeYearsChecked(tempTwoToThreeYearsChecked);
    setIsThreeToFourYearsChecked(tempThreeToFourYearsChecked);
    setIsFourPlusYearsChecked(tempFourPlusYearsChecked)

    let count = 0;
    if (tempLessThanOneYearChecked) count++;
    if (tempOneToTwoYearsChecked) count++;
    if (tempTwoToThreeYearsChecked) count++;
    if (tempThreeToFourYearsChecked) count++;
    if (tempFourPlusYearsChecked) count++;


    // Update the selected experience count
    setSelectedExperienceCount(count);

    // Close the dropdown or modal
    setOpenDataExp(false);
    setOpenDataExp(false); // Close the dropdown or modal
  };
  const BackToCompany = () => {
    history.push("/portal/manage-jobs");
  };
  const resetCheckboxes = () => {
    setTempLessThanOneYearChecked(false);
    setTempOneToTwoYearsChecked(false);
    setTempTwoToThreeYearsChecked(false);
    setTempThreeToFourYearsChecked(false);
setTempFourPlusYearsChecked(false)
    // Reset the main experience filter states to show all results
    setIsLessThanOneYearChecked(false);
    setIsOneToTwoYearsChecked(false);
    setIsTwoToThreeYearsChecked(false);
    setIsThreeToFourYearsChecked(false);
    setIsFourPlusYearsChecked(false)
    setSelectedExperienceCount(0);
    setOpenDataExp(false); // Close the dropdown or modal
  };

  return (
    <div>
      <HeaderLayout className="header-layout">
        {/* Start of Mobile Header  */}
        <div className="sub-header o-mobile p-3">
          <div
            className="arrow-box"
            onClick={() => history.push("/portal/jobs")}
          >
            <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
          </div>
          <h4 className="textpass font-face-gm mb-0">Active Jobs</h4>
        </div>
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Manage Jobs"}
          searchedKey={searchedKey}
          onFieldChange={handleInputChange}
          back={BackToCompany}
          backbtn
        />
        {/* End of desktop Header  */}
        <HeaderDownLayout
          className="o-desk"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "12rem",
          }}
        >
          <ul className="breadcrumbs bread-crumbs">
            <li>
              <a
                onClick={() => history.push("/portal/manage-jobs")}
                style={{ cursor: "pointer" }}
              >
                Active Jobs
              </a>
            </li>
            <li>
              <IoIosArrowForward />
            </li>
            <li style={{ cursor: "pointer" }}>View Applicants</li>
          </ul>
        </HeaderDownLayout>
        <MainPart>
          <DivParts className={tempSortOption === null ? "sort-opt" : "sort-opt sort-option"}onClick={() => handleOpenExp()}>
            Sort by{" "}
            {tempSortOption === "earliest"
              ? "earliest applicants"
              : tempSortOption === "latest"
                ? "latest application"
                : ""}
            <img src={DownArrow} alt="img" />
          </DivParts>
          {openExp && (
            <div className="filter-options">
              <div className="filter-head">
                <h4 className="filter-tit">Sort Options</h4>
              </div>
              {/* Sort Options */}
              <div>
                <div className="filter-opt-cont">Sort by</div>
                <label className="filter-opt-cont">
                  <input
                    type="radio"
                    checked={tempSortOption === "earliest"}
                    onChange={() => handleSortChange("earliest")}
                  />{" "}
                  Earliest applicants
                </label>
                <br />
                <label className="filter-opt-cont">
                  <input
                    type="radio"
                    checked={tempSortOption === "latest"}
                    onChange={() => handleSortChange("latest")}
                  />{" "}
                  Latest applicants
                </label>
              </div>
              <div
                className="modalFooter modalfooter"
                // style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  className="reset-btn"
                  onClick={handleReset}
                  disabled={!tempSortOption} // Disable if tempSortOption is not set
                >
                  Reset
                </button>

                <button onClick={handleGetResult} className="button2">Get Results</button>
              </div>
            </div>
          )}

          <DivParts className={tempSortAscOption === null ? "sort-opt" : "sort-opt sort-option"} onClick={() => handleRating()}>
            Profile rating:{" "}
            <img src={DownArrow} alt="img" />
            {tempSortAscOption === "ascending"
              ? "ascending"
              : tempSortAscOption === "descending"
                ? "descending"
                : ""}
          </DivParts>
          {ratingExp && (
            <div
              className="filter-options filter-profile"
            >
              <div
                className="filter-head"
              >
                <h4 className="filter-tit">Sort by profile rating on </h4>
              
              </div>

              <div>
                <label className="filter-opt-cont">
                  <input
                    type="radio"
                    checked={tempSortAscOption === "ascending"}
                    onChange={() => handleAscSortChange("ascending")}
                  />{" "}
                  Ascending
                </label>
                <br />
                <label className="filter-opt-cont">
                  <input
                    type="radio"
                    checked={tempSortAscOption === "descending"}
                    onChange={() => handleAscSortChange("descending")}
                  />{" "}
                  Descending
                </label>
              </div>

              <div
                className="modalFooter modalfooter"
              >
                <button
                  className="reset-btn"
                  onClick={handleAscReset}
                  disabled={!tempSortAscOption} // Disable if tempSortOption is not set
                >
                  Reset
                </button>
                <button onClick={handleSortAscChange} className="button2">Get Results</button>
              </div>
            </div>
          )}
          <DivParts className={selectedExperienceCount === 0 ? "sort-opt" : "sort-opt sort-option"} onClick={() => handleDataExpRating()}>
            Years of experience {selectedExperienceCount}
            <img src={DownArrow} alt="img" />
          </DivParts>
          {openDataExp && (
            <div
              className="filter-options filter-exp"
            >
              <div
                className="filter-head"
              >
                <h4 className="filter-tit">Year of Expereince</h4>
               
              </div>

              <div>
                <label className="filter-opt-cont">
                  <input
                    type="checkbox"
                    checked={tempLessThanOneYearChecked}
                    onChange={() =>
                      setTempLessThanOneYearChecked(!tempLessThanOneYearChecked)
                    }
                  />{" "}
                  Less than One Year
                </label>
                <br />
                <label className="filter-opt-cont">
                  <input
                    type="checkbox"
                    checked={tempOneToTwoYearsChecked}
                    onChange={() =>
                      setTempOneToTwoYearsChecked(!tempOneToTwoYearsChecked)
                    }
                  />{" "}
                  1 - 2 years
                </label>
                <br />
                <label className="filter-opt-cont">
                  <input
                    type="checkbox"
                    checked={tempTwoToThreeYearsChecked}
                    onChange={() =>
                      setTempTwoToThreeYearsChecked(!tempTwoToThreeYearsChecked)
                    }
                  />{" "}
                  2 - 3 years
                </label>
                <br />
                <label className="filter-opt-cont">
                  <input
                    type="checkbox"
                    checked={tempThreeToFourYearsChecked}
                    onChange={() =>
                      setTempThreeToFourYearsChecked(
                        !tempThreeToFourYearsChecked
                      )
                    }
                  />{" "}
                  3 - 4 years
                </label>
                <br/>
                <label className="filter-opt-cont">
                  <input
                    type="checkbox"
                    checked={tempFourPlusYearsChecked}
                    onChange={() =>
                      setTempFourPlusYearsChecked(
                        !tempFourPlusYearsChecked
                      )
                    }
                  />{" "}
                  4 + years
                </label>
              </div>

              <div
                className="modalFooter modalfooter"
              >
                <button
                  className="reset-btn"
                  onClick={clearCheckboxes}
                  disabled={
                    !(
                      tempLessThanOneYearChecked ||
                      tempOneToTwoYearsChecked ||
                      tempTwoToThreeYearsChecked ||
                      tempThreeToFourYearsChecked ||
                      tempFourPlusYearsChecked
                    )
                  } // Disable if all checkboxes are unchecked
                >
                  Reset
                </button>
                <button onClick={handleExpereinceGetResult} className="button2">Get Results</button>
              </div>
            </div>
          )}
          <DivParts className={selectedLocationCount === 0 ? "sort-opt" : "sort-opt sort-option"} onClick={() => handleLocationExp()}>
            Location {selectedLocationCount}
            <img src={DownArrow} alt="img" />
          </DivParts>
          {openLocationExp && (
            <div
              className="filter-options filter-loc"
            >
              <div
                className="filter-head"
              >
                <h4 className="filter-tit">Location</h4>
               
              </div>
              {input.applicants.map(
                (a) =>
                  a.aboutId?.primaryAddress?.city && (
                    <div key={a.userId}>
                      <label className="filter-opt-cont">
                        <input
                          type="checkbox"
                          checked={tempSelectedLocations.includes(
                            a.aboutId.primaryAddress.city
                          )}
                          onChange={() =>
                            handleCheckboxChange(a.aboutId.primaryAddress.city)
                          }
                        />{" "}
                        {a.aboutId.primaryAddress.city}
                      </label>
                      <br />
                    </div>
                  )
              )}
              <div
                className="modalFooter modalfooter"
              >
                <button
                  className="reset-btn"
                  onClick={resetLocations}
                  disabled={!tempSelectedLocations.length}
                >
                  Reset
                </button>
                <button onClick={handleLocationGetResult} className="button2">Get Results</button>
              </div>
            </div>
          )}
          <button className="btn btn-new-int" onClick={mainReset} >
            Reset
          </button>
        </MainPart>
      </HeaderLayout>

      <div className="in-content-wrapper sort-wrap">
        
        <CenterLayout className="ap-cls">
          <div className="sort-head">Applicants</div>
          {loading ? (
            <Spinner
              style={{
                display: "flex",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          ) : sortedApplicants.length === 0 ? (
            <div>No Data Available</div>
          ) : (
            sortedApplicants &&
            sortedApplicants?.map((i) => {
              return (
                <Card className="ap-card">
                  <Column className="ap-image">
                    <NameImageContainer>
                      {i.avatar && i.avatar ? (
                        <Image
                          src={i.avatar}
                          alt="Image Alt Text"
                          className="profile-pic"
                        />
                      ) : (
                        <Image
                          src={profile}
                          alt="Image Alt Text"
                          className="profile-pic"
                        />
                      )}
                      {i.firstName && i.lastName ? (
                        <div>
                          <div className="star-main">
                            <Name className="name">{`${i.firstName} ${i.lastName}`}</Name>
                            {i?.aboutId?.totalAverageRating !== undefined &&
                            i?.aboutId?.totalAverageRating !== null &&
                            i?.aboutId?.totalAverageRating !== 0 &&
                            i?.aboutId?.totalAverageRating !== 0.5 ? (
                              <div>
                                <img
                                  src={star}
                                  className="star-people"
                                  alt=""
                                />
                                <span className="star-average">
                                  {i?.aboutId.totalAverageRating.toFixed(1)}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          {i &&
                          i.experienceIds &&
                          i.experienceIds.length > 0 ? (
                            i &&
                            i.experienceIds
                              .filter(
                                (exp) =>
                                  !exp.endDate && exp.timestamps.createdAt
                              )
                              .sort(
                                (exp1, exp2) =>
                                  new Date(exp2.timestamps.createdAt) -
                                  new Date(exp1.timestamps.createdAt)
                              )
                              .slice(0, 1)
                              .map((a) => (
                                <p className="ap-pos">{a.designation}</p>
                              ))
                          ) : (
                            <p className="ap-pos">-</p>
                          )}
                        </div>
                      ) : (
                        <Name className="name">No Name is Provided</Name>
                      )}
                    </NameImageContainer>
                  </Column>
                  <Column className="ap-deg">
                    {i && i.educationIds && i.educationIds.length > 0 ? (
                      i &&
                      i.educationIds
                        .filter((exp) => exp.timestamps.createdAt)
                        .sort(
                          (exp1, exp2) =>
                            new Date(exp2.timestamps.createdAt) -
                            new Date(exp1.timestamps.createdAt)
                        )
                        .slice(0, 1)
                        .map((a) => (
                          <div>
                            <Name style={{ marginTop: "0px" }}>{a.degree}</Name>
                            <p className="ap-pos">{a.specialization}</p>
                          </div>
                        ))
                    ) : (
                      <Name style={{ marginTop: "0px" }} className="deg">
                        No Degree Provided
                      </Name>
                    )}
                  </Column>

                  <Column className="ap-ques">
                    <Name
                      style={{ cursor: "pointer" }}
                      onClick={() => handleViewStarredQuestions(i)}
                    >
                      <img src={iMedal} alt="" />
                      <span className="t-ques"> View Starred Question</span>
                    </Name>
                  </Column>
                  <Column className="ap-applicant">
                    <Button3
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(
                          `/portal/applicant-details/${i.userId}/${id}`
                        )
                      }
                      className="btn btn-outline"
                    >
                      Applicant Details
                    </Button3>
                  </Column>
                </Card>
              );
            })
          )}
        </CenterLayout>
      </div>
      <Dialog
        open={open4}
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "427px",
            height: "auto",
            maxWidth: "430px",
          },
        }}
      >
        <div
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            gap: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            {userForStarredQuestions?.aboutId?.avatar ? (
              <div>
                <img
                  src={userForStarredQuestions?.aboutId.avatar}
                  alt="uiio"
                  style={{
                    width: "50px",
                    height: "48px",
                    borderRadius: "30px",
                  }}
                />
              </div>
            ) : (
              <div>
                <img
                  src={profile}
                  alt="uiio"
                  style={{
                    width: "50px",
                    height: "48px",
                    borderRadius: "30px",
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "70%",
              }}
              className="vp-m-header"
            >
              <div className="vp-1">
                <Name
                  style={{ marginLeft: "0px" }}
                >{`${userForStarredQuestions?.firstName} ${userForStarredQuestions?.lastName}`}</Name>
                <div>
                  {userForStarredQuestions &&
                  userForStarredQuestions.experienceIds &&
                  userForStarredQuestions.experienceIds.length > 0 ? (
                    userForStarredQuestions &&
                    userForStarredQuestions.experienceIds
                      .filter((exp) => !exp.endDate && exp.timestamps.createdAt)
                      .sort(
                        (exp1, exp2) =>
                          new Date(exp2.timestamps.createdAt) -
                          new Date(exp1.timestamps.createdAt)
                      )
                      .slice(0, 1)
                      .map((a) => <p className="ap-pos">{a.designation}</p>)
                  ) : (
                    <p className="ap-pos"></p>
                  )}
                </div>
              </div>
              <div className="vp-2">
                {userForStarredQuestions &&
                userForStarredQuestions.educationIds &&
                userForStarredQuestions.educationIds.length > 0 ? (
                  userForStarredQuestions &&
                  userForStarredQuestions.educationIds
                    .filter((exp) => exp.timestamps.createdAt)
                    .sort(
                      (exp1, exp2) =>
                        new Date(exp2.timestamps.createdAt) -
                        new Date(exp1.timestamps.createdAt)
                    )
                    .slice(0, 1)
                    .map((a) => (
                      <div>
                        <Name className="deg">{a.degree}</Name>
                        <p className="ap-pos">{a.specialization}</p>
                      </div>
                    ))
                ) : (
                  <Name className="deg"></Name>
                )}
              </div>
            </div>
          </div>

          {/* <AiOutlineCloseCircle onClick={() => setOpen4(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setOpen4(false)} />
        </div>
        <DialogContent className="jp-diag-body">
          <div
            className="qa-holder"
            style={{
              position: "relative",
              background: "#f5f5f5",
              borderRadius: "16px",
              padding: "4px",
              // width: "420px",
              margin: "20px",
              // height: "50px",
            }}
          >
            {input2 &&
              input2.map((question, index) => {
                const userAnswers = question.answers.filter(
                  (answer) => answer.userId === userForStarredQuestions?.userId
                );

                // Check if there are any user answers
                if (userAnswers.length > 0) {
                  const lastUserAnswer = userAnswers[userAnswers.length - 1];

                  return (
                    <Wrapper3 key={index}>
                      <QuestionWrapper>
                        <JobTitle
                          style={{ marginLeft: "20px" }}
                          className="apd-ques"
                        >
                          {`${index + 1}.`}
                        </JobTitle>
                        <JobTitle> {`${question.questionName}`}</JobTitle>
                      </QuestionWrapper>
                      <div>
                        <JobTitles className="apd-ans">{` ${
                          lastUserAnswer.answer || "No answer provided"
                        }`}</JobTitles>
                      </div>
                    </Wrapper3>
                  );
                }
              })}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button10 onClick={() => setSuccess(false)}>No</Button10>
            {user ? (
              <Button11
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button11>
            ) : (
              <Button11 onClick={logout}>Yes</Button11>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Applicants;
